import React, { useState } from "react";

import { Carousel, CarouselIndicators, CarouselItem, CarouselCaption, CarouselControl } from "reactstrap";

const CarouselSlider = ({ items }) => {
    const currentIndex = items.findIndex(item => item.selected);
    const [selectedIndex, setSelectedIndex] = useState(currentIndex > -1 ? currentIndex : 0);
    return (
        <Carousel
            activeIndex={selectedIndex}
            next={() => { }}
            previous={() => { }}
        >
            <CarouselIndicators
                activeIndex={selectedIndex}
                items={items.map((item, i) => (
                    {
                        caption: item.title || `Item ${i + 1}`,
                        key: i + 1,
                        src: item.url
                    }
                ))}
                onClickHandler={(index) => setSelectedIndex(index)}
            />
            {
                items.map((item, i) => (
                    <CarouselItem
                        key={i}
                    >
                        <img
                            alt={item.title || `Item ${i + 1}`}
                            src={item.url}
                        />
                        <CarouselCaption
                            captionText={item.title || `Item ${i + 1}`}
                        />
                    </CarouselItem>
                ))
            }
            <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={() => setSelectedIndex((selectedIndex - 1) % items.length)}
            />
            <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={() => setSelectedIndex((selectedIndex + 1) % items.length)}
            />
        </Carousel>
    );
};

export default CarouselSlider;
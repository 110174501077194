import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

import { jobsApi } from '../../../services/jobsServices';
import { customersApi } from '../../../services/customersServices';
import InformationModal from '../../../components/InformationModal';
import Loader from '../../../components/Loader';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

const style = {
  control: base => ({
    ...base,
    border: 0,
    boxShadow: 'none'
  })
};

const animatedComponents = makeAnimated();

const MAX_PAGE_SIZE = 30;

const JobModal = ({ jobId, onSubmit, onClose }) => {
  const [match, setMatch] = useState();
  const [job, setJob] = useState({});

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: '',
    body: '',
  });

  const [loading, setLoading] = useState();
  const [customersLoading, setCustomersLoading] = useState();
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customer, setCustomer] = useState();

  const getOptions = () => [
    ...(customerOptions || []),
    ...(customer ?
      [{ label: `${customer.name}`, value: customer.id }] : []
    )
  ]
    .filter(e => customer ? parseInt(e.value) !== parseInt(customer.id) : true);

  const doSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const promise = job.id ? jobsApi.updateJob(job) : jobsApi.createJob(job);
    promise.then(() => {
      setLoading(false);
      onSubmit();
    })
      .catch((err) => {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: `${job.id ? 'Update' : 'Create'} Job`,
          body:
            err?.response?.data[0].msg ||
            'There was an error with your request.',
        });
      });
  };

  useEffect(() => {
    setCustomersLoading(true);
    customersApi.getCustomers({
      match,
      page: 1,
      pageSize: MAX_PAGE_SIZE
    })
      .then(result => {
        setCustomersLoading(false);
        const parsed = [
          ...result.customers
            .map(c => { return { label: `${c.name}`, value: c.id } }),
        ];
        setCustomerOptions(parsed);
      })
      .catch(() => {
        setCustomersLoading(false);
      });
  }, [match]);

  // Load Job
  useEffect(() => {
    if (jobId) {
      setLoading(true);
      jobsApi.getJob(jobId)
        .then(result => {
          setLoading(false);
          const job = result || {}
          setJob(job);
          setCustomer({ label: `${job.customer.name}`, value: job.customer.id });
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [jobId]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: '', body: '' })
      }
    />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          {jobId ? 'Edit' : 'Create'} Job
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <Col sm="12">
                <FormGroup>
                  <Label>
                    Job Number
                    <small className="text-danger ml-1">*</small>
                  </Label>
                  <Input
                    placeholder="Type something.."
                    maxLength="100"
                    type="text"
                    name="job_number"
                    value={job.job_number}
                    onChange={(e) =>
                      setJob({
                        ...job,
                        job_number: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Customer Job
                    <small className="text-danger ml-1">*</small>
                  </Label>
                  <Input
                    placeholder="Type something.."
                    maxLength="100"
                    type="text"
                    name="customer_job"
                    value={job.customer_job}
                    onChange={(e) =>
                      setJob({
                        ...job,
                        customer_job: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Address
                    <small className="text-danger ml-1">*</small>
                  </Label>
                  <Input
                    placeholder="Type something.."
                    maxLength="100"
                    type="text"
                    name="address"
                    value={job.address}
                    onChange={(e) =>
                      setJob({
                        ...job,
                        address: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    PO Number
                    <small className="text-danger ml-1">*</small>
                  </Label>
                  <Input
                    placeholder="Type something.."
                    maxLength="100"
                    type="text"
                    name="po_number"
                    value={job.po_number}
                    onChange={(e) =>
                      setJob({
                        ...job,
                        po_number: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Quote Number
                    <small className="text-danger ml-1">*</small>
                  </Label>
                  <Input
                    placeholder="Type something.."
                    maxLength="100"
                    type="text"
                    name="quote_number"
                    value={job.quote_number}
                    onChange={(e) =>
                      setJob({
                        ...job,
                        quote_number: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup className='mb-0'>
                  <Label>
                    Customer
                    <small className="text-danger ml-1">*</small>
                  </Label>
                  <Select
                    required={true}
                    placeholder={<span className="text-muted">Search for a customer..</span>}
                    noOptionsMessage={() => "No customers found"}
                    styles={style}
                    className="flex-grow-1 border rounded"
                    options={getOptions()}
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    value={customer}
                    isSearchable
                    isClearable
                    inputValue={match}
                    onInputChange={value => setMatch(value)}
                    onChange={selected => {
                      setJob({
                        ...job,
                        customer_id: selected?.value
                      });
                      setCustomer(selected);
                    }}
                    isLoading={customersLoading}
                  />
                </FormGroup>
                {job.workOrders?.length ? (
                  <ListGroup className='mt-2'>
                    <ListGroupItem
                      className="d-flex justify-content-between align-items-center py-2 bg-lighter"
                      tag="div"
                    >
                      Work Orders
                    </ListGroupItem>
                    {job.workOrders.map(workOrder => (
                      <ListGroupItem
                        className="d-flex justify-content-between align-items-center py-2"
                        tag="div"
                      >
                        {workOrder.work_order_number}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                ) : null}
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={'secondary'} onClick={onClose}>
                Cancel
              </Button>
              <Button
                color='primary'
                className='rounded'
                type='submit'
              >
                <FontAwesomeIcon icon={faSave} className='mr-2' />
                <span>Save</span>
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default JobModal;

import { faBell, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { RefreshCw } from 'react-feather';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Row,
  UncontrolledCollapse,
} from 'reactstrap';
import Header from '../../../components/Header';
import HeaderTitle from '../../../components/HeaderTitle';
import Loader from '../../../components/Loader';
import {
  ACTIONS,
  MAX_PAGE_SIZE,
  useCustomers,
} from '../../../providers/customersProvider';
import { customersApi } from '../../../services/customersServices';
import { farmsApi } from '../../../services/farmsServices';
import CustomerModal from './CustomerModal';
import CustomersMap from './CustomersMap';

const TABLE_COLUMNS = () => [
  {
    dataField: 'name',
    text: 'Name',
    classes: 'text-truncate',
    formatter: (name) => name || '-',
  },
];

const getSizePerPage = (size) => (size === 'All' ? MAX_PAGE_SIZE : size);

const Customers = () => {
  const [customersContext, setCustomersContext] = useCustomers();

  const [loading, setLoading] = useState(true);

  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);

  const [customersAlerts, setCustomersAlerts] = useState([]);
  const [farmsAlerts, setFarmsAlerts] = useState([]);

  const history = useHistory();

  const location = useLocation();

  const onDetails = (id) => history.push(`/customers/${id}/details`);

  const onFarmDetails = (customerId, farmId) =>
    history.push(`/customers/${customerId}/farm/${farmId}`);

  useEffect(() => {
    setLoading(true);
    customersApi
      .getCustomers({
        match: customersContext.match,
        page: customersContext.pagination?.page,
        pageSize: getSizePerPage(customersContext.pagination?.pageSize),
        sortBy: customersContext.sortBy,
        direction: customersContext.direction,
      })
      .then((customers) => {
        setCustomersContext({
          action: ACTIONS.GET_CUSTOMERS_SUCCESS,
          payload: customers,
        });
        setLoading(false);
      });
  }, [
    setCustomersContext,
    customersContext.sortBy,
    customersContext.direction,
    customersContext.pagination?.pageSize,
    customersContext.pagination?.page,
    customersContext.match,
    customersContext.status,
    customersContext.refresh,
    location.pathname,
  ]);

  useEffect(() => {
    customersApi.getAlerts().then(setCustomersAlerts);
    farmsApi.getAlerts().then(setFarmsAlerts);
  }, []);

  return (
    <Container className="d-flex flex-column flex-grow-1" fluid>
      <Header className="mb-3 ">
        <HeaderTitle className="d-flex flex-wrap justify-content-between">
          <span className="col-12 px-0 col-lg-6 text-dark">Customers</span>
        </HeaderTitle>
      </Header>
      <Row className="pb-4 flex-grow-1">
        <Col className='d-flex'>
          <Card id="customerSearch" className='flex-grow-1'>
            <CardHeader>
              <div className="d-flex justify-content-between py-1 align-items-center">
                {(customersAlerts?.length > 0 || farmsAlerts?.length > 0) && (
                  <Button
                    size="sm"
                    color="primary"
                    id="toggler"
                    className="mr-2 rounded d-flex align-items-center"
                  >
                    <Badge color="warning" className="mr-1" pill style={{ marginBottom: "-1px" }}>
                      {(customersAlerts.length || 0) +
                        (farmsAlerts.length || 0)}
                    </Badge>
                    <FontAwesomeIcon className="mr-2 ml-1" icon={faBell} />
                    <span>Notifications</span>
                  </Button>
                )}
                <Button
                  size="sm"
                  className="rounded"
                  onClick={() => setShowAddCustomerModal(true)}
                >
                  <FontAwesomeIcon className="mr-2" icon={faPlus} />
                  <span>Create Customer</span>
                </Button>
              </div>
              {(customersAlerts.length > 0 || farmsAlerts.length > 0) && (
                <div className="my-2">
                  <UncontrolledCollapse toggler="#toggler">
                    {customersAlerts.length > 0 && (
                      <ListGroup>
                        <ListGroupItem
                          className="d-flex justify-content-between align-items-center py-2 bg-lighter font-500"
                          tag="div"
                        >
                          <span>New Customers</span>
                          <Badge color="danger" className="mr-1" pill>
                            {customersAlerts.length}
                          </Badge>
                        </ListGroupItem>
                        {customersAlerts.map((customer) => (
                          <ListGroupItem
                            key={customer.id}
                            tag={'a'}
                            action
                            onClick={() => onDetails(customer.id)}
                          >
                            <div>{customer.name}</div>
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    )}
                    {farmsAlerts.length > 0 && (
                      <ListGroup className="mt-3">
                        <ListGroupItem
                          className="d-flex justify-content-between align-items-center py-2 bg-lighter font-500"
                          tag="div"
                        >
                          <span>New Farms</span>
                          <Badge color="danger" className="mr-1" pill>
                            {farmsAlerts.length}
                          </Badge>
                        </ListGroupItem>
                        {farmsAlerts.map((farm) => (
                          <ListGroupItem
                            key={farm.id}
                            tag={'a'}
                            action
                            onClick={() =>
                              onFarmDetails(farm.customer.id, farm.id)
                            }
                          >
                            <div>{farm.name}</div>
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    )}
                  </UncontrolledCollapse>
                </div>
              )}
              <div className="card-actions d-flex align-items-center justify-content-between">
                <InputGroup className=" col-6 px-0" size="m">
                  <Input
                    maxLength="50"
                    value={customersContext.match || ''}
                    onChange={(evt) =>
                      setCustomersContext({
                        action: ACTIONS.SEARCH,
                        payload: { match: evt.target.value },
                      })
                    }
                    className="border-0"
                    placeholder="Search customers.."
                  />
                </InputGroup>
                <span className="col-6 m-0 p-0 cursor-pointer d-flex align-items-center justify-content-end">
                  <div className="d-flex align-items-center">
                    <RefreshCw
                      className="col-2 p-0"
                      onClick={() =>
                        setCustomersContext({
                          action: ACTIONS.REFRESH,
                        })
                      }
                    />
                    <small className=" col-10 p-0  text-truncate">
                      Showing {customersContext.pagination?.rowCount} customers
                    </small>
                  </div>
                </span>
              </div>
            </CardHeader>
            <CardBody className="d-flex flex-column pt-0">
              {loading ? (
                <Loader />
              ) : customersContext.customers?.length ? (
                <BootstrapTable
                  headerClasses='text-muted small'
                  bootstrap4
                  striped
                  hover
                  remote
                  bordered={false}
                  keyField="id"
                  classes="cursor-pointer border"
                  data={customersContext.customers}
                  columns={[...TABLE_COLUMNS()]}
                  defaultSorted={[
                    {
                      dataField: customersContext.sortBy,
                      order: customersContext.direction,
                    },
                  ]}
                  rowEvents={{
                    onClick: (_, customer) => onDetails(customer.id),
                  }}
                  onTableChange={() => { }}
                  pagination={paginationFactory({
                    page: customersContext.pagination?.page,
                    totalSize: customersContext.pagination?.rowCount,
                    sizePerPage: getSizePerPage(
                      customersContext.pagination?.pageSize
                    ),
                    sizePerPageList: [
                      5,
                      10,
                      25,
                      50,
                      {
                        text: 'All',
                        value: customersContext.pagination?.rowCount,
                      },
                    ],
                    onPageChange: (page) =>
                      setCustomersContext({
                        action: ACTIONS.PAGE_CHANGE,
                        payload: { page },
                      }),
                    onSizePerPageChange: (pageSize) =>
                      setCustomersContext({
                        action: ACTIONS.PAGE_SIZE_CHANGE,
                        payload: { pageSize },
                      }),
                  })}
                />
              ) : (
                <div className="text-center">No results</div>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col className="col-8 d-flex flex-column">
          <CustomersMap loading={loading} />
        </Col>
      </Row>
      {showAddCustomerModal && (
        <CustomerModal onClose={() => setShowAddCustomerModal(false)} />
      )}
    </Container>
  );
};

export default Customers;

import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import InformationModal from '../../../components/InformationModal';
import Loader from '../../../components/Loader';

import { ROLES } from '../../../providers/usersProvider';

import { usersApi } from '../../../services/usersServices';

const UserModal = ({ userId, onClose, onSubmit }) => {
  const [user, setUser] = useState({
    firstName: null,
    lastName: null,
    email: null,
    role: null,
    password: null
  });

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [loading, setLoading] = useState();

  const doSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (user.id) {
      usersApi
        .updateUser(user)
        .then(() => {
          setLoading(false);
          onSubmit();
        })
        .catch(err => {
          setLoading(false);
          return setInformationModal({
            isOpen: true,
            title: "Update User",
            body: err?.response?.data[0].msg ||
              'There was an error with your request.'
          });
        });
    } else {
      usersApi
        .createUser({
          ...user
        })
        .then(() => {
          setLoading(false);
          onSubmit();
        })
        .catch(err => {
          setLoading(false);
          return setInformationModal({
            isOpen: true,
            title: "Create User",
            body: err?.response?.data[0].msg ||
              'There was an error with your request.'
          });
        });
    };
  };

  // Load User
  useEffect(() => {
    if (userId) {
      setLoading(true);
      usersApi.getUsers({ id: userId })
        .then((result) => {
          setLoading(false);
          setUser(result || {});
        })
        .catch(() => {
          setLoading(false);
          return setInformationModal({
            isOpen: true,
            title: "Error",
            body: 'There was an error with your request.'
          });
        });
    }
  }, [userId]);

  const closeBtn = <Button className="close" color="none" onClick={onClose}>&times;</Button>;

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() => setInformationModal({ isOpen: false, title: "", body: "" })}
    />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader className="d-flex justify-content-between" close={closeBtn}>
          {userId ? 'Edit' : 'Create'} User
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? <Loader size="sm" /> : <Col className="col-12">
              <FormGroup>
                <Label>
                  Name
                  <small className="text-danger ml-1">*</small>
                </Label>
                <Input
                  type="text"
                  name="name"
                  value={user.name || ""}
                  onChange={(e) =>
                    setUser({
                      ...user,
                      name: e.target.value,
                    })
                  }
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  Email
                  <small className="text-danger ml-1">*</small>
                </Label>
                <Input
                  type="email"
                  name="email"
                  value={user.email || ""}
                  onChange={(e) =>
                    setUser({
                      ...user,
                      email: e.target.value,
                    })
                  }
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  Phone
                  <small className="text-danger ml-1">*</small>
                </Label>
                <Input
                  type="text"
                  name="phone"
                  value={user.phone || ""}
                  onChange={(e) =>
                    setUser({
                      ...user,
                      phone: e.target.value,
                    })
                  }
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  Role
                  <small className="text-danger ml-1">*</small>
                </Label>
                <CustomInput
                  value={user.role || ""}
                  label="Role"
                  id="role-select"
                  type="select"
                  required={true}
                  name="role-select"
                  onChange={event => setUser({
                    ...user,
                    role: event.currentTarget.value
                  })}
                >
                  <option value={""}>Select a role</option>
                  {Object.keys(ROLES).map(id =>
                    <option key={id} value={id}>{ROLES[id].label}</option>
                  )}
                </CustomInput>
              </FormGroup>
              <FormGroup>
                <Label>
                  PO Prefix
                </Label>
                <Input
                  type="text"
                  name="po_prefix"
                  value={user.po_prefix || ""}
                  onChange={(e) =>
                    setUser({
                      ...user,
                      po_prefix: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup className='mb-0'>
                <Label>
                  Password
                  <small hidden={user.id} className="text-danger ml-1">*</small>
                </Label>
                <Input
                  type="password"
                  name="password"
                  autoComplete="new-password"
                  value={user.password || ""}
                  onChange={event => setUser({
                    ...user,
                    password: event.currentTarget.value
                  })}
                  required={!user.id}
                />
              </FormGroup>
            </Col>}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={'secondary'} onClick={onClose}>
                Cancel
              </Button>
              <Button
                color='primary'
                className='rounded'
                type='submit'
              >
                <FontAwesomeIcon icon={faSave} className='mr-2' />
                <span>Save</span>
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default UserModal;

// Admin
import {
  faCogs,
  faDatabase,
  faHandshake,
  faHome,
  faMoneyBill,
  faUsers,
  faWallet,
} from '@fortawesome/free-solid-svg-icons';
import Commodities from '../pages/admin/commodities/Commodities';
import CustomerDetails from '../pages/admin/customers/CustomerDetails';
import Customers from '../pages/admin/customers/Customers';
import Equipments from '../pages/admin/equipments/Equipments';
import FarmForm from '../pages/admin/farms/FarmForm';
import Home from '../pages/admin/Home';
import Jobs from '../pages/admin/jobs/Jobs';
import Operations from '../pages/admin/operations/Operations';
import OperationTypes from '../pages/admin/operationTypes/OperationTypes';
import PurchaseOrderDetails from '../pages/admin/purchaseOrders/PurchaseOrderDetails';
import PurchaseOrders from '../pages/admin/purchaseOrders/PurchaseOrders';
import Sms from '../pages/admin/sms/sms';
import Users from '../pages/admin/users/Users';
import Vehicles from '../pages/admin/vehicles/Vehicles';
import Vendors from '../pages/admin/vendors/Vendors';
import WorkOrderDetails from '../pages/admin/workOrders/WorkOrderDetails';
import WorkOrders from '../pages/admin/workOrders/WorkOrders';
// Auth
import SignIn from '../pages/auth/signIn/SignIn';

// Routes
export const adminRoutes = [
  {
    path: '/home',
    name: 'Home',
    sidebar: false,
    component: Home,
  },
  {
    path: '/work-orders',
    name: 'Work Orders',
    icon: faHome,
    sidebar: true,
    component: WorkOrders,
  },
  {
    path: '/purchase-orders',
    name: 'Purchase Orders',
    icon: faMoneyBill,
    sidebar: true,
    component: PurchaseOrders,
  },
  {
    path: '/jobs',
    name: 'Jobs',
    icon: faWallet,
    sidebar: true,
    component: Jobs,
  },
  {
    path: '/customers',
    name: 'Customers',
    icon: faHandshake,
    sidebar: true,
    component: Customers,
  },
  {
    path: '/customers/:customerId/details',
    name: 'Customers',
    component: CustomerDetails,
  },
  {
    path: '/customers/:customerId/new/farm',
    name: 'NewFarm',
    component: FarmForm,
  },
  {
    path: '/customers/:customerId/farm/:farmId',
    name: 'EditFarm',
    component: FarmForm,
  },
  {
    path: '/users',
    name: 'Users',
    icon: faUsers,
    sidebar: true,
    component: Users,
  },
  {
    path: `/work-orders/:workOrderId/details`,
    name: 'Work Order Details',
    component: WorkOrderDetails,
  },
  {
    path: `/purchase-orders/:purchaseOrderId/details`,
    name: 'Purchase Order Details',
    component: PurchaseOrderDetails,
  },
  {
    name: 'Admin',
    icon: faCogs,
    sidebar: true,
    entries: [
      {
        icon: faDatabase,
        name: 'Equipments',
        path: `/admin/equipments`,
        component: Equipments,
      },
      {
        icon: faDatabase,
        name: 'Commodities',
        path: `/admin/commodities`,
        component: Commodities,
      },
      {
        icon: faDatabase,
        name: 'Vehicles',
        path: `/admin/vehicles`,
        component: Vehicles,
      },
      {
        icon: faDatabase,
        name: 'Operation Types',
        path: `/admin/operation-types`,
        component: OperationTypes,
      },
      {
        icon: faDatabase,
        name: 'Operations',
        path: `/admin/operations`,
        component: Operations,
      },
      {
        icon: faDatabase,
        name: 'Vendors',
        path: `/admin/vendors`,
        component: Vendors,
      },
      {
        icon: faDatabase,
        name: 'SMS',
        path: `/admin/sms`,
        component: Sms,
      },
    ],
  },
];

export const authRoutes = [
  {
    path: '/auth/sign-in',
    name: 'Login',
    component: SignIn,
  },
];

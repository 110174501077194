import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useColumnOrder, useSortBy, useTable } from 'react-table';
import { Badge, Button, Row, Table } from 'reactstrap';
import Loader from '../../../../../components/Loader';
import { useAuth } from '../../../../../providers/authProvider';
import { ACTIONS, useWorkOrderDetails } from '../../../../../providers/workOrderDetailsProvider';
import { usersApi } from '../../../../../services/usersServices';
import { workDaysApi } from '../../../../../services/workDaysServices';
import { utils } from '../../../../../utils/utils';
import ColumnFilteringModal from '../../modals/ColumnFilteringModal';
import DeleteWorkDayModal from '../../modals/DeleteWorkDayModal';
import WorkDayFormModal from '../../modals/WorkDayFormModal';
import TableHeaders from './TableHeaders';

const STATUS_OPEN = 1;
const STATUS_COLUMN = 'status';
const ACTIONS_COLUMN = 'actions';

const tableHeaders = (
  setWorkDayToEdit,
  setIsEditionModalOpen,
  setWorkDayToDelete,
  setIsDeletionModalOpen
) => [
    {
      Header: <small className='text-muted font-500'>Status</small>,
      id: 'status',
      accessor: (row) => (
        <Badge color={row.status === STATUS_OPEN ? 'warning' : 'success'}>
          {row.status === STATUS_OPEN ? 'Open' : 'Completed'}
        </Badge>
      ),
      sortType: (rowA, rowB) =>
        rowA.status - rowB.status
    },
    {
      Header: <small className='text-muted font-500'>Date</small>,
      id: 'date',
      accessor: (row) => {
        const offset = new Date().getTimezoneOffset() / 60;
        return utils.formatDate(
          new Date(row.date).setHours(new Date(row.date).getHours() + offset)
        );
      },
      sortType: (rowA, rowB) =>
        utils.getDateDiff(
          new Date(rowA.original.date),
          new Date(rowB.original.date) > 0 ? 1 : -1
        ),
    },
    {
      Header: <small className='text-muted font-500'>Operator</small>,
      id: 'user.name',
      accessor: (row) => {
        return row.user?.name || '-';
      },
    },
    {
      Header: <small className='text-muted font-500'>Operation</small>,
      id: 'operation.name',
      accessor: (row) => {
        return row.operation?.name || '-';
      },
    },
    {
      Header: <small className='text-muted font-500'>Job Ticket</small>,
      id: 'job_ticket',
      accessor: (row) => {
        return row.job_ticket || '-';
      },
    },
    {
      Header: <small className='text-muted font-500'>Pass #</small>,
      id: 'pass_number',
      accessor: (row) => {
        return row.pass_number || '-';
      },
      sortType: 'number',
    },
    {
      Header: <small className='text-muted font-500'>Pass Per Row</small>,
      id: 'pass_per_row',
      accessor: (row) => {
        return row.pass_per_row || '-';
      },
    },
    {
      Header: <small className='text-muted font-500'>Complexity</small>,
      id: 'complexity',
      accessor: (row) => {
        return row.complexity || '-';
      },
    },
    {
      Header: <small className='text-muted font-500'>Pickup / Auto</small>,
      id: 'pickup',
      accessor: (row) => {
        return row.vehicle?.equipment || '-';
      },
    },
    {
      Header: <small className='text-muted font-500'>Tractor</small>,
      id: 'tractor',
      accessor: (row) => {
        return row.equipment?.equipment || '-';
      },
    },
    {
      Header: <small className='text-muted font-500'>Tctr. Start Hrs.</small>,
      id: 'tractor_start_hours',
      sortType: 'number',
      accessor: (row) => {
        return row.tractor_start_hours?.toFixed(2) || '-';
      },
    },
    {
      Header: <small className='text-muted font-500'>Tctr. End Hrs.</small>,
      id: 'tractor_end_hours',
      accessor: (row) => {
        return row.tractor_end_hours?.toFixed(2) || '-';
      },
      sortType: 'number',
    },
    {
      Header: <small className='text-muted font-500'>Total Tctr. Hrs.</small>,
      id: 'total_tractor_hours',
      sortType: 'number',
      accessor: (row) => {
        const totalHours = row.tractor_end_hours - row.tractor_start_hours;
        return !isNaN(totalHours) ? totalHours?.toFixed(2) : '-';
      },
    },
    {
      Header: <small className='text-muted font-500'>Tctr. Start Time</small>,
      accessor: (row) => {
        if (!row.tractor_start_time || !moment(row.tractor_start_time).isValid()) {
          return '-';
        };
        return moment(new Date(row.tractor_start_time)).local().format('h:mm a');
      },
      id: 'tractor_start_time',
      sortType: (rowA, rowB) => {
        return utils.compareOnlyTime(
          new Date(rowA.original.tractor_start_time),
          new Date(rowB.original.tractor_start_time)
        );
      },
    },
    {
      Header: <small className='text-muted font-500'>Tctr. End Time</small>,
      id: 'tractor_end_time',
      accessor: (row) => {
        if (!row.tractor_end_time || !moment(row.tractor_end_time).isValid()) {
          return '-';
        };
        return moment(new Date(row.tractor_end_time)).local().format('h:mm a');
      },
      sortType: (rowA, rowB) => {
        return utils.compareOnlyTime(
          new Date(rowA.original.tractor_end_time),
          new Date(rowB.original.tractor_end_time)
        );
      },
    },
    {
      Header: <small className='text-muted font-500'>Tctr. Total Time</small>,
      id: 'total_tractor_time',
      accessor: (row) => {
        if (!row.tractor_end_time) {
          return '-';
        };
        return utils.getDateDiff(row.tractor_start_time, row.tractor_end_time);
      },
      sortType: (rowA, rowB) => {
        return utils.compareOnlyTime(
          new Date(rowA.original.operator_start_time),
          new Date(rowB.original.operator_start_time)
        );
      },
    },

    {
      Header: <small className='text-muted font-500'>Op. Start Time</small>,
      id: 'operator_start_time',
      accessor: (row) => {
        if (!row.operator_start_time) {
          return '-';
        };
        return moment(new Date(row.operator_start_time)).local().format('h:mm a');
      },
      sortType: (rowA, rowB) => {
        return utils.compareOnlyTime(
          new Date(rowA.original.operator_start_time),
          new Date(rowB.original.operator_start_time)
        );
      },
    },
    {
      Header: <small className='text-muted font-500'>Op. End Time</small>,
      id: 'operator_end_time',
      accessor: (row) => {
        if (!row.operator_end_time) {
          return '-';
        };
        return moment(new Date(row.operator_end_time)).local().format('h:mm a');
      },
      sortType: (rowA, rowB) => {
        return utils.compareOnlyTime(
          new Date(rowA.original.operator_end_time),
          new Date(rowB.original.operator_end_time)
        );
      },
    },
    {
      Header: <small className='text-muted font-500'>Op. Total Time</small>,
      id: 'total_operator_time',
      accessor: (row) => {
        if (!row.operator_end_time) {
          return '-';
        };
        return utils.getDateDiff(row.operator_start_time, row.operator_end_time);
      },
      sortType: (rowA, rowB) => {
        return utils.compareOnlyTime(
          new Date(rowA.original.operator_start_time),
          new Date(rowB.original.operator_start_time)
        );
      },
    },
    {
      Header: <small className='text-muted font-500'>Actions</small>,
      id: 'actions',
      accessor: (row) => (
        <div className='d-flex'>
          <Button
            onClick={() => {
              setWorkDayToEdit(row);
              setIsEditionModalOpen(true);
            }}
            size='sm'
            className='mr-2 rounded d-flex align-items-center'
            color='primary'
          >
            <FontAwesomeIcon icon={faPen} className='mr-1' />
            <span>Edit</span>
          </Button>
          <Button
            size='sm'
            className='rounded align-items-center'
            color='danger'
            onClick={() => {
              setWorkDayToDelete(row);
              setIsDeletionModalOpen(true);
            }}
          >
            <FontAwesomeIcon icon={faTrash} className='mr-1' />
            <span>Delete</span>
          </Button>
        </div>
      ),
    },
  ];

const initialTableState = (columnOrder) => ({
  columnOrder,
  sortBy: [
    {
      id: 'date',
    },
    {
      id: 'user_name',
    },
    {
      id: 'operator_start_time',
    },
  ],
});

const WorkedPerformedTable = ({
  closeFilteringModal,
  isFilteringModalOpen,
}) => {
  const [authContext, setAuthContext] = useAuth();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();
  const [loading, setLoading] = useState(false);
  const [workDayToEdit, setWorkDayToEdit] = useState({});
  const [workDayToDelete, setWorkDayToDelete] = useState({});
  const [isEditionModalOpen, setIsEditionModalOpen] = useState(false);
  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);

  const columns = React.useMemo(
    () =>
      tableHeaders(
        setWorkDayToEdit,
        setIsEditionModalOpen,
        setWorkDayToDelete,
        setIsDeletionModalOpen
      ),
    []
  );

  const onDragEnd = (result) => {
    setLoading(false);
    if (!result.destination) {
      return;
    }
    reorder(
      headerGroups[0].headers,
      result.source.index,
      result.destination.index
    );
  };

  const updateUserWorkPerformedTableColumnsSetting = (workPerformedTableColumns) => {
    usersApi.updateUserWorkPerformedTableColumnsSetting({
      id: authContext.currentUser.id,
      workPerformedTableColumns
    });
    const currentUser = authContext.currentUser;
    if (!currentUser.settings) { currentUser.settings = {} };
    currentUser.settings.workPerformedTableColumns = workPerformedTableColumns;
    setColumnOrder(workPerformedTableColumns);
    setAuthContext({
      currentUser
    });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const workPerformedTableColumns = [...result.map((d) => d.id)];
    setColumnOrder(workPerformedTableColumns);
    updateUserWorkPerformedTableColumnsSetting(workPerformedTableColumns);
  };

  const getColumnOrder = () => {
    const columnsOrder = authContext?.currentUser?.settings?.workPerformedTableColumns || [];
    const actionsColumnIndex = columnsOrder.findIndex(c => c === ACTIONS_COLUMN);
    const [actionsColumn] = columnsOrder.splice(actionsColumnIndex, 1);
    columnsOrder.push(actionsColumn)
    return columnsOrder;
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    allColumns,
    prepareRow,
    setColumnOrder,
  } = useTable(
    {
      columns,
      data: workOrderDetails.workOrder?.workDays || [],
      initialState: initialTableState(getColumnOrder()),
      disableSortBy: false,
      manualSortBy: false,
      disableMultiSort: false,
      autoResetSortBy: false,
    },
    useColumnOrder,
    useSortBy
  );

  useEffect(() => {
    const currentUser = authContext.currentUser;
    if (currentUser.settings && currentUser.settings.workPerformedTableColumns) {
      allColumns.forEach(column => {
        const isVisible = currentUser.settings.workPerformedTableColumns.indexOf(column.id) > -1;
        column.toggleHidden(!isVisible);
      });
    };
  }, [allColumns, authContext.currentUser])

  const setLoader = () => setLoading(true);

  const updateWorkDay = async (workDay) => {
    await workDaysApi.updateWorkDay(workDay);
    setWorkOrderDetails({
      action: ACTIONS.REFRESH
    });
    setIsEditionModalOpen(false);
  };

  const deleteWorkDay = async (workDay) => {
    await workDaysApi.deleteWorkDay(workDay);
    setWorkOrderDetails({
      action: ACTIONS.REFRESH
    });
    setIsDeletionModalOpen(false);
  };

  return (
    <>
      <Row>
        <div className='table-responsive overflow-y-hidden' id='box'>
          <Table {...getTableProps()} className='table-striped border'>
            <TableHeaders
              headers={headerGroups[0].headers}
              onDragEnd={onDragEnd}
              onDragStart={setLoader}
            />
            {loading ? (
              <tbody>
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <Loader size="sm" align="start" />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody {...getTableBodyProps()}>
                {rows.length ?
                  rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells
                          .map((cell, index) => {
                            return (
                              <td
                                className={`${cell.column.id === STATUS_COLUMN ? 'width-50' : 'small'} responsive-table-cell text-capitalize`}
                                {...cell.getCellProps()}
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                      </tr>
                    );
                  })
                  : (
                    <tr>
                      <td colSpan={headerGroups[0].headers.length}>
                        <small>No work has been performed</small>
                      </td>
                    </tr>
                  )}
              </tbody>
            )}
          </Table>
          <div className='scroll-shadow' id='shadow'></div>
        </div>
      </Row>
      <>
        {isFilteringModalOpen && (
          <ColumnFilteringModal
            updateUserWorkPerformedTableColumnsSetting={updateUserWorkPerformedTableColumnsSetting}
            isFilteringModalOpen={isFilteringModalOpen}
            onClose={closeFilteringModal}
            allColumns={allColumns}
          />
        )}
        {isEditionModalOpen && (
          <WorkDayFormModal
            workDay={workDayToEdit}
            onSubmit={updateWorkDay}
            onClose={() => setIsEditionModalOpen(false)}
          />
        )}
        {isDeletionModalOpen && (
          <DeleteWorkDayModal
            workDay={workDayToDelete}
            onClose={() => setIsDeletionModalOpen(false)}
            onConfirm={() => deleteWorkDay(workDayToDelete)}
          />
        )}
      </>
    </>
  );
};

export default WorkedPerformedTable;

import React, { createContext, useContext, useReducer } from 'react';

const INITIAL_PAGE_SIZE = 10;
const MAX_PAGE_SIZE = 999;

const PURCHASE_ORDER_STATUS_ANY = '';
const PURCHASE_ORDER_STATUS_OPEN = 1;
const PURCHASE_ORDER_STATUS_ARCHIVED = 2;

const PURCHASE_ORDER_STATUSES = {
  [PURCHASE_ORDER_STATUS_OPEN]: { label: 'Open', color: 'secondary' },
  [PURCHASE_ORDER_STATUS_ARCHIVED]: { label: 'Archived', color: 'danger' },
  [PURCHASE_ORDER_STATUS_ANY]: { label: 'Any Status', color: 'success' },
};

const initialState = {
  purchaseOrders: [],
  pagination: {
    page: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
  match: null,
  status: PURCHASE_ORDER_STATUS_OPEN,
  sortBy: 'created_at',
  direction: 'desc',
};

const ACTIONS = {
  SORT: 'SORT',
  SEARCH: 'SEARCH',
  REFRESH: 'REFRESH',
  STATUS_CHANGE: 'STATUS_CHANGE',
  PAGE_CHANGE: 'PAGE_CHANGE',
  PAGE_SIZE_CHANGE: 'PAGE_SIZE_CHANGE',
  GET_PURCHASE_ORDERS_SUCCESS: 'GET_PURCHASE_ORDERS_SUCCESS',
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_PURCHASE_ORDERS_SUCCESS: {
      const { payload } = data;
      const { purchaseOrders, pagination } = payload;

      return {
        ...state,
        purchaseOrders,
        pagination,
      };
    }

    case ACTIONS.SORT: {
      const { payload } = data;
      const { sortBy, direction } = payload;

      return {
        ...state,
        sortBy,
        direction,
        pagination: {
          page: 1,
          pageSize: INITIAL_PAGE_SIZE,
        },
      };
    }

    case ACTIONS.SEARCH: {
      const { payload } = data;
      const { match } = payload;

      return {
        ...state,
        match,
        pagination: {
          page: 1,
          pageSize: INITIAL_PAGE_SIZE,
        },
      };
    }

    case ACTIONS.REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case ACTIONS.STATUS_CHANGE: {
      const { payload } = data;
      const { status } = payload;
      return {
        ...state,
        status,
      };
    }

    case ACTIONS.PAGE_CHANGE: {
      const { payload } = data;
      const { page } = payload;
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page,
        },
      };
    }

    case ACTIONS.PAGE_SIZE_CHANGE: {
      const { payload } = data;
      const { pageSize } = payload;
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageSize,
          page: 1,
        },
      };
    }

    default:
      throw new Error();
  }
};

const PurchaseOrdersContext = createContext(initialState);

const PurchaseOrdersProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <PurchaseOrdersContext.Provider value={stateAndDispatch}>
      {children}
    </PurchaseOrdersContext.Provider>
  );
};

export const usePurchaseOrders = () => useContext(PurchaseOrdersContext);

export {
  PurchaseOrdersContext,
  PurchaseOrdersProvider,
  ACTIONS,
  MAX_PAGE_SIZE,
  PURCHASE_ORDER_STATUSES,
  PURCHASE_ORDER_STATUS_ARCHIVED,
  PURCHASE_ORDER_STATUS_OPEN,
};

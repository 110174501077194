import React, { useReducer, createContext, useContext } from "react";

const INITIAL_PAGE_SIZE = 10;
const MAX_PAGE_SIZE = 100;

const USER_ROLE_EXECUTIVE = 1;
const USER_ROLE_SUPERVISOR = 2;
const USER_ROLE_OPERATOR = 3;
const USER_ROLE_SERVICE_TECH = 4;

const ROLES = {
    [USER_ROLE_EXECUTIVE]: {
        label: "Executive",
        color: "success"
    },
    [USER_ROLE_SUPERVISOR]: {
        label: "Supervisor",
        color: "info"
    },
    [USER_ROLE_OPERATOR]: {
        label: "Operator",
        color: "secondary"
    },
    [USER_ROLE_SERVICE_TECH]: {
        label: "Service Tech",
        color: "warning"
    },
};

const initialState = {
    users: [],
    pagination: {
        page: 1,
        pageSize: INITIAL_PAGE_SIZE,
    },
    match: null,
    role: null,
    sortBy: 'name',
    direction: 'asc',
};

const ACTIONS = {
    SORT: "SORT",
    SEARCH: "SEARCH",
    REFRESH: "REFRESH",
    PAGE_CHANGE: "PAGE_CHANGE",
    ROLE_CHANGE: "ROLE_CHANGE",
    PAGE_SIZE_CHANGE: "PAGE_SIZE_CHANGE",
    GET_USERS_SUCCESS: "GET_USERS_SUCCESS"
};

const reducer = (state, data) => {
    switch (data.action) {
        case ACTIONS.GET_USERS_SUCCESS: {
            const { payload } = data;
            const { users, pagination } = payload;

            return {
                ...state,
                users,
                pagination
            };
        };

        case ACTIONS.SORT: {
            const { payload } = data;
            const { sortBy, direction } = payload;

            return {
                ...state,
                sortBy,
                direction,
                pagination: {
                    page: 0,
                    pageSize: INITIAL_PAGE_SIZE,
                },
            };
        };

        case ACTIONS.SEARCH: {
            const { payload } = data;
            const { match } = payload;

            return {
                ...state,
                match,
                pagination: {
                    page: 0,
                    pageSize: INITIAL_PAGE_SIZE,
                },
            };
        };

        case ACTIONS.REFRESH:
            return {
                ...state,
                refresh: !state.refresh
            };


        case ACTIONS.ROLE_CHANGE: {
            const { payload } = data;
            const { role } = payload;
            return {
                ...state,
                role
            };
        };

        case ACTIONS.PAGE_CHANGE: {
            const { payload } = data;
            const { page } = payload;
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    page
                },
            };
        };

        case ACTIONS.PAGE_SIZE_CHANGE: {
            const { payload } = data;
            const { pageSize } = payload;
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    pageSize,
                    page: 1
                },
            };
        };

        default:
            throw new Error();
    }
};

const UsersContext = createContext(initialState);

const UsersProvider = ({ children }) => {
    const stateAndDispatch = useReducer(reducer, initialState);
    return (
        <UsersContext.Provider value={stateAndDispatch}>
            {children}
        </UsersContext.Provider>
    );
}

export const useUsers = () => useContext(UsersContext);

export { UsersContext, UsersProvider, ACTIONS, MAX_PAGE_SIZE, ROLES };
import React, { useEffect, useState } from "react";
import Wrapper from "../components/Wrapper";
import Main from "../components/Main";
import Content from "../components/Content";
import Footer from "../components/Footer";
import { useAuth } from "../providers/authProvider";
import { authApi } from "../services/authServices";
import Loader from "../components/Loader";
import Sidebar from "../components/admin/sidebar/Sidebar";
import Hamburger from "../components/Hamburger";
import { Redirect, useParams } from "react-router-dom";
import { utils } from "../utils/utils";
import { ACTIONS, useWorkOrdersKPIs } from "../providers/workOrdersKPIsProvider";
import { workOrdersApi } from "../services/workOrdersServices";

const Admin = ({ children }) => {
  const [authContext, setAuthContext] = useAuth();
  const [, setWorkOrdersKPIsContext] = useWorkOrdersKPIs();

  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [loading, setLoading] = useState();
  const { workOrderId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setWorkOrdersKPIsContext({
        action: ACTIONS.GET_DATA_LOADING,
        payload: true,
      });
      const kpis = await workOrdersApi.getWorkOrdersKPIs();
      setWorkOrdersKPIsContext({
        action: ACTIONS.GET_DATA_SUCCESS,
        payload: { kpis },
      });
    };
    fetchData();
  }, [setWorkOrdersKPIsContext]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await authApi.validate();
      if (!result) {
        setLoading(false);
        setRedirectToLogin(true);
      } else {
        setAuthContext({ currentUser: result });
        setLoading(false);
      }
    };
    if (utils.isMobile() && workOrderId) {
      window.open(`sunharvest-app://work-orders/${workOrderId}`);
      setLoading(false);
      setRedirectToLogin(true);
    } else if (!authContext.currentUser) {
      fetchData();
    };
  }, [authContext.currentUser, authContext.serviceLocationId, setAuthContext, workOrderId]);

  return redirectToLogin ? (
    <Redirect to={`/auth/sign-in`} />
  ) : (loading || !authContext.currentUser) ? (
    <Loader />
  ) : (
    <React.Fragment>
      <Wrapper className="overflow-visible min-width-992px">
        <Sidebar />
        <Main className="min-width-lg d-flex flex-column justify-content-between">
          <Hamburger />
          <Content>{children}</Content>
          <Footer />
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};

export default Admin;

import React from 'react';
import { Col, Row, Table } from 'reactstrap';
import { useWorkOrderDetails } from '../../../../providers/workOrderDetailsProvider';

const PurchaseOrdersTab = () => {
  const [workOrderDetails] = useWorkOrderDetails();

  return (
    <Row className='mt-3'>
      <Col>
        <h4>Purchase Orders</h4>
        <Table>
          <thead className='text-muted small'>
            <tr>
              <th>Date</th>
              <th>PO #</th>
              <th>Vendor</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {workOrderDetails.workOrder.purchaseOrders.length === 0 ? (
              <tr>
                <td colSpan={4} className='text-center text-muted small'>
                  There are no purchase orders
                </td>
              </tr>
            ) : (
              workOrderDetails.workOrder.purchaseOrders.map((purchaseOrder) => (
                <tr key={purchaseOrder.id}>
                  <td>{purchaseOrder.date}</td>
                  <td>{purchaseOrder.po_number}</td>
                  <td>{purchaseOrder.vendor?.name || '-'}</td>
                  <td>$ {purchaseOrder.amount}</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Col>
    </Row>
  )
};

export default PurchaseOrdersTab;

import {
  faAsterisk,
  faChevronLeft,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';

import Header from '../../../components/Header';
import HeaderTitle from '../../../components/HeaderTitle';
import InformationModal from '../../../components/InformationModal';
import Loader from '../../../components/Loader';
import { ACTIONS, useWorkOrderDetails } from '../../../providers/workOrderDetailsProvider';
import { STATUSES } from '../../../providers/workOrdersProvider';
import { workOrdersApi } from '../../../services/workOrdersServices';
import ChangeWorkOrderStateModal from './modals/ChangeWorkOrderStateModal';
import ComponentToPrint from './report/Report';
import DetailsTab from './tabs/DetailsTab';
import NotesTab from './tabs/NotesTab';
import PurchaseOrdersTab from './tabs/PurchaseOrdersTab';
import PerformedTab from './tabs/workPerformed/PerformedTab';
import WorkOrderMap from './WorkOrderMap';

const DETAILS_TAB = 'DETAILS_TAB';
const WORK_PERFORMED_TAB = 'WORK_PERFORMED_TAB';
const NOTES_TAB = 'NOTES_TAB';
const POS_TAB = 'POS_TAB';

const StatusBadge = ({ status }) => (
  <span
    className={`badge-pill mr-1 mb-1 badge badge-${STATUSES[status]?.color}`}
  >
    {STATUSES[status]?.label}
  </span>
);

const WorkOrderDetails = () => {
  const history = useHistory();
  const { workOrderId } = useParams();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const [loading, setLoading] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: '',
    body: '',
    onClose: null,
  });

  const [changeStateModal, setChangeStateModal] = useState(false);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const toggle = (tab) => {
    if (workOrderDetails.activeTab !== tab) {
      setWorkOrderDetails({
        action: ACTIONS.CHANGE_TAB,
        payload: {
          tab
        }
      });
    };
  };

  // Load WorkOrder
  useEffect(() => {
    const getWorkOrder = async () => {
      setLoading(true);
      try {
        const workOrder = await workOrdersApi.getWorkOrder(workOrderId);
        setLoading(false);
        setWorkOrderDetails({
          action: ACTIONS.GET_WORKORDER_SUCCESS,
          payload: {
            workOrder
          }
        });
      } catch (err) {
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: 'Work Order Details',
          body: 'There was an error with your request.',
          onClose: () => history.push('/work-orders/all'),
        });
      }
    };
    getWorkOrder();
  }, [workOrderId, history, setWorkOrderDetails, workOrderDetails.refresh]);

  return (
    <Container fluid className='d-flex flex-column flex-grow-1'>
      <Header className='mb-2'>
        <HeaderTitle className='d-flex align-items-center justify-content-between'>
          <div className='d-flex flex-column align-items-start col-6 px-0'>
            <span className='col-12 px-0 col-lg-6 text-dark'>
              Work Order #{workOrderDetails.workOrder?.work_order_number}
            </span>
            <Button
              size='sm'
              className='rounded mt-1 d-flex align-items-center'
              onClick={() => history.goBack()}
            >
              <FontAwesomeIcon icon={faChevronLeft} className='mr-1' />
              <span>Back</span>
            </Button>
          </div>
          <div className='d-flex justify-content-end mb-2 col-6 px-0'>
            <Button
              onClick={() => setChangeStateModal(true)}
              className='mr-2'
              color='primary'
            >
              <FontAwesomeIcon className='mr-2' icon={faAsterisk} />
              <span>Change Status</span>
            </Button>
            <Button onClick={handlePrint}>
              <FontAwesomeIcon className='mr-2' icon={faPrint} />
              <span>Print</span>
            </Button>
            {workOrderDetails.workOrder && workOrderDetails.mapProperties ? (
              <ComponentToPrint
                mapProperties={workOrderDetails.mapProperties}
                workOrder={workOrderDetails.workOrder}
                ref={componentRef}
              />
            ) : null}
          </div>
        </HeaderTitle>
      </Header>
      <Row className='flex-grow-1 pb-4'>
        {!workOrderDetails.workOrder || loading ? (
          <Loader />
        ) : (
          <>
            <Col className='flex-grow-1'>
              <Card>
                <CardHeader>
                  <div className='d-flex justify-content-between'>
                    <div className='d-flex justify-content-between'>
                      <div className='mr-3'>
                        <div className='h4'>
                          {workOrderDetails.workOrder?.customer?.name ||
                            'No customer name provided'}
                        </div>
                        <div>
                          Contact:{' '}
                          <span className='text-muted'>
                            {workOrderDetails.workOrder?.contact?.name || 'Not provided'}
                          </span>
                        </div>
                        <div>
                          Phone:{' '}
                          <span className='text-muted'>
                            {workOrderDetails.workOrder?.contact?.phone || 'Not provided'}
                          </span>
                        </div>
                        <div>
                          Email:{' '}
                          <span className='text-muted'>
                            {workOrderDetails.workOrder?.contact?.email || 'Not provided'}
                          </span>
                        </div>
                      </div>
                      <div>
                        <StatusBadge status={workOrderDetails.workOrder?.status} />
                      </div>
                    </div>
                    <div>
                      <div className='h4'>
                        <span>{workOrderDetails.workOrder?.farm?.name}</span>
                      </div>
                      <div>
                        Start Date:{' '}
                        <span className='text-muted'>
                          {workOrderDetails.workOrder?.start_date || '-'}
                        </span>
                      </div>
                      <div>
                        End Date:{' '}
                        <span className='text-muted'>
                          {workOrderDetails.workOrder?.end_date || '-'}
                        </span>
                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Card>
              <Nav tabs className='work-order-details-nav cursor-pointer'>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: workOrderDetails.activeTab === DETAILS_TAB,
                    })}
                    onClick={() => toggle(DETAILS_TAB)}
                  >
                    Work Order Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: workOrderDetails.activeTab === WORK_PERFORMED_TAB,
                    })}
                    onClick={() => toggle(WORK_PERFORMED_TAB)}
                  >
                    Work Performed
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: workOrderDetails.activeTab === NOTES_TAB })}
                    onClick={() => toggle(NOTES_TAB)}
                  >
                    Notes
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: workOrderDetails.activeTab === POS_TAB })}
                    onClick={() => toggle(POS_TAB)}
                  >
                    Purchase Orders
                  </NavLink>
                </NavItem>
                <NavItem className='flex-grow-1 d-flex align-items-center justify-content-end'></NavItem>
              </Nav>
              <TabContent activeTab={workOrderDetails.activeTab}>
                <TabPane tabId={DETAILS_TAB}>
                  <Card className='box-shadow-none border-left border-bottom border-right'>
                    <CardBody>
                      {workOrderDetails.activeTab === DETAILS_TAB && (
                        <DetailsTab />
                      )}
                    </CardBody>
                  </Card>
                </TabPane>
              </TabContent>
              <TabContent activeTab={workOrderDetails.activeTab}>
                <TabPane tabId={WORK_PERFORMED_TAB}>
                  <Card className='box-shadow-none border-left border-bottom border-right'>
                    <CardBody>
                      {workOrderDetails.activeTab === WORK_PERFORMED_TAB && (
                        <PerformedTab />
                      )}
                    </CardBody>
                  </Card>
                </TabPane>
              </TabContent>
              <TabContent activeTab={workOrderDetails.activeTab}>
                <TabPane tabId={NOTES_TAB}>
                  <Card className='box-shadow-none border-left border-bottom border-right'>
                    <CardBody>
                      {workOrderDetails.activeTab === NOTES_TAB && (
                        <NotesTab />
                      )}
                    </CardBody>
                  </Card>
                </TabPane>
              </TabContent>
              <TabContent activeTab={workOrderDetails.activeTab}>
                <TabPane tabId={POS_TAB}>
                  <Card className='box-shadow-none border-left border-bottom border-right'>
                    <CardBody>
                      {workOrderDetails.activeTab === POS_TAB && (
                        <PurchaseOrdersTab />
                      )}
                    </CardBody>
                  </Card>
                </TabPane>
              </TabContent>
            </Col>
            {workOrderDetails.activeTab !== WORK_PERFORMED_TAB && (
              <Col className='col-4 flex-grow-1 d-flex'>
                <WorkOrderMap />
              </Col>
            )}
          </>
        )}
        {changeStateModal && (
          <ChangeWorkOrderStateModal
            onClose={() => setChangeStateModal(false)}
          />
        )}
      </Row>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                isOpen: false,
                title: '',
                body: '',
                onClose: null,
              })
          }
        />
      ) : null}
    </Container>
  );
};

export default WorkOrderDetails;

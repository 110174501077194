import React from "react";

import { useAuth } from "../../../providers/authProvider";

import placeholder from "../../../assets/img/placeholder.png";

const USER_ROLE_EXECUTIVE = 1;
const USER_ROLE_SUPERVISOR = 2;

const getUserRoleName = roleId => {
  if (parseInt(roleId) === USER_ROLE_EXECUTIVE) {
    return 'Executive';
  };
  if (parseInt(roleId) === USER_ROLE_SUPERVISOR) {
    return 'Supervisor';
  };
  return "";
};

const SideBarUser = () => {
  const [authContext] = useAuth();

  return (
    <div className="sidebar-user p-3 d-flex flex-column align-items-center justify-content-center">
      <div 
        className="rounded-circle bg-brand img-thumbnail d-flex align-items-center justify-content-center"
        style={{ 
            backgroundPosition: "center",
            backgroundImage: `url(${placeholder})`,
            width: "96px",
            height: "96px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
        }}
      >
        
      </div>
      <span className="text-muted mt-1 font-500">{`${authContext.currentUser?.name}`}</span>
      <small className="text-muted">{getUserRoleName(authContext.currentUser.role)}</small>
    </div>
  );
};

export default SideBarUser;
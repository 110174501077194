import axios from 'axios';
import config from '../config/config';

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}&reason=1`;
  } else if (err?.response?.status === 404) {
    window.location.href = '/error/404';
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = '/error/500';
  }
};

export const smsApi = {
  getSms: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join('&');
    return axios(`/${config.apiVersion}/api/sms?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: 'get',
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  }
};

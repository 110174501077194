import React, { useEffect } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

const AdaptableTextArea = ({
  label,
  value,
  onChange,
  className,
  name = "",
  readOnly = false,
}) => {
  const adaptHeight = (e) => {
    const element = e.target;
    var isOverflowing =
      element.clientWidth < element.scrollWidth ||
      element.clientHeight < element.scrollHeight;

    if (isOverflowing) {
      element.style.height = `${element.scrollHeight + 5}px`;
    }
  };

  useEffect(() => {
    const textarea = document.querySelector('textarea.adaptable-textarea');
    if (textarea) textarea.style.height = `${textarea.scrollHeight + 5}px`;
  }, []);

  return (
    <FormGroup>
      <Label>{label}</Label>
      <Input
        style={{ overflow: 'hidden' }}
        className={`${className ?? ''} adaptable-textarea`}
        type='textarea'
        value={value}
        name={name}
        onChange={onChange}
        readOnly={readOnly}
        onKeyDown={adaptHeight}
      />
    </FormGroup>
  );
};

export default AdaptableTextArea;

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from "react";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./App";

Sentry.init({
    dsn: "https://45c2e324fbf1465e864d8d649585baa1@o912544.ingest.sentry.io/6188056",
    integrations: [
        new Integrations.BrowserTracing({
        tracingOrigins: [window.location.origin],
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENV || 'local',
});

// Render app in `#root` element
ReactDOM.render(<App />, document.getElementById("root"));

import React from "react";

import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";

const InformationModal = ({ 
    onClose, 
    title, 
    body, 
    rawBody = false, 
    size = "md", 
    closeButtonText = "Close"
}) => {

    const closeBtn = <Button className="close"  color="none" onClick={onClose}>&times;</Button>;

    return (
        <Modal isOpen={true} onClosed={onClose} size={size}>
            <ModalHeader close={closeBtn}>
                {title}
            </ModalHeader>
            <ModalBody>
                {rawBody ? body : <div dangerouslySetInnerHTML={{__html: body}}></div>}
            </ModalBody>
            <ModalFooter className="justify-content-end">
                <Button
                    color={'primary'} 
                    onClick={onClose}
                >
                    {closeButtonText}
                </Button>{" "}
            </ModalFooter>
        </Modal>
    );
};

export default InformationModal;
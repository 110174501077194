import mapboxgl from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { farmsApi } from '../../../services/farmsServices';
import { mapCustomers } from '../../../utils/map';

const ALL_LAYERS = 1;
const CUSTOMER_LAYER = 2;
const COUNTIES_LAYER = 3;

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
  }),
};

const animatedComponents = makeAnimated();

const SelectFarm = ({ onSelect, selected }) => {
  const [match, setMatch] = useState();
  const [loading, setLoading] = useState();
  const [options, setOptions] = useState([]);

  const getOptions = () =>
    options
      .map((item) => ({
        label: item.name || item.label,
        value: item.id || item.value,
      }))
      .filter((item) => item.value !== selected?.value);

  // Load operation types from service
  useEffect(() => {
    setLoading(true);
    farmsApi
      .getFarms({
        match,
        page: 1,
        pageSize: 100,
      })
      .then((result) => {
        const parsed = result?.farms.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setOptions(parsed);
      })
      .finally(() => setLoading(false));
  }, [match, selected]);

  return (
    <div className="col-sm-6">
      <Select
        required={true}
        placeholder={<span className="text-muted">Type a farm name...</span>}
        noOptionsMessage={() => 'No farm found'}
        styles={style}
        className="border rounded"
        options={getOptions()}
        closeMenuOnSelect={true}
        components={animatedComponents}
        value={selected}
        isSearchable
        isClearable
        inputValue={match}
        onInputChange={(value) => setMatch(value)}
        onChange={onSelect}
        isLoading={loading}
      />
    </div>
  );
};

const SelectLayer = ({ onSelect, selected }) => {
  const [match, setMatch] = useState();

  return (
    <div className="col-sm-6">
      <Select
        required={true}
        noOptionsMessage={() => 'No farm found'}
        styles={style}
        className="border rounded"
        options={[
          { label: 'All Layers', value: ALL_LAYERS },
          { label: 'Customer Layer', value: CUSTOMER_LAYER },
          { label: 'Counties Layer', value: COUNTIES_LAYER },
        ]}
        closeMenuOnSelect={true}
        components={animatedComponents}
        value={selected}
        inputValue={match}
        onInputChange={(value) => setMatch(value)}
        onChange={onSelect}
      />
    </div>
  );
};

const CustomersMap = ({ loading }) => {
  const [rendered, setRendered] = useState(false);

  const [selectedLayer, setSelectLayer] = useState({
    label: 'All Layers',
    value: ALL_LAYERS,
  });
  const [map, setMap] = useState();

  const [size, setSize] = useState();

  const resizeMap = () => {
    const card = document.getElementById('customerSearch');
    const filters = document.getElementById('filters');
    if (card && filters) {
      setSize(card.offsetHeight - filters.offsetHeight);
    }
  };

  const handleChangeSelectedFarm = (selected) => {
    if (selected && selected.value) {
      farmsApi.getFarm(selected.value).then((farm) => {
        mapCustomers.flyToFarm(map, farm);
      });
    } else {
      mapCustomers.clearSelectedFarms(map);
    }
    resizeMap();
  };

  const handleChangeFarm = (e) => {
    setSelectLayer(e);
    const { value } = e;
    if (value === ALL_LAYERS) {
      mapCustomers.showAll(map);
    } else if (value === CUSTOMER_LAYER) {
      mapCustomers.showCustomer(map);
    } else if (value === COUNTIES_LAYER) {
      mapCustomers.showCounties(map);
    }
    resizeMap();
  };

  useEffect(() => {
    if (!rendered) {
      mapboxgl.clearStorage();
      setMap(mapCustomers.initMap());
      setRendered(true);
      resizeMap();
    }
  }, [rendered]);

  useEffect(() => {
    if (!loading) {
      resizeMap();
    }
  }, [loading]);

  return (
    <>
      <div className="row pb-1" id="filters">
        <SelectFarm onSelect={handleChangeSelectedFarm} />
        <SelectLayer selected={selectedLayer} onSelect={handleChangeFarm} />
      </div>
      <div
        id="map"
        className="rounded d-flex flex-grow-1"
        style={{ height: size + 'px' }}
      />
    </>
  );
};

export default CustomersMap;

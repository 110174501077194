import { faPen, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import * as uuid from 'uuid';
import InformationModal from '../../../../../components/InformationModal';
import Loader from '../../../../../components/Loader';
import { ACTIONS, useWorkOrderDetails } from '../../../../../providers/workOrderDetailsProvider';
import { workDaysApi } from '../../../../../services/workDaysServices';
import { workOrdersApi } from '../../../../../services/workOrdersServices';
import { utils } from '../../../../../utils/utils';
import CloseOutWorkOrderModal from '../../modals/CloseOutWorkOrderModal';
import WorkDayFormModal from '../../modals/WorkDayFormModal';
import WorkedPerformedTable from './WorkPerformedTable';

const HOUR = 'Hour';
const STATUS_CLOSED = 0;

const PerformedTab = () => {
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const [totalHours, setTotalHours] = useState({
    tractorHours: 0,
    tractorTime: 0,
    operatorTime: 0,
  });

  const [closeOutWorkOrder, setCloseOutWorkOrder] = useState({
    hourBillingRate: 0,
    acreBillingRate: 0,
    billingType: HOUR,
    billingTotal: 0,
    ratePer: 0,
    ifBilledBy: 0,
    costPer: 0,
    workedAcres: 0,
    error: '',
  });

  const [workDayModal, setWorkDayModal] = useState({
    isOpen: false,
  });

  const [closeOutWorkOrderModal, setCloseOutWorkOrderModal] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    rawBody: true,
    title: '',
    body: '',
  });

  const [editTotalBilling, setEditTotalBilling] = useState(false);
  const [valueTotalBilling, setValueTotalBilling] = useState();
  const [loading, setLoading] = useState();
  const [isFilteringModalOpen, setIsFilteringModalOpen] = useState(false);

  const onCloseWorkOrderModal = () => setWorkDayModal({ isOpen: false });

  const isClosed = () => workOrderDetails.workOrder?.status === STATUS_CLOSED;

  useEffect(() => {
    const { totalHours, closeOutWorkOrder } = utils.calculateCloseOutWorkOrder(workOrderDetails.workOrder);
    setTotalHours(totalHours);
    setCloseOutWorkOrder(closeOutWorkOrder);
  }, [workOrderDetails.workOrder]);

  const handleSubmitBillingTotal = e => {
    e.preventDefault();
    if (e.target.value) {
      setLoading(true);

      const {
        totalHours,
        updatedWorkOrder
      } = utils.calculateCloseOutWorkOrder(workOrderDetails.workOrder, valueTotalBilling);

      workOrdersApi
        .updateWorkOrder({
          ...updatedWorkOrder,
          total_hours: totalHours.tractorHours
        })
        .then(() =>
          setWorkOrderDetails({
            action: ACTIONS.REFRESH
          })
        )
        .finally(() => {
          setLoading(false);
          setEditTotalBilling(false);
        });
    }
  };

  const createNewWorkDay = async (newWorkDay) => {
    newWorkDay.id = uuid.v4();
    await workDaysApi.createWorkDay(newWorkDay);
    setWorkOrderDetails({
      action: ACTIONS.REFRESH,
    });
    onCloseWorkOrderModal();
  };

  const closeFilteringModal = () => {
    setIsFilteringModalOpen(false);
  };

  return (
    <Row className='mt-3'>
      <Col>
        <div className='d-flex mb-2 justify-content-between align-items-center'>
          <div className='d-flex'>
            <h4 className='mb-0 mr-2'>Work Performed</h4>
            <Button
              size='sm'
              className='rounded mr-2 d-flex align-items-center'
              onClick={() => setIsFilteringModalOpen(true)}
            >
              <FontAwesomeIcon className='mr-1' icon={faPen} />
              <span>Columns</span>
            </Button>
          </div>
          <div>
            <Button
              size='sm'
              className='rounded mr-2'
              color='primary'
              onClick={() => setCloseOutWorkOrderModal(true)}
              disabled={workOrderDetails.workOrder?.status === STATUS_CLOSED}
            >
              <FontAwesomeIcon className='mr-1' icon={faTimes} />
              <span>Close Out Work Order</span>
            </Button>
            <Button
              size='sm'
              className='rounded'
              color='success'
              onClick={() => setWorkDayModal({ isOpen: true })}
              disabled={workOrderDetails.workOrder?.status === STATUS_CLOSED}
            >
              <FontAwesomeIcon className='mr-1' icon={faPlus} />
              <span>Add New Work Day</span>
            </Button>
          </div>
        </div>
        <WorkedPerformedTable
          closeFilteringModal={closeFilteringModal}
          isFilteringModalOpen={isFilteringModalOpen}
        />

        <div className='d-flex row'>
          {totalHours && (
            <ListGroup
              className='my-2'
              style={{ width: '100%', margin: '0 0.3em' }}
            >
              <ListGroupItem
                className='d-flex justify-content-center align-items-center py-2 bg-lighter font-500'
                tag='div'
              >
                Totals
              </ListGroupItem>
              <ListGroupItem
                className='d-flex justify-content-between align-items-center py-2'
                tag='div'
              >
                <span>Total Tractor Hours</span>
                <span>
                  {totalHours.tractorHours?.toFixed(2) || '0'} Hrs.
                </span>
              </ListGroupItem>
              <ListGroupItem
                className='d-flex justify-content-between align-items-center py-2'
                tag='div'
              >
                <span>Tractor Time</span>
                <span>
                  {totalHours.tractorTime?.toFixed(2) || '0'} Hrs.
                </span>
              </ListGroupItem>
              <ListGroupItem
                className='d-flex justify-content-between align-items-center py-2'
                tag='div'
              >
                <span>Operator Time</span>
                <span>
                  {totalHours.operatorTime?.toFixed(2) || '0'} Hrs.
                </span>
              </ListGroupItem>
            </ListGroup>
          )}
          {isClosed() && (
            <ListGroup
              className='my-2'
              style={{ width: '100%', margin: '0 0.3em' }}
            >
              <ListGroupItem
                className='d-flex justify-content-center align-items-center py-2 bg-lighter font-500'
                tag='div'
              >
                By {closeOutWorkOrder.billingType} Breakdown
              </ListGroupItem>
              <ListGroupItem
                className='d-flex justify-content-between align-items-center py-2'
                tag='div'
              >
                <span>Total Tractor Hours</span>
                <span>
                  {totalHours.tractorHours?.toFixed(2) || '0'} Hrs.
                </span>
              </ListGroupItem>
              <ListGroupItem
                className='d-flex justify-content-between align-items-center py-2'
                tag='div'
              >
                <span>Total Acres</span>
                <span>
                  {closeOutWorkOrder.workedAcres?.toFixed(0) || '0'} Acr.
                </span>
              </ListGroupItem>
              <ListGroupItem
                className='d-flex justify-content-between align-items-center py-2'
                tag='div'
              >
                <span>Billing Total</span>
                <div>
                  {loading ? (
                    <div className='min-width-50'><Loader size='sm' align='end' /></div>
                  ) : !editTotalBilling ? (
                    <span onClick={() => {
                      setEditTotalBilling(true);
                      setValueTotalBilling(closeOutWorkOrder.billingTotal);
                    }}>
                      $ {workOrderDetails.workOrder?.workDays?.length ?
                        utils.roundUp(closeOutWorkOrder.billingTotal)
                        : 0}
                    </span>
                  ) : (
                    <FormGroup row className='pt-3'>
                      <Col xs>
                        <Input
                          value={valueTotalBilling}
                          type='text'
                          onChange={(e) => setValueTotalBilling(e.target.value)}
                          onBlur={handleSubmitBillingTotal}
                        />
                      </Col>
                    </FormGroup>
                  )}
                </div>
              </ListGroupItem>
              <ListGroupItem
                className='d-flex justify-content-between align-items-center py-2'
                tag='div'
              >
                <span>
                  Rate Per{' '}
                  {utils.getAnotherBillingTypeLabel(closeOutWorkOrder.billingType)}{' '}
                  (Actual)
                </span>
                <span>$ {utils.roundUp(closeOutWorkOrder.ratePer)}</span>
              </ListGroupItem>
              <hr></hr>
              <ListGroupItem
                className='d-flex justify-content-between align-items-center py-2'
                tag='div'
              >
                <span>
                  If Billed By{' '}
                  {utils.getAnotherBillingTypeLabel(closeOutWorkOrder.billingType)}
                </span>
                <span>$ {workOrderDetails.workOrder?.workDays?.length ?
                  utils.roundUp(closeOutWorkOrder.ifBilledBy)
                  : 0}
                </span>
              </ListGroupItem>
              <ListGroupItem
                className='d-flex justify-content-between align-items-center py-2'
                tag='div'
              >
                <span>Cost Per {closeOutWorkOrder.billingType}</span>
                {workOrderDetails.workOrder?.workDays?.length ?
                  <span>$ {utils.roundUp(closeOutWorkOrder.costPer)}</span>
                  :
                  <span>0</span>
                }
              </ListGroupItem>
            </ListGroup>
          )}
        </div>
        {workDayModal.isOpen && (
          <WorkDayFormModal
            workDay={workDayModal.workDay}
            disabled={workOrderDetails.workOrder?.status === STATUS_CLOSED}
            onSubmit={createNewWorkDay}
            onClose={onCloseWorkOrderModal}
          />
        )}
        {closeOutWorkOrderModal && (
          <CloseOutWorkOrderModal
            tractorHours={totalHours.tractorHours}
            disabled={workOrderDetails.workOrder?.status === STATUS_CLOSED}
            onClose={() => setCloseOutWorkOrderModal(false)}
          />
        )}
        {informationModal.isOpen ? (
          <InformationModal
            title={informationModal.title}
            body={informationModal.body}
            rawBody={informationModal.rawBody}
            onClose={() =>
              setInformationModal({ isOpen: false, title: '', body: '' })
            }
          />
        ) : null}
      </Col>
    </Row>
  );
};

export default PerformedTab;

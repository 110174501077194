import moment from "moment";
import React, { useReducer, createContext, useContext } from "react";

const INITIAL_PAGE_SIZE = 10;
const MAX_PAGE_SIZE = 999;

const initialState = {
    messages: [],
    pagination: {
        page: 1,
        pageSize: INITIAL_PAGE_SIZE,
    },
    date: moment().format("YYYY-MM-DD"),
    status: null,
    sortBy: 'created_at',
    direction: 'desc',
};

const ACTIONS = {
    REFRESH: "REFRESH",
    DATE_CHANGE: "DATE_CHANGE",
    LIMIT_CHANGE: "LIMIT_CHANGE",
    PAGE_SIZE_CHANGE: "PAGE_SIZE_CHANGE",
    GET_SMS_SUCCESS: "GET_SMS_SUCCESS"
};

const reducer = (state, data) => {
    switch (data.action) {
        case ACTIONS.GET_SMS_SUCCESS: {
            const { payload } = data;
            const { sms } = payload;

            return {
                ...state,
                ...sms
            };
        };

        case ACTIONS.PAGE_SIZE_CHANGE: {
            const { payload } = data;
            const { pageSize } = payload;
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    pageSize,
                    page: 1
                },
            };
        };

        case ACTIONS.REFRESH:
            return {
                ...state,
                refresh: !state.refresh
            };


        case ACTIONS.DATE_CHANGE: {
            const { payload } = data;
            const { date } = payload;
            return {
                ...state,
                date
            };
        };

        case ACTIONS.LIMIT_CHANGE: {
            const { payload } = data;
            const { limit } = payload;
            return {
                ...state,
                limit
            };
        };

        default:
            throw new Error();
    }
};

const SmsContext = createContext(initialState);

const SmsProvider = ({ children }) => {
    const stateAndDispatch = useReducer(reducer, initialState);
    return (
        <SmsContext.Provider value={stateAndDispatch}>
            {children}
        </SmsContext.Provider>
    );
}

export const useSms = () => useContext(SmsContext);

export { SmsContext, SmsProvider, ACTIONS, MAX_PAGE_SIZE };
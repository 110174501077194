import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

import {
  Button,
  Form,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import { utils } from '../../../../utils/utils';

const CloseOutWorkOrderDetailsModal = ({
  workOrder,
  onSave,
  onClose,
  title,
}) => {
  const [closeOutWorkOrder, setCloseOutWorkOrder] = useState({});
  const [totalHours, setTotalHours] = useState({});

  const CloseBtn = ({ onClose }) => (
    <Button className='close' color='none' onClick={onClose}>
      &times;
    </Button>
  );

  const handleChangeToClose = (e) => {
    e.preventDefault();
    onSave();
  };

  useEffect(() => {
    const { totalHours, closeOutWorkOrder } = utils.calculateCloseOutWorkOrder(workOrder);
    setTotalHours(totalHours);
    setCloseOutWorkOrder(closeOutWorkOrder);
  }, [workOrder]);


  return (
    <Modal isOpen={true}>
      <Form onSubmit={handleChangeToClose}>
        <ModalHeader close={<CloseBtn onClose={onClose} />}>
          {title}
        </ModalHeader>
        <ModalBody>
          <ListGroup className='my-2 col-12'>
            <ListGroupItem
              className='d-flex justify-content-center align-items-center py-2 bg-lighter font-500'
              tag='div'
            >
              By {closeOutWorkOrder.billingType} Breakdown
            </ListGroupItem>
            <ListGroupItem
              className='d-flex justify-content-between align-items-center py-2'
              tag='div'
            >
              <span>Total Tractor Hours</span>
              <span>{totalHours.tractorHours?.toFixed(2) || '-'} Hrs.</span>
            </ListGroupItem>
            <ListGroupItem
              className='d-flex justify-content-between align-items-center py-2'
              tag='div'
            >
              <span>Total Acres</span>
              <span>
                {closeOutWorkOrder.workedAcres?.toFixed(0) || '-'} Acr.
              </span>
            </ListGroupItem>
            <ListGroupItem
              className='d-flex justify-content-between align-items-center py-2'
              tag='div'
            >
              <span>Billing Total</span>
              <span>$ {utils.roundUp(closeOutWorkOrder.billingTotal)}</span>
            </ListGroupItem>
            <ListGroupItem
              className='d-flex justify-content-between align-items-center py-2'
              tag='div'
            >
              <span>
                Rate Per{' '}
                {utils.getAnotherBillingTypeLabel(closeOutWorkOrder.billingType)}{' '}
                (Actual)
              </span>
              <span>$ {utils.roundUp(closeOutWorkOrder.ratePer)}</span>
            </ListGroupItem>
            <ListGroupItem
              className='d-flex justify-content-between align-items-center py-2'
              tag='div'
            >
              <span>
                If Billed By{' '}
                {utils.getAnotherBillingTypeLabel(closeOutWorkOrder.billingType)}
              </span>
              <span>$ {utils.roundUp(closeOutWorkOrder.ifBilledBy)}</span>
            </ListGroupItem>
            <ListGroupItem
              className='d-flex justify-content-between align-items-center py-2'
              tag='div'
            >
              <span>Cost Per {closeOutWorkOrder.billingType}</span>
              <span>$ {utils.roundUp(closeOutWorkOrder.costPer)}</span>
            </ListGroupItem>
          </ListGroup>
        </ModalBody>
        <ModalFooter className='d-flex justify-content-between align-items-center'>
          <Button onClick={onClose}>Back</Button>
          <Button
            color='primary'
            className='rounded'
          >
            <FontAwesomeIcon icon={faSave} className='mr-2' />
            <span>Save</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
export default CloseOutWorkOrderDetailsModal;

import moment from 'moment';
import React, { Component } from 'react';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
  Table,
} from 'reactstrap';
import * as turf from '@turf/turf';
import { utils } from '../../../../utils/utils';
import './Report.css';

const STATUS_CLOSED = 0;
const GEO_JSON_LENGTH_LIMIT = 6494;

const FormField = ({ label, value }) =>
  value ? (
    <FormGroup row>
      <Label xs={3}>{label}</Label>
      <Col xs={9}>
        <Input type='text' value={value || '-'} readOnly />
      </Col>
    </FormGroup>
  ) : null;

class ComponentToPrint extends Component {
  getStatus(status) {
    switch (status) {
      case 0:
        return 'Closed';
      case 1:
        return 'Open';
      case 2:
        return 'Invoiced';
      case 3:
        return 'Printed';
      case 4:
        return 'Void';
      default:
        return status;
    }
  }

  render() {
    const { getStatus } = this;
    const { workOrder, mapProperties } = this.props;

    const { customer, farm, contact, assignations, operations } = workOrder;
    const { commodity } = farm;

    const operationType = { label: '' };

    const operationResult = operations.find(
      (operation) => operation.operation_type_id === workOrder.operation_type_id
    );

    if (operationResult) {
      const { name, id } = operationResult.operationType;
      operationType.value = id;
      operationType.label = name;
    }

    const { totalHours, closeOutWorkOrder } =
      utils.calculateCloseOutWorkOrder(workOrder);

    const stringGeojson =
      JSON.stringify(workOrder?.farm?.geojson).length > GEO_JSON_LENGTH_LIMIT
        ? JSON.stringify(turf.envelope(workOrder?.farm?.geojson))
        : JSON.stringify(workOrder?.farm?.geojson);

    const geojson = encodeURIComponent(stringGeojson);

    const mapUrl = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/geojson(${geojson})/${mapProperties.mapCenter.lng
      },${mapProperties.mapCenter.lat},${mapProperties.mapZoom || 14
      }/600x950?access_token=${process.env.REACT_APP_MB_ACCESS_TOKEN}`;

    return (
      <div className='d-sm-none d-print-flex flex-column col-12'>
        <Container>
          <h1 className='my-5'>Work Order #{workOrder?.work_order_number}</h1>
          <Row>
            <Col className='col-12'>
              <Card className='box-shadow-none border'>
                <CardHeader>
                  <div className='d-flex justify-content-between'>
                    <div className='d-flex justify-content-between'>
                      <div className='mr-3'>
                        <div className='h4'>
                          {workOrder?.customer?.name ||
                            'No customer name provided'}
                        </div>
                        <div>
                          Contact:{' '}
                          <span className='text-muted'>
                            {workOrder?.contact?.name || 'Not provided'}
                          </span>
                        </div>
                        <div>
                          Phone:{' '}
                          <span className='text-muted'>
                            {workOrder?.contact?.phone || 'Not provided'}
                          </span>
                        </div>
                        <div>
                          Email:{' '}
                          <span className='text-muted'>
                            {workOrder?.contact?.email || 'Not provided'}
                          </span>
                        </div>
                      </div>
                      <div>
                        <Badge>{getStatus(workOrder.status)}</Badge>
                      </div>
                    </div>
                    <div>
                      <div className='h4'>
                        <span>{workOrder?.farm?.name}</span>
                      </div>
                      <div>
                        Start Date:{' '}
                        <span className='text-muted'>
                          {workOrder?.start_date || '-'}
                        </span>
                      </div>
                      <div>
                        End Date:{' '}
                        <span className='text-muted'>
                          {workOrder?.end_date || '-'}
                        </span>
                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className='col-6'>
              <Card className='box-shadow-none border no-break-inside'>
                <CardHeader>
                  <h4>Details</h4>
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormField label='Customer' value={customer?.name} />
                    <FormField label='Farm' value={farm?.name} />
                    <FormField label='Field #' value={farm?.field_number} />
                    <FormField label='Commodity' value={commodity?.name} />
                    <FormField label='Acres' value={farm?.acres} />
                    <FormField label='Row Spacing' value={farm?.row_spacing} />
                    <FormField
                      label='Contact'
                      value={
                        contact ? contact.name + ' / ' + contact.phone : ''
                      }
                    />
                    <FormField
                      label='Customer PO#'
                      value={workOrder.customer_PO_number}
                    />
                    <FormField
                      label='Operation Type'
                      value={operationType.label}
                    />
                    <FormField
                      label='Operations'
                      value={operations
                        ?.map((operation) => operation.name)
                        ?.join(',')}
                    />
                    <FormField label='Estimate' value={workOrder.estimate} />
                    <FormField
                      label='USA Ticket'
                      value={workOrder.usa_ticket}
                    />
                    <FormField
                      label='Start Date'
                      value={workOrder.start_date}
                    />
                    <FormField label='End Date' value={workOrder.end_date} />
                    <FormField
                      label='Operators'
                      value={assignations
                        ?.map((assignation) => assignation.user.name)
                        ?.join(',')}
                    />
                    <FormField
                      label='Created By'
                      value={workOrder.createdBy.name}
                    />
                  </Form>
                </CardBody>
              </Card>
              <Card className='box-shadow-none border no-break-inside'>
                <CardHeader>
                  <h4>Notes</h4>
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormGroup>
                      <Label>Office Notes</Label>
                      <Input
                        style={{ minHeight: '3vh' }}
                        type='textarea'
                        value={workOrder.office_notes || '-'}
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>App Notes</Label>
                      <Input
                        style={{ minHeight: '3vh' }}
                        type='textarea'
                        value={
                          workOrder.app_notes ||
                          'There are not app notes for this work order'
                        }
                        readOnly
                      />
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col className='col-6'>
              <Card className='box-shadow-none border no-break-inside col-12'>
                <CardHeader>
                  <h4>Purchase Orders</h4>
                </CardHeader>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Vendor</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {workOrder.purchaseOrders.length === 0 ? (
                        <tr>
                          <td
                            colSpan={3}
                            className='text-center text-muted small'
                          >
                            There are no purchase orders
                          </td>
                        </tr>
                      ) : (
                        workOrder.purchaseOrders.map((purchaseOrder) => (
                          <tr key={purchaseOrder.id}>
                            <td>{purchaseOrder.date}</td>
                            <td>{purchaseOrder.vendor?.name || '-'}</td>
                            <td>$ {purchaseOrder.amount}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
              {mapUrl ? (
                <div className='col-12 px-0'>
                  <Col className='text-center text-truncate'>
                    <img src={mapUrl} alt={workOrder?.farm?.name} />
                  </Col>
                </div>
              ) : null}
            </Col>
          </Row>
          <div className='work-performed-container small'>
            <h1 className='my-5'>Work Performed</h1>
            <Table bordered responsive>
              <thead className='small text-muted'>
                <tr>
                  <th className='align-middle'>Date</th>
                  <th className='align-middle'>Operator</th>
                  <th className='align-middle'>Op. Type</th>
                  <th className='align-middle'>Pass #</th>
                  <th className='align-middle'>Job Ticket #</th>
                  <th className='align-middle'>Pass Per Row</th>
                  <th className='align-middle'>Pickup / Auto</th>
                  <th className='align-middle'>Tractor</th>
                  <th className='align-middle'>Tractor Start Time</th>
                  <th className='align-middle'>Tractor End Time</th>
                  <th className='align-middle text-center'>Tractor Hours</th>
                  <th className='align-middle text-center'>Tractor Time</th>
                  <th className='align-middle text-center'>Operator Time</th>
                </tr>
              </thead>
              <tbody className='small'>
                {!workOrder.workDays || !workOrder.workDays.length ? (
                  <tr>
                    <td colSpan='13' className='text-center text-muted'>
                      There are no work days
                    </td>
                  </tr>
                ) : (
                  <>
                    {workOrder.workDays
                      .sort((a, b) => new Date(a.date) - new Date(b.date))
                      .map((workDay) => {
                        const offset = new Date().getTimezoneOffset() / 60;
                        const newDate = utils.formatDate(
                          new Date(workDay.date).setHours(
                            new Date(workDay.date).getHours() + offset
                          )
                        );
                        return (
                          <tr key={workDay.id}>
                            <td>{newDate}</td>
                            <td>{workDay.user?.name || '-'}</td>
                            <td>{workDay.operation?.name || '-'}</td>
                            <td>{workDay.pass_number || '-'}</td>
                            <td>{workDay.job_ticket || '-'}</td>
                            <td className='text-capitalize'>
                              {workDay.pass_per_row || '-'}
                            </td>
                            <td>{workDay.vehicle?.equipment || '-'}</td>
                            <td>{workDay.equipment?.equipment || '-'}</td>
                            <td>
                              {moment(workDay.tractor_start_time).format(
                                'MM/DD/YYYY hh:mm'
                              ) || '-'}
                            </td>
                            <td>
                              {moment(workDay.tractor_end_time).format(
                                'MM/DD/YYYY hh:mm'
                              ) || '-'}
                            </td>
                            <td>
                              <table>
                                <tbody>
                                  <tr className='d-none'>
                                    <th>Start</th>
                                    <td>
                                      {workDay.tractor_start_hours?.toFixed(
                                        2
                                      ) || '-'}
                                    </td>
                                  </tr>
                                  <tr className='d-none'>
                                    <th>End</th>
                                    <td>
                                      {workDay.tractor_end_hours?.toFixed(
                                        2
                                      ) || '-'}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Total:</th>
                                    <td>
                                      {workDay.tractor_end_hours
                                        ? `${(
                                          workDay.tractor_end_hours -
                                          workDay.tractor_start_hours
                                        )?.toFixed(2)} Hrs.`
                                        : '0.00 Hrs.'}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table>
                                <tbody>
                                  <tr className='d-none'>
                                    <th>Start</th>
                                    <td>
                                      {utils.formatTime(
                                        new Date(workDay.tractor_start_time)
                                      )}
                                    </td>
                                  </tr>
                                  <tr className='d-none'>
                                    <th>End</th>
                                    <td>
                                      {utils.formatTime(
                                        new Date(workDay.tractor_end_time)
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Total:</th>
                                    <td>
                                      {utils.getDateDiff(
                                        workDay.tractor_start_time,
                                        workDay.tractor_end_time
                                      )}{' '}
                                      Hrs.
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table>
                                <tbody>
                                  <tr className='d-none'>
                                    <th>Start</th>
                                    <td>
                                      {utils.formatTime(
                                        new Date(
                                          workDay.operator_start_time
                                        )
                                      )}
                                    </td>
                                  </tr>
                                  <tr className='d-none'>
                                    <th>End</th>
                                    <td>
                                      {utils.formatTime(
                                        new Date(workDay.operator_end_time)
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Total:</th>
                                    <td>
                                      {utils.getDateDiff(
                                        workDay.operator_start_time,
                                        workDay.operator_end_time
                                      )}{' '}
                                      Hrs.
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        );
                      })}
                    <tr>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td className='text-center'>
                        Total: {totalHours.tractorHours}
                      </td>
                      <td className='text-center'>
                        Total: {totalHours.tractorTime}
                      </td>
                      <td className='text-center'>
                        Total: {totalHours.operatorTime}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </div>
          {workOrder.status === STATUS_CLOSED && totalHours && (
            <div className='breakdown'>
              <h1 className='my-5'>Close Out Breakdown</h1>
              <Row>
                <Col>
                  <ListGroup className='my-2 col-12 col-lg-6'>
                    <ListGroupItem className='d-flex justify-content-center align-items-center py-2 bg-lighter font-500'>
                      Total Tractor Hours
                    </ListGroupItem>
                    <ListGroupItem className='text-center py-2'>
                      {totalHours.tractorHours} Hrs
                    </ListGroupItem>
                  </ListGroup>
                </Col>
                <Col>
                  <ListGroup className='my-2 col-12 col-lg-6'>
                    <ListGroupItem className='d-flex justify-content-center align-items-center py-2 bg-lighter font-500'>
                      Hours Billing Rate
                    </ListGroupItem>
                    <ListGroupItem className='text-center py-2'>
                      {closeOutWorkOrder.hourBillingRate
                        ? `$${closeOutWorkOrder.hourBillingRate.toFixed(
                          2
                        )}`
                        : '0'}
                    </ListGroupItem>
                  </ListGroup>
                </Col>
                <Col>
                  <ListGroup className='my-2 col-12 col-lg-6'>
                    <ListGroupItem className='d-flex justify-content-center align-items-center py-2 bg-lighter font-500'>
                      Total Acres
                    </ListGroupItem>
                    <ListGroupItem className='text-center py-2'>
                      {closeOutWorkOrder.workedAcres} Acr
                    </ListGroupItem>
                  </ListGroup>
                </Col>
                <Col>
                  <ListGroup className='my-2 col-12 col-lg-6'>
                    <ListGroupItem className='d-flex justify-content-center align-items-center py-2 bg-lighter font-500'>
                      Per Acre Rate
                    </ListGroupItem>
                    <ListGroupItem className='text-center py-2'>
                      {closeOutWorkOrder.acreBillingRate
                        ? `$${closeOutWorkOrder.acreBillingRate.toFixed(
                          2
                        )}`
                        : '0'}
                    </ListGroupItem>
                  </ListGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ListGroup className='my-2 col-12 col-lg-6'>
                    <ListGroupItem className='d-flex justify-content-center align-items-center py-2 bg-lighter font-500'>
                      By Hour Calculation
                    </ListGroupItem>
                    <ListGroupItem
                      className='d-flex justify-content-between align-items-center py-2'
                      tag='div'
                    >
                      <strong>Total</strong>
                      <span>
                        {totalHours.tractorHours} Hrs x $
                        {closeOutWorkOrder.hourBillingRate?.toFixed(2) ||
                          '0'}{' '}
                        = $
                        {(
                          totalHours.tractorHours *
                          closeOutWorkOrder.hourBillingRate
                        )?.toFixed(2) || '0'}
                      </span>
                    </ListGroupItem>
                    <ListGroupItem
                      className='d-flex justify-content-between align-items-center py-2'
                      tag='div'
                    >
                      <strong>Cost per Acre</strong>
                      <span>
                        $
                        {(
                          closeOutWorkOrder.hourBillingRate *
                          totalHours.tractorHours
                        )?.toFixed(2) || '0'}{' '}
                        / {closeOutWorkOrder.workedAcres} Acr = $
                        {closeOutWorkOrder.workedAcres
                          ? (
                            (closeOutWorkOrder.hourBillingRate *
                              totalHours.tractorHours) /
                            closeOutWorkOrder.workedAcres
                          )?.toFixed(2)
                          : '0'}
                      </span>
                    </ListGroupItem>
                  </ListGroup>
                </Col>

                <Col>
                  <ListGroup className='my-2 col-12 col-lg-6'>
                    <ListGroupItem
                      className='d-flex justify-content-center align-items-center py-2 bg-lighter font-500'
                      tag='div'
                    >
                      By Acre Calculation
                    </ListGroupItem>
                    <ListGroupItem
                      className='d-flex justify-content-between align-items-center py-2'
                      tag='div'
                    >
                      <strong>Total</strong>
                      <span>
                        $
                        {closeOutWorkOrder.acreBillingRate?.toFixed(2) ||
                          '0'}{' '}
                        x {closeOutWorkOrder.workedAcres} Acr = $
                        {(
                          closeOutWorkOrder.acreBillingRate *
                          closeOutWorkOrder.workedAcres
                        )?.toFixed(2) || '0'}
                      </span>
                    </ListGroupItem>
                    <ListGroupItem
                      className='d-flex justify-content-between align-items-center py-2'
                      tag='div'
                    >
                      <strong>Cost per Hour</strong>
                      <span>
                        $
                        {(
                          closeOutWorkOrder.acreBillingRate *
                          closeOutWorkOrder.workedAcres
                        )?.toFixed(2) || '0'}{' '}
                        / {totalHours.tractorHours} Hrs = $
                        {totalHours.tractorHours
                          ? (
                            (closeOutWorkOrder.acreBillingRate *
                              closeOutWorkOrder.workedAcres) /
                            totalHours.tractorHours
                          )?.toFixed(2)
                          : '0'}
                      </span>
                    </ListGroupItem>
                  </ListGroup>
                </Col>
              </Row>

              <Row>
                <Col className='col-6'>
                  <ListGroup className='my-2 col-12 col-lg-6'>
                    <ListGroupItem
                      className='d-flex justify-content-center align-items-center py-2 bg-lighter font-500'
                      tag='div'
                    >
                      Actual Billing Breakdown
                    </ListGroupItem>
                    <ListGroupItem
                      className='d-flex justify-content-between align-items-center py-2'
                      tag='div'
                    >
                      <strong>Billed</strong>
                      <span>{closeOutWorkOrder.billingType}</span>
                    </ListGroupItem>
                    <ListGroupItem
                      className='d-flex justify-content-between align-items-center py-2'
                      tag='div'
                    >
                      <strong>Billing total</strong>
                      <span>
                        ${closeOutWorkOrder.billingTotal || '0.00'}
                      </span>
                    </ListGroupItem>
                  </ListGroup>
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </div>
    );
  }
}

export default ComponentToPrint;
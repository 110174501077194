import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import * as turf from '@turf/turf';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { farmsApi } from '../services/farmsServices';

const SH_COORDINATES = [-121.150232, 37.981695];
const MAP_BOX_ZOOM = 15;

const COUNTY_NAMES = {
  39: 'San Joaquin',
  15: 'Kern',
  7: 'Contra Costa',
  34: 'Sacramento',
  42: 'Santa Barbara',
};

const SELECTED_FIELD = 'SELECTED_FIELD';

export const mapUtils = {
  getCenter: (workOrder, callback) => {
    if (
      workOrder.farm &&
      workOrder.farm.geojson &&
      workOrder.farm.geojson.type
    ) {
      return callback(
        turf.getCoords(
          turf.center(
            workOrder.farm.geojson.type === 'MultiPolygon'
              ? turf.polygon(workOrder.farm.geojson.coordinates[0])
              : workOrder.farm.geojson
          )
        )
      );
    }
    if (
      workOrder.farm &&
      workOrder.farm.centerLat &&
      workOrder.farm.centerLong
    ) {
      return callback([workOrder.farm.centerLong, workOrder.farm.centerLat]);
    }
    return callback(SH_COORDINATES);
  },
  initMap: (workOrder, center, callback) => {
    mapboxgl.accessToken = process.env.REACT_APP_MB_ACCESS_TOKEN;
    const map = new mapboxgl.Map({
      container: 'map',
      style: `mapbox://styles/belovedrobot/${process.env.REACT_APP_MB_CLIENT_STYLE_ID}?fresh=true`,
      center,
      zoom: MAP_BOX_ZOOM,
    });
    map.on('load', () => {
      map.resize();
      map.setLayoutProperty('Farms', 'visibility', 'none');
      map.setLayoutProperty('Farms-Label', 'visibility', 'none');
      map.setLayoutProperty('Farm-Line', 'visibility', 'none');
      map.setLayoutProperty('AllCounties', 'visibility', 'none');
      map.setLayoutProperty('AllCounties-Label', 'visibility', 'none');
      map.setLayoutProperty('AllCounties-Line', 'visibility', 'none');
      if (
        workOrder &&
        workOrder.farm &&
        workOrder.farm.geojson &&
        Object.keys(workOrder.farm.geojson).length
      ) {
        map.addSource('farm', {
          type: 'geojson',
          data: workOrder.farm.geojson,
        });
        map.addLayer({
          id: 'farm-layer',
          type: 'fill',
          source: 'farm',
          layout: {},
          paint: {
            'fill-color': '#fbd309',
            'fill-opacity': 1,
            'fill-outline-color': '#3d3d3d',
          },
        });
        map.addLayer({
          id: 'farm-label-layer',
          type: 'symbol',
          source: 'farm',
          layout: {
            'text-field': workOrder.farm.name,
            'text-size': 14,
          },
        });
      }
      callback(map);
    });
    map.addControl(new mapboxgl.NavigationControl());
  },
};

export const mapCustomers = {
  initMap: (customer = null) => {
    mapboxgl.accessToken = process.env.REACT_APP_MB_ACCESS_TOKEN;
    const map = new mapboxgl.Map({
      container: 'map',
      style: `mapbox://styles/belovedrobot/${process.env.REACT_APP_MB_CLIENT_STYLE_ID}?fresh=true`,
      center: SH_COORDINATES,
      zoom: MAP_BOX_ZOOM,
    });
    map.on('load', () => {
      if (customer) {
        if (customer.farms_geojson?.features?.length) {
          let bbox = turf.bbox(customer.farms_geojson);
          map.fitBounds(bbox, { padding: 20 });
          map.addSource('farm', {
            type: 'geojson',
            data: customer.farms_geojson,
          });
          map.addLayer({
            id: 'farm-layer',
            type: 'fill',
            source: 'farm',
            layout: {},
            paint: {
              'fill-color': '#fbd309',
              'fill-opacity': 0.6,
              'fill-outline-color': '#3d3d3d',
            },
          });
          map.addLayer({
            id: 'farm-text-layer',
            type: 'symbol',
            source: 'farm',
            layout: {
              'text-field': '{farm}',
              'text-size': 12,
            },
          });
        }
      }
      map.resize();
      map.setLayoutProperty('Farms', 'visibility', !customer ? 'visible' : 'none');
      map.setLayoutProperty('Farms-Label', 'visibility', !customer ? 'visible' : 'none');
      map.setLayoutProperty('Farm-Line', 'visibility', !customer ? 'visible' : 'none');
      map.setLayoutProperty('AllCounties', 'visibility', !customer ? 'visible' : 'none');
      map.setLayoutProperty('AllCounties-Label', 'visibility', !customer ? 'visible' : 'none');
      map.setLayoutProperty('AllCounties-Line', 'visibility', !customer ? 'visible' : 'none');
    });

    map.on('click', 'Farms', async (e) => {
      const feature = e.features[0];
      let acres = feature.properties.acres;
      const farm = await farmsApi.getFarm(feature.properties.id);
      if (farm.geojson.type === 'MultiPolygon') {
        const polygon = farm.geojson.coordinates.find(coordinates => {
          const polygon = turf.polygon(coordinates);
          const booleanPointInPolygon = turf.booleanPointInPolygon([e.lngLat.lng, e.lngLat.lat], polygon);
          return booleanPointInPolygon;
        });
        if (polygon) {
          const sqMtArea = turf.area(turf.polygon(polygon));
          acres = turf.convertArea(sqMtArea, 'meters', 'acres').toFixed(2);
        }
      }
      if (typeof map.getLayer(SELECTED_FIELD) !== 'undefined') {
        map.removeLayer(SELECTED_FIELD);
        map.removeSource(SELECTED_FIELD);
      }
      map.addSource(SELECTED_FIELD, {
        type: 'geojson',
        data: feature.toJSON(),
      });
      map.addLayer({
        id: SELECTED_FIELD,
        source: SELECTED_FIELD,
        type: 'fill',
        layout: {},
        paint: {
          'fill-color': 'rgba(58,134,134, 0.45)',
          'fill-outline-color': 'yellow',
        },
      });
      new mapboxgl.Popup({ "autoClose": false, "closeOnClick": null })
        .setLngLat(e.lngLat)
        .setHTML(`
          <div class="mt-2">
            <strong>${feature.properties.farm}</strong><br/>
            <span>${feature.properties.county || "-"}<span><br/>
            <span>${feature.properties.customer_name || "-"}<span><br/>
            <span>${feature.properties.commodity || "-"}<span><br/>
            <span>${acres} acres<span><br/>
            <a style="color: #0D83DD" href="/customers/${feature.properties.customer_id}/farm/${feature.properties.id}">See more<a/>
          </div>
        `)
        .addTo(map);
    });

    map.on('click', 'farm-layer', async (e) => {
      const feature = e.features[0];
      let acres = feature.properties.acres;
      const farm = await farmsApi.getFarm(feature.properties.id);
      if (farm.geojson.type === 'MultiPolygon') {
        const polygon = farm.geojson.coordinates.find(coordinates => {
          const polygon = turf.polygon(coordinates);
          const booleanPointInPolygon = turf.booleanPointInPolygon([e.lngLat.lng, e.lngLat.lat], polygon);
          return booleanPointInPolygon;
        });
        if (polygon) {
          const sqMtArea = turf.area(turf.polygon(polygon));
          acres = turf.convertArea(sqMtArea, 'meters', 'acres').toFixed(2);
        }
      }
      if (typeof map.getLayer(SELECTED_FIELD) !== 'undefined') {
        map.removeLayer(SELECTED_FIELD);
        map.removeSource(SELECTED_FIELD);
      }
      map.addSource(SELECTED_FIELD, {
        type: 'geojson',
        data: feature.toJSON(),
      });
      map.addLayer({
        id: SELECTED_FIELD,
        source: SELECTED_FIELD,
        type: 'fill',
        layout: {},
        paint: {
          'fill-color': 'rgba(58,134,134, 0.45)',
          'fill-outline-color': 'yellow',
        },
      });
      new mapboxgl.Popup({ "autoClose": false, "closeOnClick": null })
        .setLngLat(e.lngLat)
        .setHTML(`
          <div class="mt-2">
            <strong>${feature.properties.farm}</strong><br/>
            <span>${feature.properties.county || "-"}<span><br/>
            <span>${feature.properties.customer_name || "-"}<span><br/>
            <span>${feature.properties.commodity || "-"}<span><br/>
            <span>${acres} acres<span><br/>
            <a style="color: #0D83DD" href="/customers/${feature.properties.customer_id}/farm/${feature.properties.id}">See more<a/>
          </div>
        `)
        .addTo(map);
    });

    map.on('click', 'AllCounties', (e) => {
      if (e.features?.length > 0) {
        if (typeof map.getLayer(SELECTED_FIELD) !== 'undefined') {
          map.removeLayer(SELECTED_FIELD);
          map.removeSource(SELECTED_FIELD);
        }

        const feature = e.features[0];
        map.addSource(SELECTED_FIELD, {
          type: 'geojson',
          data: feature.toJSON(),
        });
        map.addLayer({
          id: SELECTED_FIELD,
          source: SELECTED_FIELD,
          type: 'fill',
          layout: {},
          paint: {
            'fill-color': 'rgba(58,134,134, 0.45)',
            'fill-outline-color': 'yellow',
          },
        });

        const coordinates = turf.getCoords(
          turf.center(turf.polygon(feature.geometry.coordinates))
        );

        const countyName =
          COUNTY_NAMES[feature.properties.county_num] ||
          feature.properties.County_Name;

        const size = feature.properties.size ? feature.properties.size.toFixed(2) + ' acres' : 'N/A';

        const popupActions =
          `<a style="color: #0D83DD" href="/farms/new/${feature.properties.OBJECTID}?centerLat=${coordinates[1]}&centerLong=${coordinates[0]}">Add to Customer<a/><br/>
        <a style="color: #0D83DD" href="/farms/new/${feature.properties.OBJECTID}?centerLat=${coordinates[1]}&centerLong=${coordinates[0]}&newCustomer=true">Create new Customer<a/>`;

        new mapboxgl.Popup({ "autoClose": false, "closeOnClick": null })
          .setLngLat(e.lngLat)
          .setHTML(`
            <div class="mt-2">
              <strong>${countyName}</strong><br/>
              <span>${feature.properties.permittee}<span><br/>
              <span>${feature.properties.crop_list}<span><br/>
              <span>${size}<span><br/>
              ${!customer ? popupActions : ''}
            </div>
          `)
          .addTo(map);
      }
    });

    map.addControl(new mapboxgl.NavigationControl());
    return map;
  },
  showAll: (map) => {
    map.setLayoutProperty('Farms', 'visibility', 'visible');
    map.setLayoutProperty('Farms-Label', 'visibility', 'visible');
    map.setLayoutProperty('Farm-Line', 'visibility', 'visible');
    map.setLayoutProperty('AllCounties', 'visibility', 'visible');
    map.setLayoutProperty('AllCounties-Label', 'visibility', 'visible');
    map.setLayoutProperty('AllCounties-Line', 'visibility', 'visible');
  },
  showCounties: (map) => {
    map.setLayoutProperty('Farms', 'visibility', 'none');
    map.setLayoutProperty('Farms-Label', 'visibility', 'none');
    map.setLayoutProperty('Farm-Line', 'visibility', 'none');
    map.setLayoutProperty('AllCounties', 'visibility', 'visible');
    map.setLayoutProperty('AllCounties-Label', 'visibility', 'visible');
    map.setLayoutProperty('AllCounties-Line', 'visibility', 'visible');
  },
  showCustomer: (map) => {
    map.setLayoutProperty('Farms', 'visibility', 'visible');
    map.setLayoutProperty('Farms-Label', 'visibility', 'visible');
    map.setLayoutProperty('Farm-Line', 'visibility', 'visible');
    map.setLayoutProperty('AllCounties', 'visibility', 'none');
    map.setLayoutProperty('AllCounties-Label', 'visibility', 'none');
    map.setLayoutProperty('AllCounties-Line', 'visibility', 'none');
  },
  flyToFarm: (map, farm) => {
    if (farm.geojson) {
      if (map.getLayer('farm-layer')) {
        map.removeLayer('farm-layer');
      }
      if (map.getSource('farm')) {
        map.removeSource('farm');
      }
      map.addSource('farm', {
        type: 'geojson',
        data: farm.geojson,
      });
      map.addLayer({
        id: 'farm-layer',
        type: 'fill',
        source: 'farm',
        layout: {},
        paint: {
          'fill-color': '#088',
          'fill-opacity': 0.5,
          'fill-outline-color': '#088',
        },
      });
      let center = turf.center(farm.geojson);
      map.flyTo({ center: center.geometry.coordinates, zoom: 15 });
    }
  },
  clearSelectedFarms: (map) => {
    if (map.getLayer('farm-layer')) {
      map.removeLayer('farm-layer');
    }
    if (map.getSource('farm')) {
      map.removeSource('farm');
    }
  },
};

const drawer = new MapboxDraw({
  displayControlsDefault: false,
  controls: {
    polygon: true,
    trash: false,
  },
  styles: [
    {
      id: 'gl-draw-polygon-fill',
      type: 'fill',
      paint: {
        'fill-color': '#fbd309',
        'fill-outline-color': '#3d3d3d',
        'fill-opacity': 0.6,
      },
    },
    {
      id: 'gl-draw-polygon-stroke-active',
      type: 'line',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#FFA500',
        'line-width': 2,
        'line-dasharray': [3, 4],
      },
    },
    {
      id: 'gl-draw-polygon-and-line-vertex-active',
      type: 'circle',
      paint: {
        'circle-radius': 4,
        'circle-color': '#FFA500',
        'circle-stroke-width': 2,
        'circle-stroke-color': '#FFF',
      },
    },
  ],
});

const coordinatesGeocoder = (query) => {
  // match anything which looks like a decimal degrees coordinate pair
  const matches = query.match(
    /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
  );
  if (!matches) {
    return null;
  }

  function coordinateFeature(lng, lat) {
    return {
      center: [lng, lat],
      geometry: {
        type: 'Point',
        coordinates: [lng, lat],
      },
      place_name: 'Lat: ' + lat + ' Lng: ' + lng,
      place_type: ['coordinate'],
      properties: {},
      type: 'Feature',
    };
  }

  const coord1 = Number(matches[1]);
  const coord2 = Number(matches[2]);
  const geocodes = [];

  if (coord1 < -90 || coord1 > 90) {
    // must be lng, lat
    geocodes.push(coordinateFeature(coord1, coord2));
  }

  if (coord2 < -90 || coord2 > 90) {
    // must be lat, lng
    geocodes.push(coordinateFeature(coord2, coord1));
  }

  if (geocodes.length === 0) {
    // else could be either lng, lat or lat, lng
    geocodes.push(coordinateFeature(coord1, coord2));
    geocodes.push(coordinateFeature(coord2, coord1));
  }

  return geocodes;
};

let mapInstance = null;
const popups = {};

const addPopups = (geojson, map) => {
  if (geojson.type === "Polygon") {
    const center = turf.getCoords(turf.center(geojson));
    const area = turf.area(geojson);
    const acres = turf.convertArea(area, 'meters', 'acres').toFixed(2)
    if (center?.length) {
      if (popups[0]) {
        popups[0].remove();
      };
      popups[0] = new mapboxgl.Popup({ "autoClose": false, "closeOnClick": null })
        .setLngLat({ lat: center[1], lng: center[0] })
        .setHTML(`<div class="mt-2">${acres} acres.</div>`)
        .addTo(map);
    };
  } else {
    geojson.coordinates.forEach((coordinate, i) => {
      const polygon = turf.polygon(coordinate);
      const center = turf.getCoords(turf.center(polygon));
      const area = turf.area(polygon);
      const acres = turf.convertArea(area, 'meters', 'acres').toFixed(2)
      if (center?.length) {
        if (popups[i]) {
          popups[i].remove();
        };
        popups[i] = new mapboxgl.Popup({ "autoClose": false, "closeOnClick": null })
          .setLngLat({ lat: center[1], lng: center[0] })
          .setHTML(`<div class="mt-2">${acres} acres.</div>`)
          .addTo(map);
      };
    });
  };
};

export const mapFarms = {
  initMap: (farm, onCalculate) => {
    mapboxgl.accessToken = process.env.REACT_APP_MB_ACCESS_TOKEN;

    const map = new mapboxgl.Map({
      container: 'map',
      style: `mapbox://styles/belovedrobot/${process.env.REACT_APP_MB_CLIENT_STYLE_ID}?fresh=true`,
      center: SH_COORDINATES,
      zoom: MAP_BOX_ZOOM,
    });

    mapInstance = map;

    map.on('load', () => {
      map.resize();
      map.setLayoutProperty('Farms', 'visibility', 'none');
      map.setLayoutProperty('Farms-Label', 'visibility', 'none');
      map.setLayoutProperty('Farm-Line', 'visibility', 'none');
      map.setLayoutProperty('AllCounties', 'visibility', 'none');
      map.setLayoutProperty('AllCounties-Label', 'visibility', 'none');
      map.setLayoutProperty('AllCounties-Line', 'visibility', 'none');
      if (
        farm &&
        farm.geojson &&
        Object.keys(farm.geojson).length
      ) {
        map.addSource('farm', {
          type: 'geojson',
          data: farm.geojson,
        });
        map.addLayer({
          id: 'farm-layer',
          type: 'fill',
          source: 'farm',
          layout: {},
          farmData: farm,
          paint: {
            'fill-color': '#fbd309',
            'fill-opacity': 1,
            'fill-outline-color': '#3d3d3d',
          },
        });
        map.addLayer({
          id: 'farm-label-layer',
          type: 'symbol',
          source: 'farm',
          layout: {
            'text-field': farm.name,
            'text-size': 14,
          },
        });
        map.on('click', 'farm-layer', (e) => {
          if (typeof map.getLayer(SELECTED_FIELD) !== 'undefined') {
            map.removeLayer(SELECTED_FIELD);
            map.removeSource(SELECTED_FIELD);
          }
          const feature = e.features[0];
          map.addSource(SELECTED_FIELD, {
            type: 'geojson',
            data: feature.toJSON(),
          });
          map.addLayer({
            id: SELECTED_FIELD,
            source: SELECTED_FIELD,
            type: 'fill',
            layout: {},
            paint: {
              'fill-color': 'rgba(58,134,134, 0.45)',
              'fill-outline-color': 'yellow',
            },
          });

          new mapboxgl.Popup({ "autoClose": false, "closeOnClick": null })
            .setLngLat(e.lngLat)
            .setHTML(`
              <div class="mt-2">
                <strong>${farm.name}</strong><br/>
                <span>${farm.county}<span><br/>
                <span>${farm.customer.name}<span><br/>
                <span>${farm.commodity.name}<span><br/>
                <span>${farm.acres} acres<span><br/>
              </div>
            `)
            .addTo(map);
        });
      }
    });

    map.addControl(
      new MapboxGeocoder({
        position: 'top',
        accessToken: mapboxgl.accessToken,
        localGeocoder: coordinatesGeocoder,
        zoom: MAP_BOX_ZOOM,
        placeholder: 'Address or coordinates..',
        mapboxgl,
      })
    );

    map.addControl(new mapboxgl.NavigationControl());
    map.addControl(drawer);

    const updateArea = () => {
      const data = drawer.getAll();
      const multiPol = turf.combine(data);
      const geojson = multiPol.features[0].geometry;
      if (data.features.length > 0) {
        const area = turf.area(data);
        const acres = turf.convertArea(area, 'meters', 'acres').toFixed(2)
        onCalculate(acres, geojson);
      };
      addPopups(geojson, map);
    };

    map.on('draw.create', updateArea);
    map.on('draw.delete', updateArea);
    map.on('draw.update', updateArea);

    return map;
  },
  drawFarm: (geojson) => {
    drawer.add(geojson);
    const bbox = turf.bbox(geojson);
    mapInstance.fitBounds(bbox, { padding: 20 });
    addPopups(geojson, mapInstance);
  },
  clearDraw: () => {
    drawer.deleteAll();
  },
};

import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import Loader from '../../../../components/Loader';
import { ACTIONS, useWorkOrderDetails } from '../../../../providers/workOrderDetailsProvider';
import { workOrdersApi } from '../../../../services/workOrdersServices';
import CloseOutWorkOrderDetailsModal from './CloseOutWorkOrderDetailsModal';

const CLOSE_STATUS = '0';
const OPEN_STATUS = '1';

const CloseBtn = ({ onClose }) => (
  <Button className='close' color='none' onClick={onClose}>
    &times;
  </Button>
);

const ChangeWorkOrderStateModal = ({ onClose }) => {
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();
  const [loading, setLoading] = useState();
  const [closeWorkOrder, setCloseWorkOrder] = useState();

  const changeState = (status) => {
    setLoading(true);
    workOrdersApi
      .updateWorkOrder({
        ...workOrderDetails.workOrder,
        total_hours: status === OPEN_STATUS ? 0 : workOrderDetails.workOrder.total_hours,
        status,
      })
      .then(() =>
        setWorkOrderDetails({
          action: ACTIONS.REFRESH
        })
      )
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  const handleChangeStatus = (e) => {
    e.preventDefault();
    const newState = e.target.newState.value;
    if (newState === CLOSE_STATUS) {
      setCloseWorkOrder(true);
    } else {
      changeState(newState);
    }
  };

  return (
    closeWorkOrder ? (
      <CloseOutWorkOrderDetailsModal
        workOrder={workOrderDetails.workOrder}
        onSave={() => {
          setCloseWorkOrder(false);
          changeState(CLOSE_STATUS);
        }}
        onClose={() => setCloseWorkOrder(false)}
        title={'Change Work Order State - Close'}
      />
    ) : (
      <Modal isOpen={true} size='sm'>
        <ModalHeader close={<CloseBtn onClose={onClose} />}>
          Change Work Order Status
        </ModalHeader>
        <Form onSubmit={handleChangeStatus}>
          <ModalBody>
            {loading && <Loader size='sm' />}
            {!loading && (
              <FormGroup className='mb-0'>
                <Label>New Status</Label>
                <Input
                  type='select'
                  name='newState'
                  defaultValue={workOrderDetails.workOrder?.status}
                >
                  {[
                    { value: 1, label: 'Open' },
                    { value: 0, label: 'Closed' },
                    { value: 2, label: 'Invoiced' },
                    { value: 3, label: 'Printed' },
                    { value: 4, label: 'Void' },
                  ].map(({ label, value }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            )}
          </ModalBody>
          <ModalFooter className='d-flex justify-content-between align-items-center'>
            <Button color='secondary' onClick={onClose}>
              Discard
            </Button>
            <Button
              disabled={loading}
              color='primary'
              className='rounded'
            >
              <FontAwesomeIcon icon={faSave} className='mr-2' />
              <span>Save</span>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  );
};

export default ChangeWorkOrderStateModal;

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import { Card, CardBody, CardHeader, ListGroup, ListGroupItem } from 'reactstrap';

import { useWorkOrdersKPIs } from '../../providers/workOrdersKPIsProvider';
import { utils } from '../../utils/utils';
import Header from '../Header';
import InformationModal from '../InformationModal';
import Loader from '../Loader';

const WorkOrdersKPIs = () => {
  const [workOrdersKPIsContext] = useWorkOrdersKPIs();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: '',
    body: '',
  });

  return (
    <Header className='row mb-0'>
      <div className='col-12 col-md-6 col-lg-3'>
        <Card>
          <CardHeader>
            <div className='text-uppercase pb-1 border-bottom text-center'>
              Total Acres
            </div>
          </CardHeader>
          <CardBody className='pt-0 pb-3'>
            {workOrdersKPIsContext.loading ? (
              <Loader size='sm' />
            ) : (
              <div onClick={() => {}} className='text-xl text-center font-500'>
                {utils.formatDecimal(workOrdersKPIsContext.totalAcres)}
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      <div className='col-12 col-md-6 col-lg-3'>
        <Card>
          <CardHeader>
            <div className='text-uppercase pb-1 border-bottom text-center'>
              Total Tractor Hours
            </div>
          </CardHeader>
          <CardBody className='pt-0 pb-3'>
            {workOrdersKPIsContext.loading ? (
              <Loader size='sm' />
            ) : (
              <div onClick={() => {}} className='text-xl text-center font-500'>
                {utils.formatDecimal(workOrdersKPIsContext.totalTractorHours)}
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      <div className='col-12 col-md-6 col-lg-3'>
        <Card>
          <CardHeader>
            <div className='text-uppercase pb-1 border-bottom text-center'>
              Total Operator Hours
            </div>
          </CardHeader>
          <CardBody className='pt-0 pb-3'>
            {workOrdersKPIsContext.loading ? (
              <Loader size='sm' />
            ) : (
              <div onClick={() => {}} className='text-xl text-center font-500'>
                {utils.formatDecimal(workOrdersKPIsContext.totalOperatorHours)}
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      <div className='col-12 col-md-6 col-lg-3'>
        <Card>
          <CardHeader>
            <div className='d-flex justify-content-center border-bottom'>
              <div className='text-uppercase pb-1'>Total Revenue</div>
              <div
                className='ml-2 cursor-pointer'
                onClick={() =>
                  setInformationModal({
                    isOpen: true,
                    title: 'Total Revenue by Commodity',
                    body: (
                      <ListGroup>
                        <ListGroupItem className='d-flex justify-content-between align-items-center bg-lighter font-weight-bold'>
                          <span>Commodity</span>
                          <span>Revenue</span>
                        </ListGroupItem>
                        {workOrdersKPIsContext.totalRevenueByCommodity.map((item) => (
                          <ListGroupItem className='d-flex justify-content-between align-items-center'>
                            <span>{item.name}</span>
                            <span>{utils.formatCurrency(item.totalRevenue)}</span>
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    ),
                  })
                }
              >
                <FontAwesomeIcon
                  size='lg'
                  icon={faInfoCircle}
                  className={`text-secondary`}
                  style={{
                    paddingBottom: '1px',
                  }}
                />
              </div>
            </div>
          </CardHeader>
          <CardBody className='pt-0 pb-3'>
            {workOrdersKPIsContext.loading ? (
              <Loader size='sm' />
            ) : (
              <div onClick={() => {}} className='text-xl text-center font-500'>
                {utils.formatCurrency(workOrdersKPIsContext.totalRevenue)}
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={true}
          onClose={() =>
            setInformationModal({ isOpen: false, title: '', body: '' })
          }
        />
      ) : null}
    </Header>
  );
};

export default WorkOrdersKPIs;

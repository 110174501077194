import mapboxgl from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import React, { useEffect, useState } from 'react';

import { mapCustomers } from '../../../utils/map';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

const CustomerDetailsMap = ({ customer, loading }) => {
  const [rendered, setRendered] = useState(false);

  const [size, setSize] = useState();

  const resizeMap = () => {
    const card = document.getElementById('customerContainer');
    if (card) {
      setSize(card.offsetHeight);
    }
  };

  useEffect(() => {
    if (!rendered) {
      mapboxgl.clearStorage();
      mapCustomers.initMap(customer);
      setRendered(true);
      resizeMap();
    }
  }, [rendered, customer]);

  useEffect(() => {
    if (!loading) {
      resizeMap();
    }
  }, [loading]);

  return (
    <div id="map" className="rounded d-flex" style={{ height: size + 'px' }} />
  );
};

export default CustomerDetailsMap;

import axios from 'axios';
import config from '../config/config';

const handleError = err => {
    if (err?.response?.status === 401) {
        window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}&reason=1`;
    } else if (err?.response?.status === 404) {
        window.location.href = '/error/404';
    } else if (err?.response?.status === 400 || err?.response?.status === 422) {
        throw err;
    } else {
        window.location.href = '/error/500';
    }
}

export const authApi = {
    login: (data) =>
        axios(`/${config.apiVersion}/api/auth/login-back`, {
            baseURL: config.apiURL,
            withCredentials: true,
            method: 'post',
            data: {
                username: data.email,
                password: data.password
            },
        })
            .then(result => result.data),
    logout: () =>
        axios(`/${config.apiVersion}/api/auth/logout`, {
            baseURL: config.apiURL,
            withCredentials: true,
            method: 'get',
        })
            .then(result => result.data),
    validate: () =>
        axios(`/${config.apiVersion}/api/auth/validate`, {
            baseURL: config.apiURL,
            withCredentials: true,
            method: 'get',
        })
            .then(result => result.data)
            .catch(err => handleError(err)),
}
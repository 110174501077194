const api_dev = 'https://api.dev.shi.farm/';
const api_beta = 'https://api.beta.shi.farm/';
const api_prod = 'https://api.shi.farm/';
const api_local = 'http://localhost:3002/';
const apiVersion = 'v2.0.0';
let apiURL;

if (process.env.REACT_APP_ENV === 'prod') {
    apiURL = api_prod;
} else if (process.env.REACT_APP_ENV === 'develop') {
    apiURL = api_dev;
} else if (process.env.REACT_APP_ENV === 'beta') {
    apiURL = api_beta;
} else {
    apiURL = api_local;
}
const config = { apiURL, apiVersion }

export default config;

import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

const ACTIONS_COLUMN = 'actions';

const ColumnFilteringModal = ({ allColumns, onClose, updateUserWorkPerformedTableColumnsSetting }) => {

  const onColumnClick = (column) => {
    column.toggleHidden();
    const workPerformedTableColumns = allColumns
      .filter(c => {
        const checked = c.getToggleHiddenProps().checked;
        return c.id === column.id ? !checked : checked;
      })
      .map(c => c.id);
    const columnsOrder = workPerformedTableColumns;
    const actionsColumnIndex = columnsOrder.findIndex(c => c === ACTIONS_COLUMN);
    const [actionsColumn] = columnsOrder.splice(actionsColumnIndex, 1);
    columnsOrder.push(actionsColumn)
    updateUserWorkPerformedTableColumnsSetting(columnsOrder);
  };

  return (
    <Modal isOpen className='pt-10 pb-10' size='sm'>
      <ModalHeader
        close={
          <Button className='close' color='none' onClick={onClose}>
            &times;
          </Button>
        }
      >
        Show/Hide Columns
      </ModalHeader>
      <ModalBody className='d-flex justify-content-between'>
        <ListGroup>
          {allColumns
            .slice(0, allColumns.length / 2)
            .filter((c) => c.id !== 'actions')
            .map((column, i) => {
              const props = column.getToggleHiddenProps();
              return (
                <ListGroupItem
                  key={i}
                  onClick={() => onColumnClick(column)}
                  className='cursor-pointer d-flex justify-content-start align-items-center py-2'
                  tag='div'
                >
                  <FontAwesomeIcon
                    className='text-primary'
                    size='lg'
                    icon={props.checked ? faCheckCircle : faCircle}
                  />
                  <span className='ml-2'>{column.Header}</span>
                </ListGroupItem>
              );
            })}
        </ListGroup>
        <ListGroup>
          {allColumns
            .slice(allColumns.length / 2)
            .filter((c) => c.id !== 'actions')
            .map((column, i) => {
              const props = column.getToggleHiddenProps();
              return (
                <ListGroupItem
                  key={i}
                  onClick={() => onColumnClick(column)}
                  className='cursor-pointer d-flex justify-content-start align-items-center py-2'
                  tag='div'
                >
                  <FontAwesomeIcon
                    className='text-primary'
                    size='lg'
                    icon={props.checked ? faCheckCircle : faCircle}
                  />
                  <span className='ml-2'>{column.Header}</span>
                </ListGroupItem>
              );
            })}
        </ListGroup>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ColumnFilteringModal;

import React, { useReducer, createContext, useContext } from "react";

const DETAILS_TAB = 'DETAILS_TAB';
const WORK_PERFORMED_TAB = 'WORK_PERFORMED_TAB';
const NOTES_TAB = 'NOTES_TAB';
const POS_TAB = 'POS_TAB';
const EQUIPMENT_TAB = 'EQUIPMENT_TAB';

const TABS = {
  DETAILS_TAB,
  WORK_PERFORMED_TAB,
  NOTES_TAB,
  POS_TAB,
  EQUIPMENT_TAB
};

const initialState = {
  workOrder: null,
  activeTab: null,
  refresh: false,
  mapProperties: null
};

const ACTIONS = {
  RESET: "RESET",
  REFRESH: "REFRESH",
  CHANGE_TAB: "CHANGE_TAB",
  GET_WORKORDER_SUCCESS: "GET_WORKORDER_SUCCESS",
  UPDATE_MAP_PROPERTIES: "UPDATE_MAP_PROPERTIES"
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_WORKORDER_SUCCESS: {
      const { payload } = data;
      const { workOrder } = payload;

      return {
        ...state,
        activeTab: state.activeTab || DETAILS_TAB,
        workOrder
      };
    };

    case ACTIONS.REFRESH: {
      const newState = {
        ...state,
        refresh: !state.refresh
      };

      return { ...newState };
    };

    case ACTIONS.CHANGE_TAB: {
      const { payload } = data;
      const { tab } = payload;

      return {
        ...state,
        activeTab: tab
      };
    };

    case ACTIONS.UPDATE_MAP_PROPERTIES: {
      const { payload } = data;

      return {
        ...state,
        mapProperties: payload
      };
    };

    default:
      return {
        ...initialState
      };
  }
};

const WorkOrderDetailsContext = createContext(initialState);

const WorkOrderDetailsProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <WorkOrderDetailsContext.Provider value={stateAndDispatch}>
      {children}
    </WorkOrderDetailsContext.Provider>
  );
}

export const useWorkOrderDetails = () => useContext(WorkOrderDetailsContext);

export { WorkOrderDetailsContext, WorkOrderDetailsProvider, ACTIONS, TABS };
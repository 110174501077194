import React, { useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';

import CarouselSlider from '../../../components/CarouselSlider';
import InformationModal from '../../../components/InformationModal';

const IMAGES_TAB = 'IMAGES_TAB';

const PurchaseOrderImages = ({ purchaseOrder }) => {
  const [activeTab] = useState(IMAGES_TAB);
  const hasPhotos = purchaseOrder?.photo_url?.length > 0 ?? false;

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: '',
    body: '',
    onClose: null,
  });

  const openImageModal = (url, selected) => {
    if (typeof (url) === 'string') {
      return setInformationModal({
        isOpen: true,
        title: 'Image Preview',
        rawBody: true,
        body: (
          <div className="d-flex flex-column text-center">
            <img className="img-fluid rounded" src={url} alt={""} />
          </div>
        ),
      });
    };
    return setInformationModal({
      isOpen: true,
      title: 'Images Preview',
      rawBody: true,
      body: (
        <CarouselSlider items={url.map((item, i) => (
          {
            title: `Image ${i + 1}`,
            url: item,
            selected: i === selected
          }
        ))} />
      ),
    });
  };

  return (
    <Col className='flex-grow-1 col-6'>
      <Nav tabs className='work-order-details-nav cursor-pointer'>
        <NavItem>
          <NavLink className='active'>Images</NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={IMAGES_TAB}>
          <Card className='rounded-0 box-shadow-none border-left border-bottom border-right'>
            <CardBody>
              {hasPhotos ? (
                <Row className='h-25 justify-content-start align-items-stretch'>
                  {purchaseOrder.photo_url
                    .map((photo, i) => (
                      <div
                        key={i}
                        className="figure img-thumbnail cursor-pointer mx-1"
                        onClick={() =>
                          openImageModal(
                            purchaseOrder.photo_url,
                            i
                          )
                        }
                        style={{
                          backgroundPosition: 'center',
                          backgroundImage: `url(${photo})`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          cursor: 'pointer',
                          alignSelf: 'center',
                          height: '200px',
                          width: '200px',
                        }}
                      ></div>
                    ))}
                </Row>
              ) : (
                <small className='text-muted'>There are no images for this purchase order</small>
              )}
            </CardBody>
          </Card>
        </TabPane>
      </TabContent>
      {informationModal.isOpen ? (
        <InformationModal
          rawBody={true}
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                isOpen: false,
                title: '',
                body: '',
                onClose: null,
              })
          }
        />
      ) : null}
    </Col>
  );
};

export default PurchaseOrderImages;

import { faChevronLeft, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import Header from '../../../components/Header';
import HeaderTitle from '../../../components/HeaderTitle';
import InformationModal from '../../../components/InformationModal';
import Loader from '../../../components/Loader';
import { purchaseOrdersApi } from '../../../services/purchaseOrdersServices';
import PurchaseOrderDetailsTab from './PurchaseOrderDetailsTab';
import PurchaseOrderImages from './PurchaseOrderImages';
import { StatusBadge } from './PurchaseOrders';

const DETAILS_TAB = 'DETAILS_TAB';

const PurchaseOrderDetails = () => {
  const history = useHistory();
  const { purchaseOrderId } = useParams();
  const [activeTab, setActiveTab] = useState(DETAILS_TAB);
  const [purchaseOrder, setPurchaseOrder] = useState();
  const [loading, setLoading] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: '',
    body: '',
    onClose: null,
  });

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // Load PurchaseOrder
  useEffect(() => {
    const getPurchaseOrder = async () => {
      setLoading(true);
      try {
        const data = await purchaseOrdersApi.getPurchaseOrder(purchaseOrderId);
        setLoading(false);
        setPurchaseOrder(data || {});
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: 'Purchase Order Details',
          body: 'There was an error with your request.',
          onClose: () => history.push('/purchase-orders'),
        });
      }
    };
    getPurchaseOrder();
  }, [purchaseOrderId, history]);

  return (
    <Container className="d-flex flex-column flex-grow-1" fluid>
      <Header className='mb-3 '>
        <HeaderTitle className='d-flex' tag="div">
          <h1 className='flex-grow-1 d-flex flex-column align-items-start'>
            <span className='flex-grow-1 text-dark'>
              Purchase Order Information
            </span>
            <Button
              size='sm'
              className='rounded mt-1 no-print'
              onClick={() => history.goBack()}
            >
              <FontAwesomeIcon icon={faChevronLeft} className='mr-1' />
              <span>Back</span>
            </Button>
          </h1>
          <div className='d-flex flex-column align-items-center'>
            <div>
              <div className='h4 mb-0'>
                {`PO# ${purchaseOrder?.po_number}`}
              </div>
            </div>
            <small>
              <StatusBadge status={purchaseOrder?.status} />
            </small>
          </div>
        </HeaderTitle>
      </Header>
      <Row>
        {!purchaseOrder || loading ? (
          <Loader />
        ) : (
          <>
            <Row className='align-items-stretch w-100'>
              <Col className=' flex-grow-1'>
                <Row>
                  <Col>
                    <Nav tabs className="work-order-details-nav cursor-pointer d-flex flex-nowrap text-center">
                      <div className='d-flex flex-grow-1'>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === DETAILS_TAB,
                            })}
                            onClick={() => toggle(DETAILS_TAB)}>
                            Purchase Order Details
                          </NavLink>
                        </NavItem>
                      </div>
                      <div className='d-flex flex-shrink-0 pl-2'>
                        <NavItem className="flex-grow-1 d-flex align-items-center justify-content-end">
                          <Button size="sm" color="primary rounded font-500 no-print" onClick={() => {window.print()}}>
                            <FontAwesomeIcon icon={faPrint} className="mr-1" />
                            <span>Print</span>
                          </Button>
                        </NavItem>
                      </div>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={DETAILS_TAB}>
                        <Card className='rounded-0 box-shadow-none border-left border-bottom border-right'>
                          <CardBody>
                            {activeTab === DETAILS_TAB && (
                              <PurchaseOrderDetailsTab
                                purchaseOrder={purchaseOrder}
                              />
                            )}
                          </CardBody>
                        </Card>
                      </TabPane>
                    </TabContent>
                  </Col>
                  <PurchaseOrderImages purchaseOrder={purchaseOrder} />
                </Row>
              </Col>
            </Row>
          </>
        )}
      </Row>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                isOpen: false,
                title: '',
                body: '',
                onClose: null,
              })
          }
        />
      ) : null}
    </Container>
  );
};

export default PurchaseOrderDetails;

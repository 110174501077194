import mapboxgl from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import React, { useEffect, useState } from 'react';
import { mapFarms } from '../../../utils/map';

const FarmMap = ({ farm, onCalculate, loading }) => {
  const [rendered, setRendered] = useState(false);

  const [size, setSize] = useState();

  const resizeMap = () => {
    const card = document.getElementById('farmFormContainer');
    if (card) {
      setSize(card.offsetHeight);
    }
  };

  useEffect(() => {
    if (!rendered) {
      mapboxgl.clearStorage();
      mapFarms.initMap(farm, onCalculate);
      setRendered(true);
      resizeMap();
    }
  }, [onCalculate, rendered, farm]);

  useEffect(() => {
    if (!loading) {
      resizeMap();
    };
  }, [loading]);

  return (
    <div id="map" className="rounded d-flex" style={{ height: size + 'px' }} />
  );
};

export default FarmMap;

import React from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';
export const FormField = ({
  label,
  value,
  onChange = () => {},
  editable = false,
  type = 'text',
  name,
}) => (
  <FormGroup row>
    <Label sm={3}>{label}</Label>
    <Col sm={9}>
      <Input
        name={name}
        type={type}
        value={value || '-'}
        readOnly={!editable}
        onChange={onChange}
      />
    </Col>
  </FormGroup>
);

export default FormField;

import axios from 'axios';
import config from '../config/config';

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}`;
  } else if (err?.response?.status === 404) {
    window.location.href = '/error/404';
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = '/error/500';
  }
};

export const usersApi = {
  getUsers: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join('&');
    return axios(
      data.id ? `/${config.apiVersion}/api/users/${data.id}` : `/${config.apiVersion}/api/users?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: 'get',
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createUser: (data) => {
    return axios(`/${config.apiVersion}/api/users`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: 'post',
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  updateUser: (data) => {
    return axios(`/${config.apiVersion}/api/users/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: 'put',
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  updateUserWorkPerformedTableColumnsSetting: (data) => {
    return axios(`/${config.apiVersion}/api/users/${data.id}/work-performed-table-columns-setting`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: 'put',
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  deleteUser: (data) => {
    return axios(`/${config.apiVersion}/api/users/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: 'delete',
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
};

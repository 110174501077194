import React, { useState } from "react";
import SidebarItem from "./SidebarItem";
import { withRouter } from "react-router-dom";
import SidebarCategory from "./SidebarCategory";
import { useAuth } from "../../../providers/authProvider";

const setBadgeText = (authContext, path) => {
    switch (path) {
        case '/work-orders/recently-added':
            return authContext.currentUser.recentlyAddedWOCount;
        default:
            return null;
    }
}

const SidebarMenu = ({ name, icon, to, location, entries }) => {
    const [authContext] = useAuth();
    const [isOpen, setIsOpen] = useState(location.pathname.indexOf(to) === 0);
    return <React.Fragment>
        <SidebarCategory
            name={name}
            icon={icon}
            isOpen={isOpen}
            onClick={() => setIsOpen(!isOpen)}
        >
            {entries.map((entry, index) => (
                <div key={index}>
                    <SidebarItem
                        icon={entry.icon}
                        name={entry.name}
                        to={entry.path}
                        badgeColor={entry.badgeColor}
                        badgeText={setBadgeText(authContext, entry.path)}
                    />
                </div>
            ))}
        </SidebarCategory>
    </React.Fragment>
};

export default withRouter(SidebarMenu);
import { faArchive, faArrowUp, faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { RefreshCw } from 'react-feather';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  CustomInput,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';

import ConfirmationModal from '../../../components/ConfirmationModal';

import InformationModal from '../../../components/InformationModal';
import Loader from '../../../components/Loader';
import {
  ACTIONS,
  MAX_PAGE_SIZE,
  PURCHASE_ORDER_STATUSES,
  PURCHASE_ORDER_STATUS_ARCHIVED,
  PURCHASE_ORDER_STATUS_OPEN,
  usePurchaseOrders,
} from '../../../providers/purchaseOrdersProvider';

import { purchaseOrdersApi } from '../../../services/purchaseOrdersServices';
import { utils } from '../../../utils/utils';

const TABLE_COLUMNS = (onSort) => [
  {
    dataField: 'po_number',
    text: 'Purchase Order #',
    classes: 'text-truncate',
    sort: true,
    formatter: (po_number) => po_number || '-',
    onSort,
  },
  {
    dataField: 'vendor',
    text: 'Vendor',
    headerStyle: { width: '300px' },
    formatter: (vendor) => vendor?.name || '-',
  },
  {
    dataField: 'date',
    text: 'Date',
    classes: 'text-truncate',
    sort: true,
    formatter: (date) => date || '-',
    onSort,
  },
  {
    dataField: 'amount',
    text: 'Amount',
    classes: 'text-truncate',
    headerStyle: { width: '100px' },
    formatter: (amount) => (amount ? utils.formatCurrency(amount) : '-'),
  },
  {
    dataField: 'workOrder',
    text: 'Work Order #',
    classes: 'text-truncate',
    formatter: (workOrder) => workOrder?.work_order_number || '-',
  },
  {
    dataField: 'status',
    text: 'Status',
    classes: 'text-truncate text-center',
    headerStyle: { width: '100px', textAlign: 'center' },
    formatter: (status) => StatusBadge({ status }),
  },
];

export const StatusBadge = ({ status }) => {
  const classes = `badge-pill mr-1 mb-1 badge badge-${PURCHASE_ORDER_STATUSES[status]?.color}`;
  const label = PURCHASE_ORDER_STATUSES[status]?.label;
  return <span className={classes}>{label}</span>;
};

const getSizePerPage = (size) => (size === 'All' ? MAX_PAGE_SIZE : size);

const PurchaseOrders = () => {
  const [purchaseOrdersContext, setPurchaseOrdersContext] = usePurchaseOrders();
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: '',
    body: '',
  };

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: '',
    body: '',
  });

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const onSort = (sortBy, direction) => {
    if (
      purchaseOrdersContext.sortBy === sortBy &&
      purchaseOrdersContext.direction === direction
    ) {
      return;
    }
    setPurchaseOrdersContext({
      action: ACTIONS.SORT,
      payload: { sortBy, direction },
    });
  };

  const details = (purchaseOrderId) =>
    history.push(`/purchase-orders/${purchaseOrderId}/details`);

  useEffect(() => {
    setLoading(true);
    purchaseOrdersApi
      .getPurchaseOrders({
        match: purchaseOrdersContext.match,
        status: purchaseOrdersContext.status,
        page: purchaseOrdersContext.pagination?.page,
        pageSize: getSizePerPage(purchaseOrdersContext.pagination?.pageSize),
        sortBy: purchaseOrdersContext.sortBy,
        direction: purchaseOrdersContext.direction,
      })
      .then((purchaseOrders) => {
        setPurchaseOrdersContext({
          action: ACTIONS.GET_PURCHASE_ORDERS_SUCCESS,
          payload: purchaseOrders,
        });
        setLoading(false);
      });
  }, [
    setPurchaseOrdersContext,
    purchaseOrdersContext.sortBy,
    purchaseOrdersContext.direction,
    purchaseOrdersContext.pagination?.pageSize,
    purchaseOrdersContext.pagination?.page,
    purchaseOrdersContext.match,
    purchaseOrdersContext.status,
    purchaseOrdersContext.refresh,
    location.pathname,
  ]);

  const onArchiveWO = (event, purchaseOrder) => {
    event.stopPropagation();
    setConfirmationModal({
      isOpen: true,
      confirmColor: 'warning',
      onSubmit: async () => {
        setLoading(true);
        await purchaseOrdersApi.updatePurchaseOrder({
          ...purchaseOrder,
          status: PURCHASE_ORDER_STATUS_ARCHIVED,
        });
        setLoading(false);
        setConfirmationModal(initConfirmationModal);
        setPurchaseOrdersContext({
          action: ACTIONS.REFRESH,
        });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: 'Archive Purchase Order',
      body: `<span class="text-center">Do you confirm you want to archive ${purchaseOrder.po_number}?</span>`,
    });
  };

  const onUnarchiveWO = (event, purchaseOrder) => {
    event.stopPropagation();
    setConfirmationModal({
      isOpen: true,
      confirmColor: 'warning',
      onSubmit: async () => {
        setLoading(true);
        await purchaseOrdersApi.updatePurchaseOrder({
          ...purchaseOrder,
          status: PURCHASE_ORDER_STATUS_OPEN,
        });
        setLoading(false);
        setConfirmationModal(initConfirmationModal);
        setPurchaseOrdersContext({
          action: ACTIONS.REFRESH,
        });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: 'Archive Purchase Order',
      body: `<span class="text-center">Do you confirm you want to unarchive ${purchaseOrder.po_number}?</span>`,
    });
  };

  return (
    <Container className="d-flex flex-column flex-grow-1" fluid>
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between mt-2">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className='mb-0 '>Purchase Orders</h3>
            <small className='text-muted ml-2 pt-1'>({purchaseOrdersContext.pagination?.rowCount})</small>
          </div>
          <div className="card-actions d-flex align-items-center justify-content-between">
            <InputGroup size="m">
              <Input
                maxLength="50"
                value={purchaseOrdersContext.match || ''}
                onChange={(evt) =>
                  setPurchaseOrdersContext({
                    action: ACTIONS.SEARCH,
                    payload: { match: evt.target.value },
                  })
                }
                className="border-right-0"
                placeholder="Search for.."
              />
              <InputGroupText className="custom-input input-group-text bg-primary text-white border-left-0 border-primary">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroupText>
            </InputGroup>
            <InputGroup
              size="m"
              className='mx-3'
            >
              <CustomInput
                label="Status"
                type="select"
                className='border-right-0'
                id="filter-po-status"
                required={true}
                name="filter-po-status"
                onChange={(event) =>
                  setPurchaseOrdersContext({
                    action: ACTIONS.STATUS_CHANGE,
                    payload: { status: event.currentTarget.value },
                  })
                }
              >
                <option value={''}>Any Status</option>
                {Object.keys(PURCHASE_ORDER_STATUSES).map((id) => (
                  <option key={id} value={id}>
                    {PURCHASE_ORDER_STATUSES[id].label}
                  </option>
                ))}
              </CustomInput>
              <InputGroupText className="custom-input input-group-text bg-primary text-white border-left-0 border-primary">
                <FontAwesomeIcon icon={faFilter} size="sm" />
              </InputGroupText>
            </InputGroup>
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button-refresh"
              color="primary"
              onClick={() =>
                setPurchaseOrdersContext({
                  action: ACTIONS.REFRESH,
                })
              }
            >
              <RefreshCw />
            </Button>
          </div>
        </CardHeader>
        <CardBody className='d-flex flex-column'>
          {loading ? (
            <Loader />
          ) : purchaseOrdersContext.purchaseOrders?.length ? (
            <BootstrapTable
              headerClasses='text-muted small'
              bootstrap4
              striped
              hover
              remote
              bordered={false}
              keyField='id'
              classes="cursor-pointer border"
              data={purchaseOrdersContext.purchaseOrders}
              columns={[
                ...TABLE_COLUMNS(onSort),
                {
                  dataField: 'id',
                  text: 'Actions',
                  classes: 'text-truncate text-center',
                  headerStyle: { width: '100px', textAlign: 'center' },
                  formatter: (id, purchaseOrder) => (
                    <div
                      className='cursor-pointer min-width-50'
                      onClick={(event) =>
                        purchaseOrder.status ===
                          PURCHASE_ORDER_STATUS_ARCHIVED
                          ? onUnarchiveWO(event, purchaseOrder)
                          : onArchiveWO(event, purchaseOrder)
                      }>
                      <FontAwesomeIcon
                        className='text-primary'
                        icon={
                          purchaseOrder.status ===
                            PURCHASE_ORDER_STATUS_ARCHIVED
                            ? faArrowUp
                            : faArchive
                        }
                      />
                    </div>
                  ),
                },
              ]}
              rowEvents={{
                onClick: (_, purchaseOrder) => details(purchaseOrder.id),
              }}
              defaultSorted={[
                {
                  dataField: purchaseOrdersContext.sortBy,
                  order: purchaseOrdersContext.direction,
                },
              ]}
              onTableChange={() => { }}
              pagination={paginationFactory({
                page: purchaseOrdersContext.pagination?.page,
                totalSize: purchaseOrdersContext.pagination?.rowCount,
                sizePerPage: getSizePerPage(
                  purchaseOrdersContext.pagination?.pageSize
                ),
                sizePerPageList: [
                  5,
                  10,
                  25,
                  50,
                  {
                    text: 'All',
                    value: purchaseOrdersContext.pagination?.rowCount,
                  },
                ],
                onPageChange: (page) =>
                  setPurchaseOrdersContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  }),
                onSizePerPageChange: (pageSize) =>
                  setPurchaseOrdersContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { pageSize },
                  }),
              })}
            />
          ) : (
            <div className='text-center'>No results</div>
          )}
        </CardBody>
      </Card>
      {
        confirmationModal.isOpen ? (
          <ConfirmationModal {...confirmationModal} />
        ) : null
      }
      {
        informationModal.isOpen ? (
          <InformationModal
            title={informationModal.title}
            body={informationModal.body}
            onClose={() =>
              setInformationModal({ isOpen: false, title: '', body: '' })
            }
          />
        ) : null
      }
    </Container >
  );
};

export default PurchaseOrders;

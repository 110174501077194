import { faArchive, faArrowUp, faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { RefreshCw } from 'react-feather';
import { useLocation, useHistory } from 'react-router-dom';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  CustomInput,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import WorkOrdersKPIs from '../../../components/admin/WorkOrdersKPIs';
import ConfirmationModal from '../../../components/ConfirmationModal';

import InformationModal from '../../../components/InformationModal';
import Loader from '../../../components/Loader';

import {
  ACTIONS,
  MAX_PAGE_SIZE,
  useWorkOrders,
  STATUSES,
  WORK_ORDER_STATUS_VOID,
  WORK_ORDER_STATUS_OPEN,
} from '../../../providers/workOrdersProvider';

import { workOrdersApi } from '../../../services/workOrdersServices';

const TABLE_COLUMNS = (onSort) => [
  {
    dataField: 'work_order_number',
    text: 'Work Order #',
    classes: 'text-truncate',
    sort: true,
    formatter: (work_order_number) => work_order_number || '-',
    onSort,
  },
  {
    dataField: 'customer',
    text: 'Customer',
    formatter: (customer) => customer?.name || '',
  },
  {
    dataField: 'farm.name',
    text: 'Farm Name',
    formatter: (farmName) => farmName || '',
  },
  {
    dataField: 'start_date',
    text: 'Start Date',
    classes: 'text-truncate',
    sort: true,
    formatter: (startDate) => startDate,
    onSort,
  },
  {
    dataField: 'assignations',
    text: 'Assigned To',
    formatter: (assignations) =>
      assignations?.length ? assignations.map((a) => a.user.name).join(', ') : '-',
  },
  {
    dataField: 'job',
    text: 'Job #',
    classes: 'text-truncate',
    headerStyle: { width: '100px' },
    formatter: (job) => job?.name || '-',
  },
  {
    dataField: 'status',
    text: 'Status',
    classes: 'text-truncate text-center',
    headerStyle: { width: '100px', textAlign: 'center' },
    formatter: (status) => StatusBadge(status),
  },
];

const StatusBadge = (status) => {
  return (
    <span
      className={`badge-pill mr-1 mb-1 badge badge-${STATUSES[status]?.color}`}
    >
      {STATUSES[status]?.label}
    </span>
  );
};

const getSizePerPage = (size) => (size === 'All' ? MAX_PAGE_SIZE : size);

const WorkOrders = () => {
  const [workOrdersContext, setWorkOrdersContext] = useWorkOrders();
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: '',
    body: '',
  };

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: '',
    body: '',
  });

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const onSort = (sortBy, direction) => {
    if (
      workOrdersContext.sortBy === sortBy &&
      workOrdersContext.direction === direction
    ) {
      return;
    }
    setWorkOrdersContext({
      action: ACTIONS.SORT,
      payload: { sortBy, direction },
    });
  };

  const details = (workOrderId) =>
    history.push(`/work-orders/${workOrderId}/details`);

  useEffect(() => {
    setLoading(true);
    workOrdersApi
      .getWorkOrders({
        match: workOrdersContext.match,
        status: workOrdersContext.status,
        page: workOrdersContext.pagination?.page,
        pageSize: getSizePerPage(workOrdersContext.pagination?.pageSize),
        sortBy: workOrdersContext.sortBy,
        direction: workOrdersContext.direction,
      })
      .then((workOrders) => {
        setWorkOrdersContext({
          action: ACTIONS.GET_WORK_ORDERS_SUCCESS,
          payload: workOrders,
        });
        setLoading(false);
      });
  }, [
    setWorkOrdersContext,
    workOrdersContext.sortBy,
    workOrdersContext.direction,
    workOrdersContext.pagination?.pageSize,
    workOrdersContext.pagination?.page,
    workOrdersContext.match,
    workOrdersContext.status,
    workOrdersContext.refresh,
    location.pathname,
  ]);

  const onArchiveWO = (event, workOrder) => {
    event.stopPropagation();
    setConfirmationModal({
      isOpen: true,
      confirmColor: 'warning',
      onSubmit: async () => {
        setLoading(true);
        await workOrdersApi.updateWorkOrder({
          ...workOrder,
          status: WORK_ORDER_STATUS_VOID,
        });
        setLoading(false);
        setConfirmationModal(initConfirmationModal);
        setWorkOrdersContext({
          action: ACTIONS.REFRESH,
        });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: 'Archive Work Order',
      body: `<span class="text-center">Do you confirm you want to archive ${workOrder.work_order_number}?</span>`,
    });
  };

  const onUnarchiveWO = (event, workOrder) => {
    event.stopPropagation();
    setConfirmationModal({
      isOpen: true,
      confirmColor: 'warning',
      onSubmit: async () => {
        setLoading(true);
        await workOrdersApi.updateWorkOrder({
          ...workOrder,
          status: WORK_ORDER_STATUS_OPEN,
        });
        setLoading(false);
        setConfirmationModal(initConfirmationModal);
        setWorkOrdersContext({
          action: ACTIONS.REFRESH,
        });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: 'Archive Work Order',
      body: `<span class="text-center">Do you confirm you want to unarchive ${workOrder.work_order_number}?</span>`,
    });
  };

  return (
    <Container className="d-flex flex-column flex-grow-1" fluid>
      <WorkOrdersKPIs />
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between mt-2">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className='mb-0 '>Work Orders</h3>
            <small className='text-muted ml-2 pt-1'>({workOrdersContext.pagination?.rowCount})</small>
          </div>
          <div className="card-actions d-flex align-items-center justify-content-between">
            <InputGroup size="m">
              <Input
                maxLength="50"
                value={workOrdersContext.match || ''}
                onChange={(evt) =>
                  setWorkOrdersContext({
                    action: ACTIONS.SEARCH,
                    payload: { match: evt.target.value },
                  })
                }
                className="border-right-0"
                placeholder="Search for.."
              />
              <InputGroupText className="custom-input input-group-text bg-primary text-white border-left-0 border-primary">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroupText>
            </InputGroup>
            <InputGroup
              size="m"
              className='mx-3'
            >
              <CustomInput
                label="Status"
                type="select"
                className='border-right-0'
                id="filter-wo-status"
                required={true}
                name="filter-wo-status"
                onChange={(event) =>
                  setWorkOrdersContext({
                    action: ACTIONS.STATUS_CHANGE,
                    payload: { status: event.currentTarget.value },
                  })
                }
              >
                <option value={''}>Any Status</option>
                {Object.keys(STATUSES).map((id) => (
                  <option key={id} value={id}>
                    {STATUSES[id].label}
                  </option>
                ))}
              </CustomInput>
              <InputGroupText className="custom-input input-group-text bg-primary text-white border-left-0 border-primary">
                <FontAwesomeIcon icon={faFilter} size="sm" />
              </InputGroupText>
            </InputGroup>
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button-refresh"
              color="primary"
              onClick={() =>
                setWorkOrdersContext({
                  action: ACTIONS.REFRESH,
                })
              }
            >
              <RefreshCw />
            </Button>
          </div>
        </CardHeader>
        <CardBody className="d-flex flex-column">
          {loading ? (
            <Loader />
          ) : workOrdersContext.workOrders?.length ? (
            <BootstrapTable
              headerClasses='text-muted small'
              bootstrap4
              striped
              hover
              remote
              bordered={false}
              keyField="id"
              classes="cursor-pointer border"
              data={workOrdersContext.workOrders}
              columns={[
                ...TABLE_COLUMNS(onSort),
                {
                  dataField: 'id',
                  text: 'Actions',
                  classes: 'text-truncate text-center',
                  headerStyle: { width: '100px', textAlign: 'center' },
                  formatter: (id, workOrder) => (
                    <div
                      className="cursor-pointer min-width-50"
                      onClick={(event) =>
                        workOrder.status === WORK_ORDER_STATUS_VOID
                          ? onUnarchiveWO(event, workOrder)
                          : onArchiveWO(event, workOrder)
                      }
                    >
                      <FontAwesomeIcon
                        className="text-primary"
                        icon={
                          workOrder.status === WORK_ORDER_STATUS_VOID
                            ? faArrowUp
                            : faArchive
                        }
                      />
                    </div>
                  ),
                },
              ]}
              rowEvents={{
                onClick: (_, workOrder) => details(workOrder.id),
              }}
              defaultSorted={[
                {
                  dataField: workOrdersContext.sortBy,
                  order: workOrdersContext.direction,
                },
              ]}
              onTableChange={() => { }}
              pagination={paginationFactory({
                page: workOrdersContext.pagination?.page,
                totalSize: workOrdersContext.pagination?.rowCount,
                sizePerPage: getSizePerPage(
                  workOrdersContext.pagination?.pageSize
                ),
                sizePerPageList: [
                  5,
                  10,
                  25,
                  50,
                  {
                    text: 'All',
                    value: workOrdersContext.pagination?.rowCount,
                  },
                ],
                onPageChange: (page) =>
                  setWorkOrdersContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  }),
                onSizePerPageChange: (pageSize) =>
                  setWorkOrdersContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { pageSize },
                  }),
              })}
            />
          ) : (
            <div className="text-center">No results</div>
          )}
        </CardBody>
      </Card>
      {
        confirmationModal.isOpen ? (
          <ConfirmationModal {...confirmationModal} />
        ) : null
      }
      {
        informationModal.isOpen ? (
          <InformationModal
            title={informationModal.title}
            body={informationModal.body}
            onClose={() =>
              setInformationModal({ isOpen: false, title: '', body: '' })
            }
          />
        ) : null
      }
    </Container >
  );
};

export default WorkOrders;

import React from "react";

import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../providers/authProvider";
import SideBarUser from "./SidebarUser";
import SidebarItem from "./SidebarItem";
import brand from "../../../assets/img/logo.png";

import { authApi } from "../../../services/authServices";
import { useHistory } from "react-router-dom";
import SidebarMenu from "./SidebarMenu";
import { adminRoutes } from "../../../routes";
import { useSidebar } from "../../../providers/sidebarProvider";

const Sidebar = () => {
  const history = useHistory();
  const [authContext, setAuthContext] = useAuth();
  const [sidebarContext] = useSidebar();

  const logout = () => {
    authApi.logout('investor');
    setAuthContext({ currentUser: null, advisements: null });
    history.push('/auth/sign-in');
  };

  return (
    <nav className={`sidebar ${!sidebarContext.isOpen ? 'toggled' : ''}`}>
      <div className="sidebar-content py-2">
        <div className="sidebar-brand d-flex align-items-center">
          <img
            src={brand}
            className="rounded p-0 col-12"
            alt={'Sun Harvest'}
          />
        </div>
        <SideBarUser />
        <ul className="sidebar-nav">
          {adminRoutes
            .filter(route => route.sidebar && (!route.roles || route.roles?.indexOf(authContext.currentUser.userRoleId) > -1))
            .map((route, index) => (
              <div key={index}>
                {route.entries ? (
                  <SidebarMenu
                    name={route.name}
                    icon={route.icon}
                    to={route.path}
                    entries={route.entries} />
                ) : (
                  <SidebarItem
                    name={route.name}
                    icon={route.icon}
                    to={route.path}
                  />
                )}
              </div>
            ))
          }
          <SidebarItem
            name="Logout"
            icon={faSignOutAlt}
            action={logout}
          />
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;

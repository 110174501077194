import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import InformationModal from '../../../../components/InformationModal';
import Loader from '../../../../components/Loader';
import { ACTIONS, useWorkOrderDetails } from '../../../../providers/workOrderDetailsProvider';
import { workOrdersApi } from '../../../../services/workOrdersServices';
import AdaptableTextArea from '../../../../UI/AdaptableTextArea';

const NotesTab = () => {
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();
  const [officeNotes, setOfficeNotes] = useState(workOrderDetails.workOrder.office_notes);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: '',
    body: '',
  });

  const [loading, setLoading] = useState();

  const saveNotes = (e) => {
    e.preventDefault();
    if (!officeNotes?.length) {
      return setInformationModal({
        isOpen: true,
        title: 'Save Notes',
        body: 'Notes can\'t be empty'
      });
    };
    setLoading(true);
    workOrdersApi
      .updateWorkOrder({
        ...workOrderDetails.workOrder,
        office_notes: officeNotes,
      })
      .then(() => {
        setWorkOrderDetails({
          action: ACTIONS.REFRESH
        });
        return setInformationModal({
          isOpen: true,
          title: 'Update Notes',
          body: 'Notes updated successfully.'
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Row className='mt-3'>
      <Col>
        <h4 className='border-bottom pb-2'>Notes</h4>
        <Form onSubmit={saveNotes} className='mt-3'>
          <AdaptableTextArea
            label='Office Notes'
            value={officeNotes}
            onChange={(e) => setOfficeNotes(e.target.value)}
            readOnly={loading}
          />
          <div className='d-flex justify-content-end'>
            {loading && <Loader size='sm' />}
            {!loading && (
              <Button
                color='primary'
                className='rounded'
              >
                <FontAwesomeIcon icon={faSave} className='mr-2' />
                <span>Save</span>
              </Button>
            )}
          </div>
        </Form>

        <hr />
        <Form>
          <AdaptableTextArea
            label='App Notes'
            value={
              workOrderDetails.workOrder.app_notes ||
              'There are not app notes for this work order'
            }
            readOnly
          />
        </Form>
      </Col>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: '', body: '' })
          }
        />
      ) : null}
    </Row>
  );
};

export default NotesTab;

import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import InformationModal from '../../../../components/InformationModal';

import Loader from '../../../../components/Loader';

import { ACTIONS, useWorkOrderDetails } from '../../../../providers/workOrderDetailsProvider';

import { workOrdersApi } from '../../../../services/workOrdersServices';

import { utils } from '../../../../utils/utils';

import CloseOutWorkOrderDetailsModal from './CloseOutWorkOrderDetailsModal';

const HOUR = 'Hour';
const ACRE = 'Acre';
const STATUS_CLOSED = 0;

const CloseBtn = ({ onClose }) => (
  <Button className="close" color="none" onClick={onClose}>
    &times;
  </Button>
);

const CloseOutWorkOrderModal = ({
  tractorHours,
  disabled,
  onClose,
}) => {
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();
  const [workOrder, setWorkOrder] = useState({ ...workOrderDetails.workOrder });

  const [loading, setLoading] = useState();

  const [closeWorkOrder, setCloseWorkOrder] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: '',
    body: '',
  });

  const arePositiveValues = () => (
    workOrder.acres >= 0 &&
    workOrder.hr_billing_rate >= 0 &&
    workOrder.acre_billing_rate >= 0
  );

  const handleSubmit = (e) => {
    if (!workOrder.billing_type) {
      return setInformationModal({
        isOpen: true,
        title: 'Close Out Work Order',
        body: 'Select a Billing Type',
      });
    };
    e.preventDefault();
    setCloseWorkOrder(true);
  };

  const updateTotalBilling = () => {
    setLoading(true);
    const updatedWorkOrder = utils.updateWorkOrderValues(workOrder, tractorHours);
    workOrdersApi
      .updateWorkOrder({
        ...updatedWorkOrder,
        status: STATUS_CLOSED
      })
      .then(() =>
        setWorkOrderDetails({
          action: ACTIONS.REFRESH
        })
      )
      .finally(() => {
        setLoading(false);
        onClose();
      });
  }

  return (
    informationModal.isOpen ? (
      <InformationModal
        title={informationModal.title}
        body={informationModal.body}
        rawBody={informationModal.rawBody}
        onClose={() =>
          setInformationModal({ isOpen: false, title: '', body: '' })
        }
      />
    ) :
      closeWorkOrder ? (
        <CloseOutWorkOrderDetailsModal
          workOrder={workOrder}
          onSave={() => {
            setCloseWorkOrder(false);
            updateTotalBilling();
          }}
          onClose={() => setCloseWorkOrder(false)}
          title={'Close Out Work Order'}
        />
      ) : (
        <Modal isOpen={true} size="sm">
          <Form onSubmit={handleSubmit}>
            <ModalHeader close={<CloseBtn onClose={onClose} />}>
              Close Out Day
            </ModalHeader>
            <ModalBody>
              {loading ? (
                <Loader size="sm" />
              ) : (
                <>
                  <FormGroup row>
                    <Label sm={6}>
                      <span>Total Tractor Hours</span>
                      <span className='text-danger ml-1'>*</span>
                    </Label>
                    <Col sm={6}>
                      <Input readOnly value={tractorHours} />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={6}>
                      <span>Total Acres</span>
                      <span className='text-danger ml-1'>*</span>
                    </Label>
                    <Col sm={6}>
                      <Input
                        required={true}
                        value={workOrder.acres || ""}
                        onChange={(e) => setWorkOrder({
                          ...workOrder,
                          acres: e.target.value
                        })}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={6}>
                      <span>Hour Billing Rate</span>
                      <span className='text-danger ml-1'>*</span>
                    </Label>
                    <Col sm={6}>
                      <Input
                        required={true}
                        value={workOrder.hr_billing_rate || ""}
                        onChange={(e) => setWorkOrder({
                          ...workOrder,
                          hr_billing_rate: e.target.value
                        })}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={6}>
                      <span>Acre Billing Rate</span>
                      <span className='text-danger ml-1'>*</span>
                    </Label>
                    <Col sm={6}>
                      <Input
                        required={true}
                        value={workOrder.acre_billing_rate || ""}
                        onChange={(e) => setWorkOrder({
                          ...workOrder,
                          acre_billing_rate: e.target.value
                        })}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row className='mb-0'>
                    <Label sm={6}>
                      <span>Billing Type</span>
                      <span className='text-danger ml-1'>*</span>
                    </Label>
                    <Col sm={6}>
                      <div className="d-flex flex-grow-1 justify-content-start">
                        <div className="col-6 pl-0">
                          <Button
                            onClick={() => setWorkOrder({
                              ...workOrder,
                              billing_type: HOUR
                            })}
                            color={workOrder.billing_type === HOUR ? 'primary' : 'white'}
                            className={`col-12 outline-none ${workOrder.billing_type === HOUR ? 'border-white bg-primary text-white' : 'border-primary bg-white text-primary'}`}
                          >
                            Hour
                          </Button>
                        </div>
                        <div className="col-6 pr-0">
                          <Button
                            onClick={() => setWorkOrder({
                              ...workOrder,
                              billing_type: ACRE
                            })}
                            color={workOrder.billing_type === ACRE ? 'primary' : 'white'}
                            className={`col-12 outline-none ${workOrder.billing_type === ACRE ? 'border-white bg-primary text-white' : 'border-primary bg-white text-primary'}`}
                          >
                            Acre
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </FormGroup>
                </>
              )}
            </ModalBody>
            <ModalFooter className="d-flex flex-grow-1 justify-content-between">
              <Button onClick={onClose}>Cancel</Button>
              <Button
                disabled={disabled || loading || !arePositiveValues()}
                color='primary'
                className='rounded'
              >
                <FontAwesomeIcon icon={faSave} className='mr-2' />
                <span>Save</span>
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )
  );
};

export default CloseOutWorkOrderModal;

import React from "react";
import { Redirect } from "react-router-dom";

import { useAuth } from "../../../providers/authProvider";
import { LoginForm } from "./LoginForm";

const redirectTo = (location) => {
  if (location.search) {
    const params = new URLSearchParams(location.search);
    return <Redirect to={params.get('returnURL')} />;
  }
  return <Redirect to={`/work-orders`}/>
}

const SignIn = ({ location }) => {
  const [authContext] = useAuth();
  
  if (!authContext.currentUser) {
    return <LoginForm/>;
  };

  return redirectTo(location, authContext.currentUser.rol);
};

export default SignIn;

import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import Loader from '../../../components/Loader';
import { equipmentsApi } from '../../../services/equipmentsServices';
import { purchaseOrdersApi } from '../../../services/purchaseOrdersServices';
import { vehiclesApi } from '../../../services/vehiclesServices';
import { workOrdersApi } from '../../../services/workOrdersServices';
import AdaptableTextArea from '../../../UI/AdaptableTextArea';
import FormField from '../../../UI/FormField';
import SearchabledropDown from '../../../UI/SearchableDropdown';

const getEnumChargeTo = (purchaseOrder) => {
  switch (purchaseOrder.charge_to) {
    case 0:
      return 'House account';
    case 1:
      return 'Credit card';
    case 2:
      return 'Cash';
    case 3:
      return 'Vendors Credit';
    default:
      return `-`;
  }
};

const availableClients = [
  'Sun Harvest',
  'Martinin Company',
  'Cooper & Frank',
  'Other',
].map((customer) => ({ value: customer, label: customer }));

const PurchaseOrderDetailsTab = ({ purchaseOrder }) => {
  const selectedWorkOrder = purchaseOrder.workOrder;
  const selectedEquipment = purchaseOrder.equipment ?? purchaseOrder.vehicle;
  const [purchaseOrderValues, setPurchaseOrderValues] = useState(purchaseOrder);
  const [selectedWorkOrderOption, setSelectedWorkOrderOption] = useState(
    selectedWorkOrder
      ? {
        value: selectedWorkOrder.id,
        label: selectedWorkOrder.work_order_number,
      }
      : undefined
  );
  const [availabeWorkOrders, setAvailabeWorkOrders] = useState();

  const [selectedEquipmentOption, setSelectedEquipmentOption] = useState(
    selectedEquipment
      ? {
        value: selectedEquipment.id,
        label: selectedEquipment.equipment,
      }
      : undefined
  );
  const [availableEquipment, setavailableEquipment] = useState();
  const [loading, setLoading] = useState(false);

  const [selectedCustomerOption, setSelectedCustomerOption] = useState({
    value: purchaseOrder.bill_to,
    label: purchaseOrder.bill_to,
  });

  useEffect(() => {
    const getEquipment = async () => {
      const equipmentPromise = equipmentsApi.getEquipments({});
      const vehiclesPromise = vehiclesApi.getVehicles({});

      const promises = [equipmentPromise, vehiclesPromise];
      const [equipmentPromiseResult, vehiclesPromiseResult] = await Promise.all(promises);

      const availableEquipmentResult = [];

      equipmentPromiseResult.equipments.forEach((eq) => {
        availableEquipmentResult.push({ ...eq, isVehicle: false });
      });

      vehiclesPromiseResult.vehicles.forEach((eq) => {
        availableEquipmentResult.push({ ...eq, isVehicle: true });
      });

      setavailableEquipment(availableEquipmentResult);
    };
    getEquipment();
  }, []);

  const getWorkOrderOptions = async (value) => {
    const apiResponse = await workOrdersApi.getWorkOrders({
      work_order_number: value, //needs fix in backend
      page: 1,
    });
    setAvailabeWorkOrders(apiResponse.workOrders);
  };

  const updateWorkOrder = (option) => {
    if (option && option.value.length > 0) {
      const selectedWorkOrder = availabeWorkOrders.find(
        (wo) => wo.id === option.value
      );
      setSelectedWorkOrderOption({ ...option });
      setPurchaseOrderValues((prevState) => {
        const stateCopy = { ...prevState };
        stateCopy.work_order_id = option?.value;
        stateCopy.workOrder = { ...selectedWorkOrder };
        stateCopy.workOrder.farm = { ...selectedWorkOrder.farm };
        stateCopy.workOrder.customer = { ...selectedWorkOrder.customer };

        return stateCopy;
      });
    } else {
      setSelectedWorkOrderOption();
      setPurchaseOrderValues((prevState) => {
        const stateCopy = { ...prevState };
        stateCopy.work_order_id = undefined;
        // stateCopy.workOrder.farm = undefined;
        // stateCopy.workOrder.customer = undefined;
        stateCopy.workOrder = undefined;

        return stateCopy;
      });
    }
  };

  const updateEqupment = (option) => {
    if (option) {
      const selectedEquipment = availableEquipment.find(
        (eq) => eq.id === option.value
      );
      setSelectedEquipmentOption({ ...option });
      setPurchaseOrderValues((prevState) => {
        const stateCopy = { ...prevState };
        if (selectedEquipment.isVehicle) {
          stateCopy.vehicle_id = option.value;
          stateCopy.equipment_id = undefined;
        } else {
          stateCopy.vehicle_id = undefined;
          stateCopy.equipment_id = option.value;
        }

        return stateCopy;
      });
    } else {
      setSelectedEquipmentOption();
      setPurchaseOrderValues((prevState) => {
        const stateCopy = { ...prevState };
        stateCopy.equipment_id = undefined;
        stateCopy.vehicle_id = undefined;

        return stateCopy;
      });
    }
  };

  const updateBillTo = (option) => {
    if (option) {
      setSelectedCustomerOption({ ...option });
      setPurchaseOrderValues((prevState) => {
        const stateCopy = { ...prevState };
        stateCopy.bill_to = option.value;

        return stateCopy;
      });
    } else {
      setPurchaseOrderValues((prevState) => {
        const stateCopy = { ...prevState };
        stateCopy.bill_to = undefined;

        return stateCopy;
      });
      setSelectedCustomerOption(undefined);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await purchaseOrdersApi.updatePurchaseOrder({
        ...purchaseOrderValues,
      });
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const onValueChange = (e) => {
    e.persist();
    setPurchaseOrderValues((prevState) => {
      const stateCopy = { ...prevState };
      stateCopy[e.target.name] = e.target.value;
      return stateCopy;
    });
  };

  const availabeWorkOrderOptions =
    availabeWorkOrders && availabeWorkOrders.length > 0
      ? availabeWorkOrders.map((wo) => ({
        value: wo.id,
        label: wo.work_order_number,
      }))
      : [{ value: 0, label: 'Type at least 3 numbers to load workorders' }];

  const availableEquipmentOptions =
    availableEquipment && availableEquipment.length > 0
      ? availableEquipment
        .map((eq) => ({
          value: eq.id,
          label: eq.equipment,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
      : [{ value: 0, label: 'There are no equipment pieces' }];

  return (
    <Row className='mt-3'>
      <Col>
        {loading ? (
          <Loader size='sm' />
        ) : (
          <Form onSubmit={onSubmit}>
            <FormField label='Date' value={purchaseOrderValues.date} />
            <FormField
              label='Created by'
              value={purchaseOrderValues.createdBy?.name}
            />
            <FormField
              label='Vendor'
              value={purchaseOrderValues.vendor?.name}
            />
            <FormField
              label='Charge to'
              value={getEnumChargeTo(purchaseOrderValues)}
            />

            <SearchabledropDown
              label='Bill to'
              noOpitionFoundText='Text not found'
              onSelect={updateBillTo}
              options={availableClients}
              placeholder='Select company'
              selected={selectedCustomerOption}
            />

            <FormField
              name='amount'
              label='Total'
              value={purchaseOrderValues.amount}
              onChange={onValueChange}
              editable
            />

            <SearchabledropDown
              label='Equipment'
              noOpitionFoundText='No equipment pieces were found'
              onSelect={updateEqupment}
              options={availableEquipmentOptions}
              placeholder='Type equipment number'
              selected={selectedEquipmentOption}
              name='equipment'
            />

            <SearchabledropDown
              label='Work oder #'
              noOpitionFoundText='No work orders were found'
              onSelect={updateWorkOrder}
              options={availabeWorkOrderOptions}
              placeholder='Type a work order number'
              selected={selectedWorkOrderOption}
              onInputChange={getWorkOrderOptions}
              name='work_order_id'
            />

            <FormField
              label='Customer'
              value={purchaseOrderValues.workOrder?.customer?.name ?? '-'}
            />

            <FormField
              label='Customer farm'
              value={purchaseOrderValues.workOrder?.farm?.name ?? '-'}
            />

            <AdaptableTextArea
              name='description'
              label='Notes'
              onChange={onValueChange}
              value={purchaseOrderValues.description}
            />

            <hr />
            <div className='d-flex flex-row justify-content-end no-print'>
              <Button
                color='primary'
                className='rounded'
              >
                <FontAwesomeIcon icon={faSave} className='mr-2' />
                <span>Save</span>
              </Button>
            </div>
          </Form>
        )}
      </Col>
    </Row>
  );
};

export default PurchaseOrderDetailsTab;

import { faPlus, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { RefreshCw } from 'react-feather';
import { useLocation } from 'react-router-dom';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import ConfirmationModal from '../../../components/ConfirmationModal';

import InformationModal from '../../../components/InformationModal';
import Loader from '../../../components/Loader';

import {
  useEquipments,
  ACTIONS,
  MAX_PAGE_SIZE,
} from '../../../providers/equipmentsProvider';

import { equipmentsApi } from '../../../services/equipmentsServices';
import EquipmentModal from './EquipmentModal';

const TABLE_COLUMNS = (onSort) => [
  {
    dataField: 'prefix',
    text: 'Prefix',
    classes: 'text-truncate',
    sort: true,
    formatter: (prefix) => prefix || '-',
    onSort,
  },
  {
    dataField: 'equipment',
    text: 'Equipment',
    classes: 'text-truncate',
    sort: true,
    formatter: (equipment) => equipment || '-',
    onSort,
  },
  {
    dataField: 'year',
    text: 'Year',
    classes: 'text-truncate',
    sort: true,
    formatter: (year) => year || '-',
    onSort,
  },
  {
    dataField: 'make',
    text: 'Make',
    classes: 'text-truncate',
    sort: true,
    formatter: (make) => make || '-',
    onSort,
  },
  {
    dataField: 'model',
    text: 'Model',
    classes: 'text-truncate',
    sort: true,
    formatter: (model) => model || '-',
    onSort,
  },
  {
    dataField: 'license',
    text: 'License',
    classes: 'text-truncate',
    sort: true,
    formatter: (license) => license || '-',
    onSort,
  },
];

const getSizePerPage = (size) => (size === 'All' ? MAX_PAGE_SIZE : size);

const Equipments = () => {
  const [equipmentsContext, setEquipmentsContext] = useEquipments();

  const [createEquipmentModal, setCreateEquipmentModal] = useState();
  const [editEquipmentModal, setEditEquipmentModal] = useState({
    isOpen: false,
    id: null,
  });

  const [loading, setLoading] = useState(true);

  const location = useLocation();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: '',
    body: '',
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: '',
    body: '',
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const onSort = (sortBy, direction) => {
    if (
      equipmentsContext.sortBy === sortBy &&
      equipmentsContext.direction === direction
    ) {
      return;
    }
    setEquipmentsContext({
      action: ACTIONS.SORT,
      payload: { sortBy, direction },
    });
  };

  const onCreate = () => setCreateEquipmentModal(true);

  const onDetails = (id) => setEditEquipmentModal({ isOpen: true, id });

  const onDelete = (event, equipment) => {
    event.stopPropagation();
    setConfirmationModal({
      isOpen: true,
      confirmColor: 'danger',
      onSubmit: async () => {
        setLoading(true);
        await equipmentsApi.deleteEquipment({ id: equipment.id });
        setLoading(false);
        setConfirmationModal(initConfirmationModal);
        setEquipmentsContext({
          action: ACTIONS.REFRESH,
        });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: 'Delete Equipment',
      body: `<span class="text-center">Do you confirm you want to delete Equipment ${equipment.equipment}?</span>`,
    });
  };

  useEffect(() => {
    setLoading(true);
    equipmentsApi
      .getEquipments({
        match: equipmentsContext.match,
        page: equipmentsContext.pagination?.page,
        pageSize: getSizePerPage(equipmentsContext.pagination?.pageSize),
        sortBy: equipmentsContext.sortBy,
        direction: equipmentsContext.direction,
      })
      .then((equipments) => {
        setEquipmentsContext({
          action: ACTIONS.GET_EQUIPMENTS_SUCCESS,
          payload: equipments,
        });
        setLoading(false);
      });
  }, [
    setEquipmentsContext,
    equipmentsContext.sortBy,
    equipmentsContext.direction,
    equipmentsContext.pagination?.pageSize,
    equipmentsContext.pagination?.page,
    equipmentsContext.match,
    equipmentsContext.status,
    equipmentsContext.refresh,
    location.pathname,
  ]);

  return (
    <Container className="d-flex flex-column flex-grow-1" fluid>
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between mt-2">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className='mb-0 '>Equipments</h3>
            <small className='text-muted ml-2 pt-1'>({equipmentsContext.pagination?.rowCount})</small>
          </div>
          <div className="card-actions d-flex align-items-center justify-content-between">
            <InputGroup size="m">
              <Input
                maxLength="50"
                value={equipmentsContext.match || ''}
                onChange={(evt) =>
                  setEquipmentsContext({
                    action: ACTIONS.SEARCH,
                    payload: { match: evt.target.value },
                  })
                }
                className="border-right-0"
                placeholder="Search for.."
              />
              <InputGroupText className="custom-input input-group-text bg-primary text-white border-left-0 border-primary">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroupText>
            </InputGroup>
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button-plus mx-3"
              color="primary"
              onClick={onCreate}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button-refresh"
              color="primary"
              onClick={() =>
                setEquipmentsContext({
                  action: ACTIONS.REFRESH,
                })
              }
            >
              <RefreshCw />
            </Button>
          </div>
        </CardHeader>
        <CardBody className="d-flex flex-column">
          {loading ? (
            <Loader />
          ) : equipmentsContext.equipments?.length ? (
            <BootstrapTable
              headerClasses='text-muted small'
              bootstrap4
              striped
              hover
              remote
              bordered={false}
              keyField="id"
              classes="cursor-pointer border"
              data={equipmentsContext.equipments}
              columns={[
                ...TABLE_COLUMNS(onSort),
                {
                  dataField: 'id',
                  text: 'Actions',
                  classes: 'text-truncate text-center',
                  headerStyle: { width: '100px', textAlign: 'center' },
                  formatter: (id, equipment) => (
                    <div
                      className="cursor-pointer min-width-50"
                      onClick={(event) => onDelete(event, equipment)}
                    >
                      <Button size="sm" className="rounded d-flex align-items-center ml-2" color="danger">
                        <FontAwesomeIcon icon={faTrash} className="mr-1" />
                        <span>Delete</span>
                      </Button>
                    </div>
                  ),
                },
              ]}
              rowEvents={{
                onClick: (_, equipment) => onDetails(equipment.id),
              }}
              defaultSorted={[
                {
                  dataField: equipmentsContext.sortBy,
                  order: equipmentsContext.direction,
                },
              ]}
              onTableChange={() => { }}
              pagination={paginationFactory({
                page: equipmentsContext.pagination?.page,
                totalSize: equipmentsContext.pagination?.rowCount,
                sizePerPage: getSizePerPage(
                  equipmentsContext.pagination?.pageSize
                ),
                sizePerPageList: [
                  5,
                  10,
                  25,
                  50,
                  {
                    text: 'All',
                    value: equipmentsContext.pagination?.rowCount,
                  },
                ],
                onPageChange: (page) =>
                  setEquipmentsContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  }),
                onSizePerPageChange: (pageSize) =>
                  setEquipmentsContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { pageSize },
                  }),
              })}
            />
          ) : (
            <div className="text-center">No results</div>
          )}
        </CardBody>
      </Card>
      {
        informationModal.isOpen ? (
          <InformationModal
            title={informationModal.title}
            body={informationModal.body}
            onClose={() =>
              setInformationModal({ isOpen: false, title: '', body: '' })
            }
          />
        ) : null
      }
      {
        createEquipmentModal ? (
          <EquipmentModal
            onSubmit={() => {
              setCreateEquipmentModal(false);
              setEquipmentsContext({
                action: ACTIONS.REFRESH,
              });
            }}
            onClose={() => setCreateEquipmentModal(false)}
          />
        ) : null
      }
      {
        editEquipmentModal.isOpen ? (
          <EquipmentModal
            equipmentId={editEquipmentModal.id}
            onSubmit={() => {
              setEditEquipmentModal({ isOpen: false, id: null });
              setEquipmentsContext({
                action: ACTIONS.REFRESH,
              });
            }}
            onClose={() => setEditEquipmentModal({ isOpen: false, id: null })}
          />
        ) : null
      }
      {
        confirmationModal.isOpen ? (
          <ConfirmationModal {...confirmationModal} />
        ) : null
      }
    </Container >
  );
};

export default Equipments;

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { equipmentsApi } from '../../../../../services/equipmentsServices';
import makeAnimated from 'react-select/animated';

const style = {
    control: (base) => ({
        ...base,
        border: 0,
        boxShadow: 'none',
    }),
};

const animatedComponents = makeAnimated();

const TractorSelect = ({ onSelect, selected }) => {
    const [match, setMatch] = useState();
    const [loading, setLoading] = useState();
    const [options, setOptions] = useState([]);

    const getOptions = () =>
        options
            .map((item) => ({
                label: item.name || item.label,
                value: item.id || item.value,
            }))
            .filter((item) => item.value !== selected?.value);

    // Load tractor select
    useEffect(() => {
        setLoading(true);

        equipmentsApi
            .getEquipments({
                match,
                pageSize: 999,
            })
            .then((result) => {
                const parsed = result?.equipments.map((item) => ({
                    label: item.equipment,
                    value: item.id,
                }));
                setOptions(parsed || []);
            });

        setLoading(false);
    }, [match, selected]);

    return (
        <FormGroup className="mt-4 d-flex">
            <Label className="mr-3 pt-2" style={{ width: '130px' }}>
                Tractor<span className="red-asterisk">*</span>
            </Label>
            <Select
                required={true}
                placeholder={<span className="text-muted">Type a tractor</span>}
                noOptionsMessage={() => 'No items found'}
                styles={style}
                className="flex-grow-1 border rounded"
                options={getOptions()}
                closeMenuOnSelect={true}
                components={animatedComponents}
                value={selected}
                isSearchable
                isClearable
                inputValue={match}
                onInputChange={setMatch}
                onChange={onSelect}
                isLoading={loading}
            />
        </FormGroup>
    );
};

export default TractorSelect;
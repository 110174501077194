import React, { useEffect, useState } from 'react';
import { faFilter, faPen, faPlus, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { RefreshCw } from 'react-feather';

import {
  Button, Card,
  CardBody,
  CardHeader,
  Container,
  CustomInput,
  Input, InputGroup, InputGroupText,
} from 'reactstrap';

import ConfirmationModal from '../../../components/ConfirmationModal';

import Loader from '../../../components/Loader';

import {
  ACTIONS,
  MAX_PAGE_SIZE,
  useUsers,
  ROLES
} from '../../../providers/usersProvider';

import { usersApi } from '../../../services/usersServices';
import UserModal from './UserModal';

const RoleBadge = role => {
  return (
    <span
      className={`badge-pill mr-1 mb-1 badge badge-${ROLES[role]?.color}`}
    >
      {ROLES[role]?.label}
    </span>
  );
};

const TABLE_COLUMNS = (onSort) => [
  {
    dataField: 'name',
    text: 'Name',
    classes: 'text-truncate',
    sort: true,
    formatter: cell => cell || "-",
    onSort,
  },
  {
    dataField: 'email',
    text: 'Email',
    classes: 'text-truncate',
    formatter: value => value || "-",
    sort: true,
    onSort,
  },
  {
    dataField: 'phone',
    text: 'Phone',
    classes: 'text-truncate',
    sort: true,
    onSort,
    formatter: value => value || "-"
  },
  {
    dataField: 'role',
    text: 'Role',
    classes: 'text-truncate',
    sort: true,
    onSort,
    formatter: role => RoleBadge(role)
  },
  {
    dataField: 'po_prefix',
    text: 'PO Prefix',
    classes: 'text-truncate',
    formatter: value => value || "-"
  },
];

const Users = () => {
  const [usersContext, setUsersContext] = useUsers();

  const [loading, setLoading] = useState(true);

  const [userToEdit, setUserToEdit] = useState();

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: '',
    body: '',
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [createUserModal, setCreateUserModal] =
    useState(false);

  const [editUserModal, setEditUserModal] = useState(false);

  const getSizePerPage = (size) => (size === 'All' ? MAX_PAGE_SIZE : size);

  const actions = (user) => (
    <div className="d-flex justify-content-end align-items-center">
      <Button size="sm" className="rounded d-flex align-items-center" color="warning" onClick={() => onEdit(user)}>
        <FontAwesomeIcon icon={faPen} className="mr-1" />
        <span>Edit</span>
      </Button>{' '}
      <Button size="sm" className="rounded d-flex align-items-center ml-2" color="danger" onClick={() => onDelete(user)}>
        <FontAwesomeIcon icon={faTrash} className="mr-1" />
        <span>Delete</span>
      </Button>
    </div>
  );

  const onSort = (sortBy, direction) => {
    if (
      usersContext.sortBy === sortBy &&
      usersContext.direction === direction
    ) {
      return;
    }
    setUsersContext({
      action: ACTIONS.SORT,
      payload: { sortBy, direction },
    });
  };

  const onEdit = (user) => {
    setUserToEdit(user.id);
    setEditUserModal(true);
  };

  const onDelete = (user) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        usersApi.deleteUser(user)
          .finally(() =>
            setUsersContext({
              action: ACTIONS.REFRESH,
            })
          );
        setConfirmationModal(initConfirmationModal);
        setUsersContext({
          action: ACTIONS.REFRESH,
        });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
        setUsersContext({
          action: ACTIONS.REFRESH,
        });
      },
      title: 'Delete User',
      body: `<span class="text-center">Do you confirm you want to delete ${user.name}?</span>`,
      confirmColor: "danger",
    });
  };

  useEffect(() => {
    setLoading(true);
    usersApi
      .getUsers({
        match: usersContext.match,
        role: usersContext.role,
        page: usersContext.pagination?.page,
        pageSize: getSizePerPage(usersContext.pagination?.pageSize),
        sortBy: usersContext.sortBy,
        direction: usersContext.direction,
      })
      .then((users) => {
        setUsersContext({
          action: ACTIONS.GET_USERS_SUCCESS,
          payload: users,
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    setUsersContext,
    usersContext.sortBy,
    usersContext.direction,
    usersContext.pagination?.pageSize,
    usersContext.pagination?.page,
    usersContext.match,
    usersContext.role,
    usersContext.refresh,
  ]);

  return (
    <Container className="d-flex flex-column flex-grow-1" fluid>
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between mt-2">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className='mb-0 '>Users</h3>
            <small className='text-muted ml-2 pt-1'>({usersContext.pagination?.rowCount})</small>
          </div>
          <div className="card-actions d-flex align-items-center justify-content-between">
            <InputGroup size="m">
              <Input
                maxLength="50"
                value={usersContext.match || ''}
                onChange={(evt) =>
                  setUsersContext({
                    action: ACTIONS.SEARCH,
                    payload: { match: evt.target.value },
                  })
                }
                className="border-right-0"
                placeholder="Search for.."
              />
              <InputGroupText className="custom-input input-group-text bg-primary text-white border-left-0 border-primary">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroupText>
            </InputGroup>
            <InputGroup
              size="m"
              className='ml-3'
            >
              <CustomInput
                label="Role"
                id="filter-role"
                type="select"
                required={true}
                name="filter-role"
                className='border-right-0'
                onChange={event => setUsersContext({
                  action: ACTIONS.ROLE_CHANGE,
                  payload: { role: event.currentTarget.value }
                })}
              >
                <option value={""}>Any Role</option>
                {Object.keys(ROLES).map(id =>
                  <option key={id} value={id}>{ROLES[id].label}</option>
                )}
              </CustomInput>
              <InputGroupText className="custom-input input-group-text bg-primary text-white border-left-0 border-primary">
                <FontAwesomeIcon icon={faFilter} size="sm" />
              </InputGroupText>
            </InputGroup>
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button-plus mx-3"
              color="primary"
              onClick={() => setCreateUserModal(true)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button-refresh"
              color="primary"
              onClick={() =>
                setUsersContext({
                  action: ACTIONS.REFRESH,
                })
              }
            >
              <RefreshCw />
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Loader />
          ) : usersContext.users?.length ? (
            <BootstrapTable
              headerClasses='text-muted small'
              bootstrap4
              remote
              bordered={false}
              classes="cursor-pointer border"
              keyField="id"
              data={usersContext.users}
              columns={[
                ...TABLE_COLUMNS(onSort),
                {
                  dataField: '',
                  text: '',
                  sort: false,
                  classes: 'text-right',
                  formatter: (cell, user) => actions(user),
                },
              ]}
              defaultSorted={[
                {
                  dataField: usersContext.sortBy,
                  order: usersContext.direction,
                },
              ]}
              onTableChange={() => { }}
              pagination={paginationFactory({
                page: usersContext.pagination?.page,
                totalSize: usersContext.pagination?.rowCount,
                sizePerPage: getSizePerPage(usersContext.pagination?.pageSize),
                sizePerPageList: [
                  5,
                  10,
                  25,
                  50,
                  { text: 'All', value: usersContext.pagination?.rowCount },
                ],
                onPageChange: (page) =>
                  setUsersContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  }),
                onSizePerPageChange: (sizePerPage) =>
                  setUsersContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  }),
              })}
            />
          ) : (
            <div className="text-center">No results</div>
          )}
        </CardBody>
      </Card>
      {createUserModal && (
        <UserModal
          onSubmit={() => {
            setCreateUserModal(false);
            setUsersContext({
              action: ACTIONS.REFRESH,
            });
          }}
          onClose={() => {
            setCreateUserModal(false);
          }}
        />
      )}
      {
        editUserModal && (
          <UserModal
            userId={userToEdit}
            onSubmit={() => {
              setUserToEdit(null);
              setEditUserModal(false);
              setUsersContext({
                action: ACTIONS.REFRESH,
              });
            }}
            onClose={() => {
              setUserToEdit(null);
              setEditUserModal(false);
            }}
          />
        )
      }
      {
        confirmationModal.isOpen ? (
          <ConfirmationModal {...confirmationModal} />
        ) : null
      }
    </Container >
  );
};

export default Users;

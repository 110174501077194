import React, { useEffect, useState } from 'react';

import BootstrapTable from 'react-bootstrap-table-next';

import { RefreshCw } from 'react-feather';
import { useLocation } from 'react-router-dom';

import "react-datetime/css/react-datetime.css";
import Datetime from 'react-datetime';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  InputGroup,
  InputGroupText,
} from 'reactstrap';

import InformationModal from '../../../components/InformationModal';
import Loader from '../../../components/Loader';

import {
  useSms,
  ACTIONS,
} from '../../../providers/smsProvider';

import { smsApi } from '../../../services/smsServices';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import paginationFactory from 'react-bootstrap-table2-paginator';

const MAX_PAGE_SIZE = 999;

const TABLE_COLUMNS = () => [
  {
    dataField: 'sid',
    text: '#',
    classes: 'text-truncate',
    formatter: (sid) => sid || "-"
  },
  {
    dataField: 'to',
    text: 'To',
    classes: 'text-truncate',
    formatter: (to) => to || "-",
  },
  {
    dataField: 'body',
    text: 'Message',
    classes: 'text-wrap',
    formatter: (message) => message || "-",
  },
  {
    dataField: 'status',
    text: 'Status',
    classes: 'text-truncate',
    formatter: (status) => status || "-",
  }
];

const Sms = () => {
  const [smsContext, setSmsContext] = useSms();

  const [loading, setLoading] = useState(true);

  const location = useLocation();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: '',
    body: '',
  });

  useEffect(() => {
    setLoading(true);
    smsApi
      .getSms({
        page: smsContext.pagination?.page,
        pageSize: getSizePerPage(smsContext.pagination?.pageSize),
        sent: smsContext.date
      })
      .then(sms => {
        setSmsContext({
          action: ACTIONS.GET_SMS_SUCCESS,
          payload: { sms },
        });
        setLoading(false);
      });
  }, [
    setSmsContext,
    smsContext.date,
    smsContext.pagination?.pageSize,
    smsContext.pagination?.page,
    smsContext.refresh,
    location.pathname,
  ]);

  const getSizePerPage = (size) => (size === 'All' ? MAX_PAGE_SIZE : size);

  return (
    <Container className="d-flex flex-column flex-grow-1" fluid>
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between mt-2">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className='mb-0 '>Text Messages</h3>
            <small className='text-muted ml-2 pt-1'>({smsContext.pagination?.rowCount || 0})</small>
          </div>
          <div className="card-actions d-flex align-items-center justify-content-between">
            <InputGroup size="m">
              <Datetime
                renderInput={props =>
                  <input
                    {...props}
                    required={true}
                    placeholder="Date"
                  />
                }
                closeOnSelect={true}
                locale="us"
                utc={true}
                dateFormat={"YYYY-MM-DD"}
                timeFormat={false}
                className="flex-grow-1 ml-3"
                value={smsContext.date}
                onChange={date => setSmsContext({
                  action: ACTIONS.DATE_CHANGE,
                  payload: { date: moment(date).format("YYYY-MM-DD") }
                })}
              />
              <InputGroupText className="custom-input input-group-text bg-primary text-white border-left-0 border-primary">
                <FontAwesomeIcon icon={faCalendar} />
              </InputGroupText>
            </InputGroup>
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button-refresh ml-3"
              color="primary"
              onClick={() =>
                setSmsContext({
                  action: ACTIONS.REFRESH,
                })
              }
            >
              <RefreshCw />
            </Button>
          </div>
        </CardHeader>
        <CardBody className="d-flex flex-column text-messages-table">
          {loading ? (
            <Loader />
          ) : smsContext.messages?.length ? (
            <BootstrapTable
              headerClasses='text-muted small'
              bootstrap4
              striped
              remote
              bordered={false}
              keyField="sid"
              classes="cursor-pointer border"
              data={smsContext.messages}
              columns={[
                ...TABLE_COLUMNS()
              ]}
              onTableChange={() => { }}
              pagination={paginationFactory({
                page: 1,
                totalSize: smsContext.pagination?.rowCount,
                sizePerPage: getSizePerPage(smsContext.pagination?.pageSize),
                sizePerPageList: [
                  5,
                  10,
                  25,
                  50,
                  {
                    text: 'All',
                    value: smsContext.pagination?.rowCount,
                  },
                ],
                onPageChange: () => { },
                onSizePerPageChange: (pageSize) =>
                  setSmsContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { pageSize },
                  }),
              })}
            />
          ) : (
            <div className="text-center">No results</div>
          )}
        </CardBody>
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: '', body: '' })
          }
        />
      ) : null}
    </Container >
  );
};

export default Sms;

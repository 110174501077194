import React from "react";
import { useSidebar } from "../providers/sidebarProvider";

const Hamburger = () => {
  const [sidebarContext, setSidebarContext] = useSidebar();

  return (
    <span
      className="sidebar-toggle d-flex ml-3 pl-1 mb-n3 mt-2 d-block d-lg-none no-print"
      onClick={() => setSidebarContext({ isOpen: !sidebarContext.isOpen })}
    >
      <i className="hamburger align-self-center" />
    </span>
  );
};

export default Hamburger;

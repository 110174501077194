import React, { useReducer, createContext, useContext } from "react";

const MAX_PAGE_SIZE = 9999;
const INIT_PAGE_SIZE = 25;

const initialState = {
    loading: false,
    totalAcres: 0,
    totalTractorHours: 0,
    totalOperatorHours: 0,
    totalRevenue: 0,
    totalRevenueByCommodity: []
};

const ACTIONS = {
    REFRESH: "REFRESH",
    GET_DATA_LOADING: "GET_DATA_LOADING",
    GET_DATA_SUCCESS: "GET_DATA_SUCCESS"
};

const reducer = (state, data) => {
    switch (data.action) {

        case ACTIONS.GET_DATA_LOADING: {
            const { payload } = data;

            return {
                ...state,
                loading: payload
            };
        };

        case ACTIONS.GET_DATA_SUCCESS: {
            const { payload } = data;
            const { kpis } = payload;

            return {
                ...state,
                loading: false,
                ...kpis
            };
        };

        case ACTIONS.REFRESH:
            return {
                ...state,
                refresh: !state.refresh
            };

        default:
            throw new Error();
    }
};

const WorkOrdersKPIsContext = createContext(initialState);

const WorkOrdersKPIsProvider = ({ children }) => {
    const stateAndDispatch = useReducer(reducer, initialState);
    return (
        <WorkOrdersKPIsContext.Provider value={stateAndDispatch}>
            {children}
        </WorkOrdersKPIsContext.Provider>
    );
}

export const useWorkOrdersKPIs = () => useContext(WorkOrdersKPIsContext);

export { WorkOrdersKPIsContext, WorkOrdersKPIsProvider, ACTIONS, MAX_PAGE_SIZE, INIT_PAGE_SIZE };
import React from 'react';

import Routes from './routes/Routes';
import { AuthProvider } from './providers/authProvider';
import { WorkOrdersProvider } from './providers/workOrdersProvider';
import { UsersProvider } from './providers/usersProvider';
import { SidebarProvider } from './providers/sidebarProvider';
import { PurchaseOrdersProvider } from './providers/purchaseOrdersProvider';
import { JobsProvider } from './providers/jobsProvider';
import { EquipmentsProvider } from './providers/equipmentsProvider';
import { CommoditiesProvider } from './providers/commoditiesProvider';
import { VehiclesProvider } from './providers/vehiclesProvider';
import { OperationTypesProvider } from './providers/operationTypesProvider';
import { OperationsProvider } from './providers/operationsProvider';
import { VendorsProvider } from './providers/vendorsProvider';
import { SmsProvider } from './providers/smsProvider';
import { CustomersProvider } from './providers/customersProvider';
import { WorkOrderDetailsProvider } from './providers/workOrderDetailsProvider';
import { WorkOrdersKPIsProvider } from './providers/workOrdersKPIsProvider';

class App extends React.Component {
  
  render() {
    return (
      <AuthProvider>
        <WorkOrdersProvider>
          <UsersProvider>
            <SidebarProvider>
              <PurchaseOrdersProvider>
                <JobsProvider>
                  <EquipmentsProvider>
                    <CommoditiesProvider>
                      <VehiclesProvider>
                        <OperationTypesProvider>
                          <OperationsProvider>
                            <VendorsProvider>
                              <SmsProvider>
                                <CustomersProvider>
                                  <WorkOrderDetailsProvider>
                                    <WorkOrdersKPIsProvider>
                                      <Routes />
                                    </WorkOrdersKPIsProvider>
                                  </WorkOrderDetailsProvider>
                                </CustomersProvider>
                              </SmsProvider>
                            </VendorsProvider>
                          </OperationsProvider>
                        </OperationTypesProvider>
                      </VehiclesProvider>
                    </CommoditiesProvider>
                  </EquipmentsProvider>
                </JobsProvider>
              </PurchaseOrdersProvider>
            </SidebarProvider>
          </UsersProvider>
        </WorkOrdersProvider>
      </AuthProvider>
    );
  }
}

export default App;

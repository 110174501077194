import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNumber } from '@turf/turf';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import Loader from '../../../components/Loader';
import { customersApi } from '../../../services/customersServices';

const initialContactData = {
  name: '',
  email: '',
  phone: '',
};

const ContactModal = ({ customerId, toEdit = null, onSave, onClose }) => {
  const [loading, setLoading] = useState();
  const [contact, setContact] = useState(
    toEdit || { ...initialContactData, customer_id: customerId }
  );
  const [showValidations, setShowValidations] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !contact.name ||
      (contact.phone && (!isNumber(contact.phone) || contact.phone.length < 7))
    ) {
      setShowValidations(true);
      return;
    }
    setLoading(true);
    let new_contact = { ...contact };
    if (toEdit) {
      await customersApi.updateContact(new_contact);
    } else {
      await customersApi.createContact(new_contact);
    }
    onSave();
    setLoading(false);
    onClose();
  };

  const closeBtn = (
    <Button className='close' color='none' onClick={onClose}>
      &times;
    </Button>
  );

  // Load Contact
  useEffect(() => {
    if (toEdit) {
      setContact(toEdit);
    }
  }, [toEdit]);

  return (
    <Modal isOpen={true} size='sm'>
      <Form onSubmit={handleSubmit}>
        <ModalHeader
          className='d-flex justify-content-between'
          close={closeBtn}
        >
          {toEdit ? 'Edit' : 'Create'} Contact
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size='sm' />
            ) : (
              <Col sm='12'>
                <FormGroup row>
                  <Label sm={3}>
                    Name <span className='red-asterisk'>*</span>
                  </Label>
                  <Col sm={9}>
                    <Input
                      placeholder='Contact Name'
                      type='text'
                      name='name'
                      required
                      value={contact.name}
                      onChange={(e) =>
                        setContact({
                          ...contact,
                          name: e.target.value,
                        })
                      }
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={3}>
                    Email
                  </Label>
                  <Col sm={9}>
                    <Input
                      placeholder='Contact Email'
                      type='email'
                      name='email'
                      value={contact.email}
                      onChange={(e) =>
                        setContact({
                          ...contact,
                          email: e.target.value,
                        })
                      }
                    />
                  </Col>
                </FormGroup>
                <FormGroup row className='mb-0'>
                  <Label sm={3}>
                    Phone
                  </Label>
                  <Col sm={9}>
                    <Input
                      placeholder='Contact Phone'
                      type='phone'
                      name='phone'
                      min={7}
                      value={contact.phone}
                      onChange={(e) =>
                        setContact({
                          ...contact,
                          phone: e.target.value,
                        })
                      }
                    />
                  </Col>
                </FormGroup>
                <Alert
                  color='danger'
                  isOpen={showValidations}
                  toggle={() => setShowValidations(false)}
                  className='p-2'
                >
                  The phone must have at least 7 digits and be numeric
                </Alert>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter className='justify-content-between'>
          <Button color={'secondary'} onClick={onClose}>
            Cancel
          </Button>{' '}
          <Button
            disabled={loading}
            color='primary'
            className='rounded'
          >
            <FontAwesomeIcon icon={faSave} className='mr-2' />
            <span>Save</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ContactModal;

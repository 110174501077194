import { faPlus, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { RefreshCw } from 'react-feather';
import { useLocation } from 'react-router-dom';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import ConfirmationModal from '../../../components/ConfirmationModal';

import InformationModal from '../../../components/InformationModal';
import Loader from '../../../components/Loader';

import {
  useVendors,
  ACTIONS,
  MAX_PAGE_SIZE
} from '../../../providers/vendorsProvider';

import { vendorsApi } from '../../../services/vendorsServices';
import VendorModal from './VendorModal';

const TABLE_COLUMNS = (onSort) => [
  {
    dataField: 'name',
    text: 'Name',
    classes: 'text-truncate',
    sort: true,
    formatter: (name) => name || "-",
    onSort,
  },
  {
    dataField: 'address',
    text: 'Address',
    classes: 'text-truncate',
    sort: true,
    formatter: (address) => address || "-",
    onSort,
  }
];

const getSizePerPage = (size) => (size === 'All' ? MAX_PAGE_SIZE : size);

const Vendors = () => {
  const [vendorsContext, setVendorsContext] = useVendors();

  const [createVendorModal, setCreateVendorModal] = useState();
  const [editVendorModal, setEditVendorModal] = useState({ isOpen: false, id: null });

  const [loading, setLoading] = useState(true);

  const location = useLocation();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: '',
    body: '',
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: '',
    body: '',
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const onSort = (sortBy, direction) => {
    if (
      vendorsContext.sortBy === sortBy &&
      vendorsContext.direction === direction
    ) {
      return;
    }
    setVendorsContext({
      action: ACTIONS.SORT,
      payload: { sortBy, direction },
    });
  };

  const onCreate = () => setCreateVendorModal(true);

  const onDetails = id => setEditVendorModal({ isOpen: true, id });

  const onDelete = (event, vendor) => {
    event.stopPropagation();
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        setLoading(true);
        await vendorsApi.deleteVendor({ id: vendor.id });
        setLoading(false);
        setConfirmationModal(initConfirmationModal);
        setVendorsContext({
          action: ACTIONS.REFRESH,
        });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: 'Delete Vendor',
      body: `<span class="text-center">Do you confirm you want to delete Vendor ${vendor.vendor}?</span>`,
    });
  };

  useEffect(() => {
    setLoading(true);
    vendorsApi
      .getVendors({
        match: vendorsContext.match,
        page: vendorsContext.pagination?.page,
        pageSize: getSizePerPage(vendorsContext.pagination?.pageSize),
        sortBy: vendorsContext.sortBy,
        direction: vendorsContext.direction,
      })
      .then(vendors => {
        setVendorsContext({
          action: ACTIONS.GET_VENDORS_SUCCESS,
          payload: vendors,
        });
        setLoading(false);
      });
  }, [
    setVendorsContext,
    vendorsContext.sortBy,
    vendorsContext.direction,
    vendorsContext.pagination?.pageSize,
    vendorsContext.pagination?.page,
    vendorsContext.match,
    vendorsContext.status,
    vendorsContext.refresh,
    location.pathname,
  ]);

  return (
    <Container className="d-flex flex-column flex-grow-1" fluid>
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between mt-2">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className='mb-0 '>Vendors</h3>
            <small className='text-muted ml-2 pt-1'>({vendorsContext.pagination?.rowCount})</small>
          </div>
          <div className="card-actions d-flex align-items-center justify-content-between">
            <InputGroup size="m">
              <Input
                maxLength="50"
                value={vendorsContext.match || ''}
                onChange={(evt) =>
                  setVendorsContext({
                    action: ACTIONS.SEARCH,
                    payload: { match: evt.target.value },
                  })
                }
                className="border-right-0"
                placeholder="Search for.."
              />
              <InputGroupText className="custom-input input-group-text bg-primary text-white border-left-0 border-primary">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroupText>
            </InputGroup>
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button-plus mx-3"
              color="primary"
              onClick={onCreate}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button-refresh"
              color="primary"
              onClick={() =>
                setVendorsContext({
                  action: ACTIONS.REFRESH,
                })
              }
            >
              <RefreshCw />
            </Button>
          </div>
        </CardHeader>
        <CardBody className="d-flex flex-column">
          {loading ? (
            <Loader />
          ) : vendorsContext.vendors?.length ? (
            <BootstrapTable
              headerClasses='text-muted small'
              bootstrap4
              striped
              hover
              remote
              bordered={false}
              keyField="id"
              classes="cursor-pointer border"
              data={vendorsContext.vendors}
              columns={[
                ...TABLE_COLUMNS(onSort),
                {
                  dataField: 'id',
                  text: 'Actions',
                  classes: 'text-truncate text-center',
                  headerStyle: { width: '100px', textAlign: 'center' },
                  formatter: (id, vendor) => (
                    <div
                      className="cursor-pointer min-width-50"
                      onClick={event => onDelete(event, vendor)}
                    >
                      <Button size="sm" className="rounded d-flex align-items-center ml-2" color="danger">
                        <FontAwesomeIcon icon={faTrash} className="mr-1" />
                        <span>Delete</span>
                      </Button>
                    </div>
                  )
                },
              ]}
              rowEvents={{
                onClick: (_, vendor) => onDetails(vendor.id),
              }}
              defaultSorted={[
                {
                  dataField: vendorsContext.sortBy,
                  order: vendorsContext.direction,
                },
              ]}
              onTableChange={() => { }}
              pagination={paginationFactory({
                page: vendorsContext.pagination?.page,
                totalSize: vendorsContext.pagination?.rowCount,
                sizePerPage: getSizePerPage(vendorsContext.pagination?.pageSize),
                sizePerPageList: [
                  5,
                  10,
                  25,
                  50,
                  {
                    text: 'All',
                    value: vendorsContext.pagination?.rowCount,
                  },
                ],
                onPageChange: (page) =>
                  setVendorsContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  }),
                onSizePerPageChange: (pageSize) =>
                  setVendorsContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { pageSize },
                  }),
              })}
            />
          ) : (
            <div className="text-center">No results</div>
          )}
        </CardBody>
      </Card>
      {
        informationModal.isOpen ? (
          <InformationModal
            title={informationModal.title}
            body={informationModal.body}
            onClose={() =>
              setInformationModal({ isOpen: false, title: '', body: '' })
            }
          />
        ) : null
      }
      {
        createVendorModal ?
          <VendorModal
            onSubmit={() => {
              setCreateVendorModal(false);
              setVendorsContext({
                action: ACTIONS.REFRESH,
              });
            }}
            onClose={() => setCreateVendorModal(false)}
          />
          : null
      }
      {
        editVendorModal.isOpen ?
          <VendorModal
            vendorId={editVendorModal.id}
            onSubmit={() => {
              setEditVendorModal({ isOpen: false, id: null });
              setVendorsContext({
                action: ACTIONS.REFRESH,
              });
            }}
            onClose={() => setEditVendorModal({ isOpen: false, id: null })}
          />
          : null
      }
      {
        confirmationModal.isOpen ? (
          <ConfirmationModal {...confirmationModal} />
        ) : null
      }
    </Container >
  );
};

export default Vendors;

import React, { useEffect } from 'react';

import mapboxgl from 'mapbox-gl';

import { mapUtils } from '../../../utils/map';
import { ACTIONS, useWorkOrderDetails } from '../../../providers/workOrderDetailsProvider';

const WorkOrderMap = () => {
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  useEffect(() => {
    mapboxgl.clearStorage();
    mapUtils.getCenter(workOrderDetails.workOrder, (center) =>
      mapUtils.initMap(workOrderDetails.workOrder, center, map => {
        const mapZoom = map.getZoom();
        const mapCenter = map.getCenter();
        setWorkOrderDetails({
          action: ACTIONS.UPDATE_MAP_PROPERTIES,
          payload: { mapZoom, mapCenter }
        });
        map.on('moveend', () => {
          const mapZoom = map.getZoom();
          const mapCenter = map.getCenter();
          setWorkOrderDetails({
            action: ACTIONS.UPDATE_MAP_PROPERTIES,
            payload: { mapZoom, mapCenter }
          });
        });
      })
    );
  }, [workOrderDetails.workOrder, setWorkOrderDetails]);

  return (
    <div id="map" className="rounded flex-grow-1"></div>
  );
};

export default WorkOrderMap;

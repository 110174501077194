import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import Loader from '../../../components/Loader';
import { customersApi } from '../../../services/customersServices';

const initialCustomerData = {
  name: '',
  email: '',
  phone: '',
  physical_address_street: '',
  physical_address_city: '',
  physical_address_state: '',
  physical_address_zip: '',
  billing_address_street: '',
  billing_address_city: '',
  billing_address_state: '',
  billing_address_zip: '',
};

const CustomerModal = ({ customerId, onClose }) => {
  const [loading, setLoading] = useState();
  const [customer, setCustomer] = useState(initialCustomerData);

  const [addBillingAddress, setAddBillingAddress] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let new_customer = { ...customer };
    if (!addBillingAddress) {
      new_customer.billing_address_street = customer.physical_address_street;
      new_customer.billing_address_city = customer.physical_address_city;
      new_customer.billing_address_state = customer.physical_address_state;
      new_customer.billing_address_zip = customer.physical_address_zip;
    }
    await customersApi.createCustomer(new_customer);
    setLoading(false);
    onClose();
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  // Load Customer
  useEffect(() => {
    if (customerId) {
      setLoading(true);
      customersApi
        .getCustomer(customerId)
        .then((customer) => {
          setCustomer(customer);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [customerId]);

  return (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={handleSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          {customerId ? 'Edit' : 'Create'} Customer
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <Col sm="12">
                <Row className="mb-3">
                  <Col>
                    <strong>Customer Data</strong>
                  </Col>
                </Row>
                <FormGroup row>
                  <Label sm={3}>
                    Name <span className="red-asterisk">*</span>
                  </Label>
                  <Col sm={9}>
                    <Input
                      placeholder="Customer Name"
                      type="text"
                      name="name"
                      required
                      value={customer.name}
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          name: e.target.value,
                        })
                      }
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={3}>Email</Label>
                  <Col sm={9}>
                    <Input
                      placeholder="Customer Email"
                      type="email"
                      name="email"
                      value={customer.email}
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          email: e.target.value,
                        })
                      }
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={3}>Phone</Label>
                  <Col sm={9}>
                    <Input
                      placeholder="Customer Phone"
                      type="phone"
                      name="phone"
                      value={customer.phone}
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          phone: e.target.value,
                        })
                      }
                    />
                  </Col>
                </FormGroup>
                <Row className="mb-3">
                  <Col>
                    <strong>Physical Address</strong>
                  </Col>
                </Row>
                <FormGroup row>
                  <Label sm={3}>
                    Street <span className="red-asterisk">*</span>
                  </Label>
                  <Col sm={9}>
                    <Input
                      placeholder="Street"
                      type="text"
                      name="street"
                      required
                      value={customer.physical_address_street}
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          physical_address_street: e.target.value,
                        })
                      }
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={3}>
                    City <span className="red-asterisk">*</span>
                  </Label>
                  <Col sm={9}>
                    <Input
                      placeholder="City"
                      type="text"
                      name="city"
                      required
                      value={customer.physical_address_city}
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          physical_address_city: e.target.value,
                        })
                      }
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={3}>
                    State <span className="red-asterisk">*</span>
                  </Label>
                  <Col sm={9}>
                    <Input
                      placeholder="State"
                      type="text"
                      name="state"
                      required
                      value={customer.physical_address_state}
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          physical_address_state: e.target.value,
                        })
                      }
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={3}>
                    Zip <span className="red-asterisk">*</span>
                  </Label>
                  <Col sm={9}>
                    <Input
                      placeholder="Zip"
                      type="text"
                      name="state"
                      required
                      value={customer.physical_address_zip}
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          physical_address_zip: e.target.value,
                        })
                      }
                    />
                  </Col>
                </FormGroup>

                <Row className="mb-3">
                  <Col>
                    <div className="custom-control custom-switch">
                      <input
                        name="billing_visible"
                        type="checkbox"
                        className="custom-control-input switch"
                        id="customSwitch1"
                        value={addBillingAddress}
                        onClick={(e) => setAddBillingAddress(e.target.checked)}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customSwitch1"
                      >
                        <strong>Different billing address</strong>
                      </label>
                    </div>
                  </Col>
                </Row>

                {addBillingAddress && (
                  <>
                    <Row className="mb-3">
                      <Col>
                        <strong>Billing Address</strong>
                      </Col>
                    </Row>
                    <FormGroup row>
                      <Label sm={3}>Street</Label>
                      <Col sm={9}>
                        <Input
                          placeholder="Street"
                          type="text"
                          name="street"
                          value={customer.billing_address_street}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              billing_address_street: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={3}>City</Label>
                      <Col sm={9}>
                        <Input
                          placeholder="City"
                          type="text"
                          name="city"
                          value={customer.billing_address_city}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              billing_address_city: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={3}>State</Label>
                      <Col sm={9}>
                        <Input
                          placeholder="State"
                          type="text"
                          name="state"
                          value={customer.billing_address_state}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              billing_address_state: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className='mb-0'>
                      <Label sm={3}>Zip</Label>
                      <Col sm={9}>
                        <Input
                          placeholder="Zip"
                          type="text"
                          name="state"
                          value={customer.billing_address_zip}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              billing_address_zip: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </FormGroup>
                  </>
                )}
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={'secondary'} onClick={onClose}>
            Cancel
          </Button>{' '}
          <Button
            disabled={loading}
            color='primary'
            className='rounded'
          >
            <FontAwesomeIcon icon={faSave} className='mr-2' />
            <span>Save</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CustomerModal;

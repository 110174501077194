import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Datetime from 'react-datetime';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import Loader from '../../../../components/Loader';
import { operationsApi } from '../../../../services/operationsServices';
import { usersApi } from '../../../../services/usersServices';
import { utils } from '../../../../utils/utils';
import PickupsAutosSelect from '../tabs/workPerformed/PickupsAutosSelect';
import TractorSelect from '../tabs/workPerformed/TractorSelect';

const ROLE_EXECUTIVE = 1;
const ROLE_SUPERVISOR = 2;
const ROLE_OPERATOR = 3;

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
  }),
};

const animatedComponents = makeAnimated();

const SelectOperator = ({ onSelect, selected }) => {
  const [match, setMatch] = useState();
  const [loading, setLoading] = useState();
  const [options, setOptions] = useState([]);

  const getOptions = () =>
    options
      .map((item) => ({
        label: item.name || item.label,
        value: item.id || item.value,
      }))
      .filter((item) => item.value !== selected?.value);

  // Load operators from service
  useEffect(() => {
    setLoading(true);
    usersApi
      .getUsers({
        match,
        role: [ROLE_EXECUTIVE, ROLE_SUPERVISOR, ROLE_OPERATOR],
        page: 1,
        pageSize: 999,
      })
      .then((result) => {
        const parsed = (result?.users || []).map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setOptions(parsed);
      })
      .finally(() => setLoading(false));
  }, [match, selected]);

  return (
    <FormGroup row>
      <Label sm={4}>
        Operator<span className='red-asterisk'>*</span>
      </Label>
      <Col sm={8}>
        <Select
          required={true}
          placeholder={<span className='text-muted'>Type an operator...</span>}
          noOptionsMessage={() => 'No operators found'}
          styles={style}
          className='flex-grow-1 border rounded'
          options={getOptions()}
          closeMenuOnSelect={true}
          components={animatedComponents}
          value={selected}
          isSearchable
          isClearable
          inputValue={match}
          onInputChange={setMatch}
          onChange={onSelect}
          isLoading={loading}
        />
      </Col>
    </FormGroup>
  );
};

const SelectOperation = ({ onSelect, selected }) => {
  const [match, setMatch] = useState();
  const [loading, setLoading] = useState();
  const [options, setOptions] = useState([]);

  const getOptions = () =>
    options
      .map((item) => ({
        label: item.name || item.label,
        value: item.id || item.value,
      }))
      .filter((item) => item.value !== selected?.value);

  // Load operation types from service
  useEffect(() => {
    setLoading(true);
    operationsApi
      .getOperations({
        match,
        page: 1,
        pageSize: 999,
      })
      .then((result) => {
        const parsed = (result?.operations || []).map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setOptions(parsed);
      })
      .finally(() => setLoading(false));
  }, [match, selected]);

  return (
    <FormGroup row>
      <Label sm={4}>
        Operation<span className='red-asterisk'>*</span>
      </Label>
      <Col sm={8}>
        <Select
          required={true}
          placeholder={<span className='text-muted'>Type an operation..</span>}
          noOptionsMessage={() => 'No operation types found'}
          styles={style}
          className='flex-grow-1 border rounded'
          options={getOptions()}
          closeMenuOnSelect={true}
          components={animatedComponents}
          value={selected}
          isSearchable
          isClearable
          inputValue={match}
          onInputChange={(value) => setMatch(value)}
          onChange={onSelect}
          isLoading={loading}
        />
      </Col>
    </FormGroup>
  );
};

const WorkDayFormModal = ({ workDay, disabled, onSubmit, onClose }) => {

  const [selectedPickupAuto, setSelectedPickupAuto] = useState(
    workDay?.vehicle
      ? {
        value: workDay.vehicle.id,
        label: workDay.vehicle.equipment,
      }
      : null
  );
  const [selectedTractor, setSelectedTractor] = useState(
    workDay?.equipment
      ? {
        value: workDay.equipment.id,
        label: workDay.equipment.equipment,
      }
      : null
  );

  const [loading, setLoading] = useState();
  const [error, setError] = useState(null);
  const [_workDay, setWorkDay] = useState(workDay);
  const [selectedDate, setSelectedDate] = useState(workDay?.date || new Date());
  const [selectedOperator, setSelectedOperator] = useState();
  const [selectedOperation, setSelectedOperation] = useState();

  const params = useParams();

  const getTime = (date) => moment(date).local().format('HH:mm');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const [tractorStartTimeHour, tractorStartTimeMinute] =
      _workDay.tractor_start_time.split(':');

    const tractorStartTime = moment(_workDay.date)
      .local()
      .set('hour', tractorStartTimeHour)
      .set('minute', tractorStartTimeMinute);

    const [tractorEndTimeHour, tractorEndTimeMinute] =
      _workDay.tractor_end_time.split(':');

    let tractorEndTime = moment(_workDay.date)
      .local()
      .set('hour', tractorEndTimeHour)
      .set('minute', tractorEndTimeMinute);

    if (tractorEndTime.isSame(tractorStartTime)) {
      setError('Tractor end time must be after start time');
      return;
    }
    if (tractorEndTime.isBefore(tractorStartTime)) {
      tractorEndTime = tractorEndTime.add(1, 'day');
    }

    if (
      parseInt(_workDay.tractor_start_hours) >=
      parseInt(_workDay.tractor_end_hours)
    ) {
      setError('Tractor end hours must be greater than Tractor start hours');
      return;
    }

    if (!selectedOperator) {
      setError('Please select an Operator');
      return;
    }

    if (!selectedOperation) {
      setError('Please select an Operation');
      return;
    }

    const [operatorStartTimeHour, operatorStartTimeMinute] =
      _workDay.operator_start_time.split(':');

    const operatorStartTime = moment(_workDay.date)
      .local()
      .set('hour', operatorStartTimeHour)
      .set('minute', operatorStartTimeMinute);

    const [operatorEndTimeHour, operatorEndTimeMinute] =
      _workDay.operator_end_time.split(':');

    let operatorEndTime = moment(_workDay.date)
      .local()
      .set('hour', operatorEndTimeHour)
      .set('minute', operatorEndTimeMinute);

    if (operatorEndTime.isSame(operatorStartTime)) {
      setError('Operator end time must be after start time');
      return;
    }

    if (operatorEndTime.isBefore(tractorStartTime)) {
      operatorEndTime = operatorEndTime.add(1, 'day');
    }

    await onSubmit({
      ..._workDay,
      date: selectedDate,
      tractor_start_time: tractorStartTime.utc(),
      tractor_end_time: tractorEndTime.utc(),
      operator_start_time: operatorStartTime.utc(),
      operator_end_time: operatorEndTime.utc(),
      user_id: selectedOperator.value,
      operation_id: selectedOperation.value,
      work_order_id: params.workOrderId,
      vehicle_id: selectedPickupAuto?.value,
      equipment_id: selectedTractor?.value,
    });
  };

  const handleClose = () => {
    setWorkDay(null);
    onClose();
  };

  useEffect(() => {
    setLoading(true);

    const currentDate = new Date();
    let tractor_start_time = getTime(currentDate);
    let tractor_end_time = getTime(currentDate);
    let operator_start_time = getTime(currentDate);
    let operator_end_time = getTime(currentDate);
    let object = {};
    if (workDay) {
      const { operation, user: operator } = workDay;
      tractor_start_time = getTime(workDay.tractor_start_time);
      tractor_end_time = getTime(workDay.tractor_end_time);
      operator_start_time = getTime(workDay.operator_start_time);
      operator_end_time = getTime(workDay.operator_end_time);
      object = workDay;
      if (operator) {
        setSelectedOperator({ value: operator.id, label: operator.name });
      };
      if (operation) {
        setSelectedOperation({ value: operation.id, label: operation.name });
      };
    } else {
      setSelectedOperator(null);
      setSelectedOperation(null);
    }
    setWorkDay({
      ...object,
      tractor_start_time,
      tractor_end_time,
      operator_start_time,
      operator_end_time,
    });
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workDay]);

  return (
    <Modal isOpen={true} size='sm'>
      <Form onSubmit={handleSubmit}>
        <ModalHeader
          className='d-flex justify-content-between'
          close={
            <Button className='close' color='none' onClick={handleClose}>
              &times;
            </Button>
          }
        >
          {workDay ? 'Edit' : 'Create'} Work Hours
        </ModalHeader>
        <ModalBody>
          {loading ? (
            <Loader size='sm' />
          ) : (
            <>
              <Alert color='danger' className='p-2' dismissible={"true"} isOpen={error}>
                {error}
              </Alert>
              <FormGroup row>
                <Label sm={4}>
                  Date
                  <span className='red-asterisk'>*</span>
                </Label>
                <Datetime
                  renderInput={(props) => (
                    <input
                      {...props}
                      required={true}
                      placeholder='Date'
                      className='form-control'
                    />
                  )}
                  closeOnSelect={true}
                  locale='us'
                  utc={true}
                  dateFormat={utils.DATE_FORMAT}
                  timeFormat={false}
                  className='col-sm-8'
                  value={selectedDate}
                  onChange={value => {
                    const date = moment(value).format("YYYY-MM-DD");
                    setSelectedDate(date);
                  }}
                />
              </FormGroup>
              <SelectOperator
                selected={selectedOperator}
                onSelect={setSelectedOperator}
              />
              <SelectOperation
                selected={selectedOperation}
                onSelect={setSelectedOperation}
              />
              <FormGroup row>
                <Label sm={4}>Job Ticket #</Label>
                <Col sm={8}>
                  <Input
                    maxLength={255}
                    type='text'
                    defaultValue={_workDay?.job_ticket || ""}
                    onBlur={(e) =>
                      setWorkDay({
                        ..._workDay,
                        job_ticket: e.target.value,
                      })
                    }
                  />
                </Col>
              </FormGroup>
              <hr />
              <FormGroup className='my-1'>
                <Label>
                  <strong>Work time</strong>
                </Label>
              </FormGroup>
              <FormGroup row>
                <Label sm={5}>Tractor Start Hours</Label>
                <Col sm={7}>
                  <Input
                    max={1000000}
                    type='number'
                    defaultValue={_workDay?.tractor_start_hours || ""}
                    onBlur={(e) =>
                      setWorkDay({
                        ..._workDay,
                        tractor_start_hours: e.target.value,
                      })
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={5}>Tractor End Hours</Label>
                <Col sm={7}>
                  <Input
                    max={1000000}
                    type='number'
                    value={_workDay?.tractor_end_hours || ""}
                    onChange={(e) =>
                      setWorkDay({
                        ..._workDay,
                        tractor_end_hours: e.target.value,
                      })
                    }
                  />
                </Col>
              </FormGroup>
              <hr />
              <FormGroup row className='my-1'>
                <Label sm={12}>
                  <strong>Tractor Time</strong>
                </Label>
              </FormGroup>
              <FormGroup row className='my-1'>
                <Col sm={5}>
                  <Input
                    type='time'
                    value={_workDay?.tractor_start_time || ""}
                    onChange={(e) =>
                      setWorkDay({
                        ..._workDay,
                        tractor_start_time: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col sm={2}>
                  <strong>To:</strong>
                </Col>
                <Col sm={5}>
                  <Input
                    type='time'
                    value={_workDay?.tractor_end_time || ""}
                    onChange={(e) =>
                      setWorkDay({
                        ..._workDay,
                        tractor_end_time: e.target.value,
                      })
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row className='my-1'>
                <Label sm={12}>
                  <strong>Operator Time</strong>
                </Label>
              </FormGroup>
              <FormGroup row className='mb-1'>
                <Col sm={5}>
                  <Input
                    type='time'
                    value={_workDay?.operator_start_time || ""}
                    onChange={(e) =>
                      setWorkDay({
                        ..._workDay,
                        operator_start_time: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col sm={2}>
                  <strong>To:</strong>
                </Col>
                <Col sm={5}>
                  <Input
                    type='time'
                    value={_workDay?.operator_end_time || ""}
                    onChange={(e) =>
                      setWorkDay({
                        ..._workDay,
                        operator_end_time: e.target.value,
                      })
                    }
                  />
                </Col>
              </FormGroup>
              <hr />
              <FormGroup className='my-1'>
                <PickupsAutosSelect
                  selected={selectedPickupAuto}
                  onSelect={setSelectedPickupAuto}
                />
                <TractorSelect
                  selected={selectedTractor}
                  onSelect={setSelectedTractor}
                />
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter className='d-flex justify-content-between align-items-center'>
          <Button color='secondary' type='reset' onClick={handleClose}>
            Close
          </Button>
          <Button
            disabled={disabled || loading}
            color='primary'
            className='rounded'
          >
            <FontAwesomeIcon icon={faSave} className='mr-2' />
            <span>Save</span>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default WorkDayFormModal;

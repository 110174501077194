import React, { useEffect, useState } from 'react';

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

import { commoditiesApi } from '../../../services/commoditiesServices';

import InformationModal from '../../../components/InformationModal';
import Loader from '../../../components/Loader';

const CommodityModal = ({ commodityId, onSubmit, onClose }) => {
  const [commodity, setCommodity] = useState({});

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: '',
    body: '',
  });

  const [loading, setLoading] = useState();

  const doSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const promise = commodity.id ? commoditiesApi.updateCommodity(commodity) : commoditiesApi.createCommodity(commodity);
    promise.then(() => {
      setLoading(false);
      onSubmit();
    })
      .catch((err) => {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: `${commodity.id ? 'Update' : 'Create'} Commodity`,
          body:
            err?.response?.data[0].msg ||
            'There was an error with your request.',
        });
      });
  };

  // Load Commodity
  useEffect(() => {
    if (commodityId) {
      setLoading(true);
      commoditiesApi.getCommodity(commodityId)
        .then(result => {
          setLoading(false);
          const commodity = result || {};
          setCommodity(commodity);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [commodityId]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: '', body: '' })
      }
    />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          {commodityId ? 'Edit' : 'Create'} Commodity
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <Col sm="12">
                <FormGroup className="mb-0">
                  <Label>
                    Name
                    <small className="text-danger ml-1">*</small>
                  </Label>
                  <Input
                    placeholder="Type something.."
                    maxLength="100"
                    type="text"
                    name="commodity"
                    value={commodity.name}
                    onChange={(e) =>
                      setCommodity({
                        ...commodity,
                        name: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={'secondary'} onClick={onClose}>
                Cancel
              </Button>
              <Button color={'primary'} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CommodityModal;

import React, { useEffect, useState } from 'react';

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

import { equipmentsApi } from '../../../services/equipmentsServices';

import InformationModal from '../../../components/InformationModal';
import Loader from '../../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

const EquipmentModal = ({ equipmentId, onSubmit, onClose }) => {
  const [equipment, setEquipment] = useState({});

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: '',
    body: '',
  });

  const [loading, setLoading] = useState();

  const doSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const promise = equipment.id ? equipmentsApi.updateEquipment(equipment) : equipmentsApi.createEquipment(equipment);
    promise.then(() => {
      setLoading(false);
      onSubmit();
    })
      .catch((err) => {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: `${equipment.id ? 'Update' : 'Create'} Equipment`,
          body:
            err?.response?.data[0].msg ||
            'There was an error with your request.',
        });
      });
  };

  // Load Equipment
  useEffect(() => {
    if (equipmentId) {
      setLoading(true);
      equipmentsApi.getEquipment(equipmentId)
        .then(result => {
          setLoading(false);
          const equipment = result || {};
          setEquipment(equipment);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [equipmentId]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: '', body: '' })
      }
    />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          {equipmentId ? 'Edit' : 'Create'} Equipment
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <Col sm="12">
                <FormGroup>
                  <Label>
                    Prefix
                  </Label>
                  <Input
                    placeholder="Type something.."
                    maxLength="100"
                    type="text"
                    name="prefix"
                    value={equipment.prefix}
                    onChange={(e) =>
                      setEquipment({
                        ...equipment,
                        prefix: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Equipment
                    <small className="text-danger ml-1">*</small>
                  </Label>
                  <Input
                    placeholder="Type something.."
                    maxLength="100"
                    type="text"
                    name="equipment"
                    value={equipment.equipment}
                    onChange={(e) =>
                      setEquipment({
                        ...equipment,
                        equipment: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Year
                  </Label>
                  <Input
                    placeholder="Type something.."
                    min="1900"
                    max="2100"
                    type="number"
                    name="year"
                    value={equipment.year}
                    onChange={(e) =>
                      setEquipment({
                        ...equipment,
                        year: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Make
                  </Label>
                  <Input
                    placeholder="Type something.."
                    maxLength="100"
                    type="text"
                    name="make"
                    value={equipment.make}
                    onChange={(e) =>
                      setEquipment({
                        ...equipment,
                        make: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Model
                  </Label>
                  <Input
                    placeholder="Type something.."
                    maxLength="100"
                    type="text"
                    name="model"
                    value={equipment.model}
                    onChange={(e) =>
                      setEquipment({
                        ...equipment,
                        model: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    License
                  </Label>
                  <Input
                    placeholder="Type something.."
                    maxLength="100"
                    type="text"
                    name="license"
                    value={equipment.license}
                    onChange={(e) =>
                      setEquipment({
                        ...equipment,
                        license: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Serial Number
                  </Label>
                  <Input
                    placeholder="Type something.."
                    maxLength="100"
                    type="text"
                    name="serial_number"
                    value={equipment.serial_number}
                    onChange={(e) =>
                      setEquipment({
                        ...equipment,
                        serial_number: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    VIN Number
                  </Label>
                  <Input
                    placeholder="Type something.."
                    maxLength="100"
                    type="text"
                    name="VIN_number"
                    value={equipment.VIN_number}
                    onChange={(e) =>
                      setEquipment({
                        ...equipment,
                        VIN_number: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Body Type
                  </Label>
                  <Input
                    placeholder="Type something.."
                    maxLength="100"
                    type="text"
                    name="body_type"
                    value={equipment.body_type}
                    onChange={(e) =>
                      setEquipment({
                        ...equipment,
                        body_type: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Weight
                  </Label>
                  <Input
                    placeholder="Type something.."
                    maxLength="100"
                    type="text"
                    name="weight"
                    value={equipment.weight}
                    onChange={(e) =>
                      setEquipment({
                        ...equipment,
                        weight: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Fuel Type
                  </Label>
                  <Input
                    placeholder="Type something.."
                    maxLength="100"
                    type="text"
                    name="fuel_type"
                    value={equipment.fuel_type}
                    onChange={(e) =>
                      setEquipment({
                        ...equipment,
                        fuel_type: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Parts Owners Manual
                  </Label>
                  <Input
                    placeholder="Type something.."
                    maxLength="100"
                    type="text"
                    name="parts_owners_manual"
                    value={equipment.parts_owners_manual}
                    onChange={(e) =>
                      setEquipment({
                        ...equipment,
                        parts_owners_manual: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={'secondary'} onClick={onClose}>
                Cancel
              </Button>
              <Button
                color='primary'
                className='rounded'
                type='submit'
              >
                <FontAwesomeIcon icon={faSave} className='mr-2' />
                <span>Save</span>
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EquipmentModal;

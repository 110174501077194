import axios from 'axios';
import config from '../config/config';

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}&reason=1`;
  } else if (err?.response?.status === 404) {
    window.location.href = '/error/404';
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = '/error/500';
  }
};

export const farmsApi = {
  getAlerts: () =>
    axios(`/${config.apiVersion}/api/farms/alerts`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: 'get',
    })
      .then((result) => result.data)
      .catch(handleError),
  getFarms: (data) => {
    const query = Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join('&');
    return axios(`/${config.apiVersion}/api/farms?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: 'get',
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getFarm: (id) => {
    return axios(`/${config.apiVersion}/api/farms/${id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: 'get',
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createFarm: (data) =>
    axios(`/${config.apiVersion}/api/farms/`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: 'post',
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  notifyFarm: (data) =>
    axios(`/${config.apiVersion}/api/farms/${data.id}/notify`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: 'put',
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  updateFarm: (data) =>
    axios(`/${config.apiVersion}/api/farms/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: 'put',
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  deleteFarm: (id) =>
    axios(`/${config.apiVersion}/api/farms/${id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: 'delete',
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
};

import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const TableHeaders = ({ headers, onDragEnd, onDragStart }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Droppable droppableId='droppable' direction='horizontal'>
        {(provided) => (
          <thead>
            <tr ref={provided.innerRef} {...provided.droppableProps}>
              {headers.map((item, index) =>
                index !== headers.length - 1 ? (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <th
                        className='responsive-table-cell'
                        {...item.getHeaderProps(item.getSortByToggleProps())}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {item.render('Header')}
                      </th>
                    )}
                  </Draggable>
                ) : (
                  <th
                    className='responsive-table-cell'
                    {...item.getHeaderProps(item.getSortByToggleProps())}
                  >
                    {item.render('Header')}
                  </th>
                )
              )}
              {provided.placeholder}
            </tr>
          </thead>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default TableHeaders;

import React, { useReducer, createContext, useContext } from 'react';

const INITIAL_PAGE_SIZE = 10;
const MAX_PAGE_SIZE = 999;

const initialState = {
  customers: [],
  pagination: {
    page: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
  match: null,
  status: null,
  sortBy: 'created_at',
  direction: 'desc',
};

const ACTIONS = {
  SORT: 'SORT',
  SEARCH: 'SEARCH',
  REFRESH: 'REFRESH',
  STATUS_CHANGE: 'STATUS_CHANGE',
  PAGE_CHANGE: 'PAGE_CHANGE',
  PAGE_SIZE_CHANGE: 'PAGE_SIZE_CHANGE',
  GET_CUSTOMERS_SUCCESS: 'GET_CUSTOMERS_SUCCESS',
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_CUSTOMERS_SUCCESS: {
      const { payload } = data;
      const { customers, pagination } = payload;

      return {
        ...state,
        customers,
        pagination,
      };
    }

    case ACTIONS.SORT: {
      const { payload } = data;
      const { sortBy, direction } = payload;

      return {
        ...state,
        sortBy,
        direction,
        pagination: {
          page: 0,
          pageSize: INITIAL_PAGE_SIZE,
        },
      };
    }

    case ACTIONS.SEARCH: {
      const { payload } = data;
      const { match } = payload;

      return {
        ...state,
        match,
        pagination: {
          page: 0,
          pageSize: INITIAL_PAGE_SIZE,
        },
      };
    }

    case ACTIONS.REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case ACTIONS.STATUS_CHANGE: {
      const { payload } = data;
      const { status } = payload;
      return {
        ...state,
        status,
      };
    }

    case ACTIONS.PAGE_CHANGE: {
      const { payload } = data;
      const { page } = payload;
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page,
        },
      };
    }

    case ACTIONS.PAGE_SIZE_CHANGE: {
      const { payload } = data;
      const { pageSize } = payload;
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageSize,
          page: 1,
        },
      };
    }

    default:
      throw new Error();
  }
};

const CustomersContext = createContext(initialState);

const CustomersProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <CustomersContext.Provider value={stateAndDispatch}>
      {children}
    </CustomersContext.Provider>
  );
};

export const useCustomers = () => useContext(CustomersContext);

export { CustomersContext, CustomersProvider, ACTIONS, MAX_PAGE_SIZE };

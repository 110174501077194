import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const Footer = () => (
  <footer className="footer">
    <Container className="d-flex flex-column flex-grow-1" fluid>
      <Row className="text-muted">
        <Col xs={8} className="text-left">
          <ul className="list-inline d-flex flex-column flex-lg-row flex-wrap">
            <li hidden className="list-inline-item">
              <Link to="dashboard" className="text-muted mr-1">
                Soporte
              </Link>
            </li>
            <li hidden className="list-inline-item">
              <Link to="dashboard" className="text-muted mr-1">
                Privacidad
              </Link>
            </li>
            <li hidden className="list-inline-item">
              <Link to="dashboard" className="text-muted mr-1">
                Terminos del Servicio
              </Link>
            </li>
            <li hidden className="list-inline-item">
              <Link to="dashboard" className="text-muted">
                Contacto
              </Link>
            </li>
          </ul>
        </Col>
        <Col xs={4} className="text-right">
          <p className="mb-0">
            &copy; {new Date().getFullYear()} -{" "}
            <a rel="noopener noreferrer" href="https://shi.farm" target="_blank" className="text-muted">
              Sun Harvest
            </a>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;

import {
  faChevronLeft,
  faPen,
  faPlus,
  faSave,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classnames from 'classnames';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from 'reactstrap';
import Header from '../../../components/Header';
import HeaderTitle from '../../../components/HeaderTitle';
import Loader from '../../../components/Loader';
import { customersApi } from '../../../services/customersServices';
import ContactModal from './ContactModal';
import CustomerDetailsMap from './CustomerDetailsMap';
import JobModal from '../jobs/JobModal';
import InformationModal from '../../../components/InformationModal';

const DATA_TAB = 'DATA_TAB';
const JOBS_TAB = 'JOBS_TAB';
const FARMS_TAB = 'FARMS_TAB';
const CONTACTS_TAB = 'CONTACTS_TAB';

const CustomerDetails = () => {
  const history = useHistory();
  const { customerId } = useParams();

  const [activeTab, setActiveTab] = useState(DATA_TAB);
  const [customer, setCustomer] = useState();
  const [addBillingAddress, setAddBillingAddress] = useState(false);
  const [loading, setLoading] = useState();
  const [reload, setReload] = useState(false);

  const [showAddContactModal, setShowContactModal] = useState(false);
  const [contactToEdit, setContactToEdit] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: '',
    body: '',
  });

  useEffect(() => {
    if (customerId) {
      setLoading(true);
      customersApi
        .getCustomer(customerId)
        .then((data) => {
          setCustomer(data);
          customersApi.updateCustomer({
            ...data,
            notified: true,
          });
        })
        .finally(() => setLoading(false));
    }
  }, [customerId, reload]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let new_customer = { ...customer };
    if (!addBillingAddress) {
      new_customer.billing_address_street = customer.physical_address_street;
      new_customer.billing_address_city = customer.physical_address_city;
      new_customer.billing_address_state = customer.physical_address_state;
      new_customer.billing_address_zip = customer.physical_address_zip;
    }
    await customersApi.updateCustomer(new_customer);
    setLoading(false);
    setInformationModal({
      isOpen: true,
      title: 'Update Customer',
      body: 'Customer updated successfully.',
    });
  };

  const customerDataTab = (
    <Card className='rounded-0 box-shadow-none border-left border-bottom border-right pt-2'>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup row>
            <Label sm={4}>
              Name <span className='red-asterisk'>*</span>
            </Label>
            <Col sm={8}>
              <Input
                placeholder='Customer Name'
                type='text'
                name='name'
                required
                value={customer?.name}
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    name: e.target.value,
                  })
                }
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4}>Email</Label>
            <Col sm={8}>
              <Input
                placeholder='Customer Email'
                type='email'
                name='email'
                value={customer?.email || ''}
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    email: e.target.value,
                  })
                }
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4}>Phone</Label>
            <Col sm={8}>
              <Input
                placeholder='Customer Phone'
                type='phone'
                name='phone'
                value={customer?.phone || ''}
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    phone: e.target.value,
                  })
                }
              />
            </Col>
          </FormGroup>
          <Row className='mb-3'>
            <Col>
              <strong>Physical Address</strong>
            </Col>
          </Row>
          <FormGroup row>
            <Label sm={4}>
              Street <span className='red-asterisk'>*</span>
            </Label>
            <Col sm={8}>
              <Input
                placeholder='Street'
                type='text'
                name='street'
                required
                value={customer?.physical_address_street}
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    physical_address_street: e.target.value,
                  })
                }
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4}>
              City <span className='red-asterisk'>*</span>
            </Label>
            <Col sm={8}>
              <Input
                placeholder='City'
                type='text'
                name='city'
                required
                value={customer?.physical_address_city}
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    physical_address_city: e.target.value,
                  })
                }
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4}>
              State <span className='red-asterisk'>*</span>
            </Label>
            <Col sm={8}>
              <Input
                placeholder='State'
                type='text'
                name='state'
                required
                value={customer?.physical_address_state}
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    physical_address_state: e.target.value,
                  })
                }
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4}>
              Zip <span className='red-asterisk'>*</span>
            </Label>
            <Col sm={8}>
              <Input
                placeholder='Zip'
                type='text'
                name='state'
                required
                value={customer?.physical_address_zip}
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    physical_address_zip: e.target.value,
                  })
                }
              />
            </Col>
          </FormGroup>

          <Row className='mb-3'>
            <Col>
              <div className='custom-control custom-switch'>
                <input
                  name='billing_visible'
                  type='checkbox'
                  className='custom-control-input switch'
                  id='customSwitch1'
                  defaultChecked={addBillingAddress}
                  onClick={(e) => setAddBillingAddress(e.target.checked)}
                />
                <label className='custom-control-label' htmlFor='customSwitch1'>
                  <strong>Different Billing Address</strong>
                </label>
              </div>
            </Col>
          </Row>

          {addBillingAddress && (
            <>
              <Row className='mb-3'>
                <Col>
                  <strong>Billing Address</strong>
                </Col>
              </Row>
              <FormGroup row>
                <Label sm={4}>Street</Label>
                <Col sm={8}>
                  <Input
                    placeholder='Street'
                    type='text'
                    name='street'
                    value={customer?.billing_address_street}
                    onChange={(e) =>
                      setCustomer({
                        ...customer,
                        billing_address_street: e.target.value,
                      })
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4}>City</Label>
                <Col sm={8}>
                  <Input
                    placeholder='City'
                    type='text'
                    name='city'
                    value={customer?.billing_address_city}
                    onChange={(e) =>
                      setCustomer({
                        ...customer,
                        billing_address_city: e.target.value,
                      })
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4}>State</Label>
                <Col sm={8}>
                  <Input
                    placeholder='State'
                    type='text'
                    name='state'
                    value={customer?.billing_address_state}
                    onChange={(e) =>
                      setCustomer({
                        ...customer,
                        billing_address_state: e.target.value,
                      })
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4}>Zip</Label>
                <Col sm={8}>
                  <Input
                    placeholder='Zip'
                    type='text'
                    name='state'
                    value={customer?.billing_address_zip}
                    onChange={(e) =>
                      setCustomer({
                        ...customer,
                        billing_address_zip: e.target.value,
                      })
                    }
                  />
                </Col>
              </FormGroup>
            </>
          )}
          <hr />
          <div className='d-flex flex-row justify-content-end'>
            <Button color='primary' className='rounded'>
              <FontAwesomeIcon icon={faSave} className='mr-2' />
              <span>Save</span>
            </Button>
          </div>
        </Form>
      </CardBody>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: '', body: '' })
          }
        />
      ) : null}
    </Card>
  );

  const JobsTab = () => {
    const [editJobModal, setEditJobModal] = useState({
      isOpen: false,
      id: null,
    });

    return (
      <Card className='rounded-0 box-shadow-none border-left border-bottom border-right'>
        <CardBody>
          {!customer.jobs?.length > 0 ? (
            <span className='text-muted'>
              There are no jobs for this customer
            </span>
          ) : (
            <Table striped bordered className='my-2'>
              <tbody>
                {customer.jobs.map((job) => (
                  <tr key={job.id}>
                    <td className='d-flex justify-content-between border-0'>
                      <span>Job #{job.job_number}</span>
                      <Button
                        size='sm'
                        className='rounded align-items-center mx-2'
                        color='warning'
                        onClick={() =>
                          setEditJobModal({ isOpen: true, id: job.id })
                        }
                      >
                        <FontAwesomeIcon icon={faPen} className='mr-1' />
                        <span>Edit</span>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CardBody>
        {editJobModal.isOpen ? (
          <JobModal
            jobId={editJobModal.id}
            onSubmit={() => {
              setEditJobModal({ isOpen: false, id: null });
              setReload(!reload);
            }}
            onClose={() => setEditJobModal({ isOpen: false, id: null })}
          />
        ) : null}
      </Card>
    );
  };

  const FarmsTab = () => (
    <Card className='rounded-0 box-shadow-none border-left border-bottom border-right'>
      <CardBody>
        {!customer.farms?.length > 0 ? (
          <span className='text-muted'>
            There are no farms for this customer
          </span>
        ) : (
          <Table striped bordered className='my-2'>
            <tbody>
              {customer.farms.map((farm) => (
                <tr key={farm.id}>
                  <td className='d-flex justify-content-between border-0'>
                    <span>{farm.name}</span>
                    <Button
                      size='sm'
                      className='rounded align-items-center mx-2'
                      color='warning'
                      onClick={() => {
                        history.push(
                          `/customers/${customer.id}/farm/${farm.id}`
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faPen} className='mr-1' />
                      <span>Edit</span>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </CardBody>
    </Card>
  );

  const ContactsTab = () => (
    <Card className='rounded-0 box-shadow-none border-left border-bottom border-right'>
      <CardBody>
        {!customer.contacts?.length > 0 ? (
          <span className='text-muted'>
            There are no contacts for this customer
          </span>
        ) : (
          <Table striped bordered className='my-2'>
            <tbody>
              {customer.contacts.map((contact) => (
                <tr key={contact.id}>
                  <td className='d-flex justify-content-between border-0 align-items-center'>
                    <div className='d-flex flex-column'>
                      <strong>{contact.name}</strong>
                      <small className='text-muted'>
                        {contact.phone}{' '}
                        {contact.phone && contact.email ? '/' : ''}{' '}
                        {contact.email}
                      </small>
                    </div>
                    <Button
                      size='sm'
                      className='rounded align-items-center mx-2'
                      color='warning'
                      onClick={() => {
                        setContactToEdit(contact);
                        setShowContactModal(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faPen} className='mr-1' />
                      <span>Edit</span>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </CardBody>
    </Card>
  );

  return (
    <Container className='d-flex flex-column flex-grow-1' fluid>
      <Header className='mb-3 '>
        <HeaderTitle className='d-flex flex-wrap justify-content-between'>
          <div className='d-flex flex-column align-items-start col-6 px-0'>
            <span className='col-12 px-0 col-lg-6 text-dark'>
              Customer details
            </span>
            <Button
              size='sm'
              className='rounded mt-1 d-flex align-items-center'
              onClick={() => history.goBack()}
            >
              <FontAwesomeIcon icon={faChevronLeft} className='mr-1' />
              <span>Back</span>
            </Button>
          </div>
        </HeaderTitle>
      </Header>
      {loading || !customer ? (
        <Loader />
      ) : (
        <Row id='customerContainer' className='flex-grow-1'>
          <Col>
            <Nav
              tabs
              className='work-order-details-nav cursor-pointer d-flex justify-content-between'
            >
              <div className='d-flex'>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === DATA_TAB,
                    })}
                    onClick={() => setActiveTab(DATA_TAB)}
                  >
                    Customer Data
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === JOBS_TAB,
                    })}
                    onClick={() => setActiveTab(JOBS_TAB)}
                  >
                    Jobs
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === FARMS_TAB })}
                    onClick={() => setActiveTab(FARMS_TAB)}
                  >
                    Farms
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === CONTACTS_TAB,
                    })}
                    onClick={() => setActiveTab(CONTACTS_TAB)}
                  >
                    Contacts
                  </NavLink>
                </NavItem>
              </div>
              <NavItem>
                {activeTab === CONTACTS_TAB ? (
                  <Button
                    size='sm'
                    color='primary'
                    className='m-1 rounded'
                    onClick={() => setShowContactModal(true)}
                  >
                    <FontAwesomeIcon icon={faPlus} className='mr-1' />
                    <span>Add Contact</span>
                  </Button>
                ) : activeTab === FARMS_TAB ? (
                  <Button
                    size='sm'
                    color='primary'
                    className='m-1 rounded'
                    onClick={() =>
                      history.push(`/customers/${customer.id}/new/farm`)
                    }
                  >
                    <FontAwesomeIcon icon={faPlus} className='mr-1' />
                    <span>Add Farm</span>
                  </Button>
                ) : null}
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={DATA_TAB}>{customerDataTab}</TabPane>
            </TabContent>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={JOBS_TAB}>
                <JobsTab />
              </TabPane>
            </TabContent>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={FARMS_TAB}>
                <FarmsTab />
              </TabPane>
            </TabContent>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={CONTACTS_TAB}>
                <ContactsTab />
              </TabPane>
            </TabContent>
          </Col>
          <Col>
            <Row className='mb-3'>
              <Col>
                <CustomerDetailsMap customer={customer} loading={loading} />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {showAddContactModal && (
        <ContactModal
          customerId={customer.id}
          toEdit={contactToEdit}
          onSave={() => setReload(!reload)}
          onClose={() => {
            setContactToEdit(null);
            setShowContactModal(false);
          }}
        />
      )}
    </Container>
  );
};

export default CustomerDetails;

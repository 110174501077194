import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const DeleteWorkDayModal = ({ workDay, onClose, onConfirm }) => {
  return (
    <Modal isOpen>
      <ModalHeader
        className='d-flex justify-content-between'
        close={
          <Button className='close' color='none' onClick={onClose}>
            &times;
          </Button>
        }
      >
        Delete Work Hours
      </ModalHeader>
      <ModalBody>
        <span>Are you sure you want to delete pass number</span>{' '}
        <b>{`${workDay.pass_number}`} </b> <span>of </span>
        <b>{`${workDay.operation?.name || "(no operation)"}`}</b> <span>Performed by </span>{' '}
        <b>{`${workDay.user.name}`}?</b>
      </ModalBody>
      <ModalFooter className='d-flex align-items-center justify-content-between'>
        <Button color='primary' onClick={onClose}>
          Cancel
        </Button>
        <Button color='danger' onClick={onConfirm}>
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteWorkDayModal;

import { faPlus, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { RefreshCw } from 'react-feather';
import { useLocation } from 'react-router-dom';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import ConfirmationModal from '../../../components/ConfirmationModal';

import InformationModal from '../../../components/InformationModal';
import Loader from '../../../components/Loader';

import {
  useCommodities,
  ACTIONS,
  MAX_PAGE_SIZE
} from '../../../providers/commoditiesProvider';

import { commoditiesApi } from '../../../services/commoditiesServices';
import CommodityModal from '../commodities/CommodityModal';

const TABLE_COLUMNS = (onSort) => [
  {
    dataField: 'name',
    text: 'Name',
    classes: 'text-truncate',
    sort: true,
    formatter: (name) => name || "-",
    onSort,
  },
];

const getSizePerPage = (size) => (size === 'All' ? MAX_PAGE_SIZE : size);

const Commodities = () => {
  const [commoditiesContext, setCommoditiesContext] = useCommodities();

  const [createCommodityModal, setCreateCommodityModal] = useState();
  const [editCommodityModal, setEditCommodityModal] = useState({ isOpen: false, id: null });

  const [loading, setLoading] = useState(true);

  const location = useLocation();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: '',
    body: '',
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: '',
    body: '',
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const onSort = (sortBy, direction) => {
    if (
      commoditiesContext.sortBy === sortBy &&
      commoditiesContext.direction === direction
    ) {
      return;
    }
    setCommoditiesContext({
      action: ACTIONS.SORT,
      payload: { sortBy, direction },
    });
  };

  const onCreate = () => setCreateCommodityModal(true);

  const onDetails = id => setEditCommodityModal({ isOpen: true, id });

  const onDelete = (event, commodity) => {
    event.stopPropagation();
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        setLoading(true);
        await commoditiesApi.deleteCommodity({ id: commodity.id });
        setLoading(false);
        setConfirmationModal(initConfirmationModal);
        setCommoditiesContext({
          action: ACTIONS.REFRESH,
        });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: 'Delete Commodity',
      body: `<span class="text-center">Do you confirm you want to delete Commodity ${commodity.name}?</span>`,
    });
  };

  useEffect(() => {
    setLoading(true);
    commoditiesApi
      .getCommodities({
        match: commoditiesContext.match,
        page: commoditiesContext.pagination?.page,
        pageSize: getSizePerPage(commoditiesContext.pagination?.pageSize),
        sortBy: commoditiesContext.sortBy,
        direction: commoditiesContext.direction,
      })
      .then(commodities => {
        setCommoditiesContext({
          action: ACTIONS.GET_COMMODITIES_SUCCESS,
          payload: commodities,
        });
        setLoading(false);
      });
  }, [
    setCommoditiesContext,
    commoditiesContext.sortBy,
    commoditiesContext.direction,
    commoditiesContext.pagination?.pageSize,
    commoditiesContext.pagination?.page,
    commoditiesContext.match,
    commoditiesContext.status,
    commoditiesContext.refresh,
    location.pathname,
  ]);

  return (
    <Container className="d-flex flex-column flex-grow-1" fluid>
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between mt-2">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className='mb-0 '>Commodities</h3>
            <small className='text-muted ml-2 pt-1'>({commoditiesContext.pagination?.rowCount})</small>
          </div>
          <div className="card-actions d-flex align-items-center justify-content-between">
            <InputGroup size="m">
              <Input
                maxLength="50"
                value={commoditiesContext.match || ''}
                onChange={(evt) =>
                  setCommoditiesContext({
                    action: ACTIONS.SEARCH,
                    payload: { match: evt.target.value },
                  })
                }
                className="border-right-0"
                placeholder="Search for.."
              />
              <InputGroupText className="custom-input input-group-text bg-primary text-white border-left-0 border-primary">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroupText>
            </InputGroup>
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button-plus mx-3"
              color="primary"
              onClick={onCreate}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button-refresh"
              color="primary"
              onClick={() =>
                setCommoditiesContext({
                  action: ACTIONS.REFRESH,
                })
              }
            >
              <RefreshCw />
            </Button>
          </div>
        </CardHeader>
        <CardBody className="d-flex flex-column">
          {loading ? (
            <Loader />
          ) : commoditiesContext.commodities?.length ? (
            <BootstrapTable
              headerClasses='text-muted small'
              bootstrap4
              striped
              hover
              remote
              bordered={false}
              keyField="id"
              classes="cursor-pointer border"
              data={commoditiesContext.commodities}
              columns={[
                ...TABLE_COLUMNS(onSort),
                {
                  dataField: 'id',
                  text: 'Actions',
                  classes: 'text-truncate text-center',
                  headerStyle: { width: '100px', textAlign: 'center' },
                  formatter: (id, commodity) => (
                    <div
                      className="cursor-pointer min-width-50"
                      onClick={event => onDelete(event, commodity)}
                    >
                      <Button size="sm" className="rounded d-flex align-items-center ml-2" color="danger">
                        <FontAwesomeIcon icon={faTrash} className="mr-1" />
                        <span>Delete</span>
                      </Button>
                    </div>
                  )
                },
              ]}
              rowEvents={{
                onClick: (_, commodity) => onDetails(commodity.id),
              }}
              defaultSorted={[
                {
                  dataField: commoditiesContext.sortBy,
                  order: commoditiesContext.direction,
                },
              ]}
              onTableChange={() => { }}
              pagination={paginationFactory({
                page: commoditiesContext.pagination?.page,
                totalSize: commoditiesContext.pagination?.rowCount,
                sizePerPage: getSizePerPage(commoditiesContext.pagination?.pageSize),
                sizePerPageList: [
                  5,
                  10,
                  25,
                  50,
                  {
                    text: 'All',
                    value: commoditiesContext.pagination?.rowCount,
                  },
                ],
                onPageChange: (page) =>
                  setCommoditiesContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  }),
                onSizePerPageChange: (pageSize) =>
                  setCommoditiesContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { pageSize },
                  }),
              })}
            />
          ) : (
            <div className="text-center">No results</div>
          )}
        </CardBody>
      </Card>
      {
        informationModal.isOpen ? (
          <InformationModal
            title={informationModal.title}
            body={informationModal.body}
            onClose={() =>
              setInformationModal({ isOpen: false, title: '', body: '' })
            }
          />
        ) : null
      }
      {
        createCommodityModal ?
          <CommodityModal
            onSubmit={() => {
              setCreateCommodityModal(false);
              setCommoditiesContext({
                action: ACTIONS.REFRESH,
              });
            }}
            onClose={() => setCreateCommodityModal(false)}
          />
          : null
      }
      {
        editCommodityModal.isOpen ?
          <CommodityModal
            commodityId={editCommodityModal.id}
            onSubmit={() => {
              setEditCommodityModal({ isOpen: false, id: null });
              setCommoditiesContext({
                action: ACTIONS.REFRESH,
              });
            }}
            onClose={() => setEditCommodityModal({ isOpen: false, id: null })}
          />
          : null
      }
      {
        confirmationModal.isOpen ? (
          <ConfirmationModal {...confirmationModal} />
        ) : null
      }
    </Container >
  );
};

export default Commodities;

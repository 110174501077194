import React from "react";
import { withRouter } from "react-router-dom";

import { Button } from "reactstrap";
import { useAuth } from "../../providers/authProvider";

const Page404 = ({ history }) => {
  const [authContext] = useAuth();
  return (
    <div className="text-center">
      <h1 className="display-1 font-weight-bold">404</h1>
      <p className="h1">Page not found.</p>
      <p className="h2 font-weight-normal mt-3 mb-4">
        The page you are looking for might have been removed.
      </p>
      <Button 
        onClick={() => 
          authContext.currentUser ? 
              history.push('/work-orders') :
            history.push('/auth/sign-in')
        }
        color="primary" size="lg">
        Return to website
      </Button>
    </div>
  )
};

export default withRouter(Page404);

import React, { useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Col, FormGroup, Label } from 'reactstrap';

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
  }),
};

const animatedComponents = makeAnimated();

const SearchabledropDown = ({
  label,
  options,
  onSelect,
  selected,
  placeholder,
  noOpitionFoundText,
  isMulti = false,
  required = false,
  isDisabled = false,
  onInputChange = undefined,
  name = undefined,
}) => {
  const [match, setMatch] = useState();

  const onInputChangeWithState = (value) => {
    setMatch(value);
    if (onInputChange && value.length > 2) {
      onInputChange(value);
    }
  };
  return (
    <FormGroup row>
      <Label sm={3}>{label}</Label>
      <Col sm={9}>
        <Select
          inputValue={match}
          onInputChange={(value) => onInputChangeWithState(value)}
          isMulti={isMulti}
          required={required}
          placeholder={<span className='text-muted'>{placeholder}</span>}
          noOptionsMessage={() => noOpitionFoundText}
          styles={style}
          className='flex-grow-1 border rounded'
          options={options}
          closeMenuOnSelect={!isMulti}
          components={animatedComponents}
          defaultValue={selected}
          value={selected}
          isSearchable
          isClearable
          onChange={onSelect}
          isDisabled={isDisabled}
          name={name}
          isOptionDisabled={(option) => option.value === 0}
        />
      </Col>
    </FormGroup>
  );
};

export default SearchabledropDown;

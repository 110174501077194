import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Datetime from 'react-datetime';
import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import InformationModal from '../../../../components/InformationModal';
import Loader from '../../../../components/Loader';
import { ACTIONS, useWorkOrderDetails } from '../../../../providers/workOrderDetailsProvider';
import { operationsApi } from '../../../../services/operationsServices';
import { operationTypesApi } from '../../../../services/operationTypesServices';
import { workOrdersApi } from '../../../../services/workOrdersServices';
import FormField from '../../../../UI/FormField';
import SearchableDropdown from '../../../../UI/SearchableDropdown';
import { utils } from '../../../../utils/utils';

const SelectOperationType = ({ onSelect, selected }) => {
  const [operationTypeOptions, setOperationTypeOptions] = useState([]);

  // Load operation types from service
  useEffect(() => {
    operationTypesApi
      .getOperationTypes({
        page: 1,
        pageSize: 999,
      })
      .then((result) => {
        const parsed = result.operationTypes.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setOperationTypeOptions(parsed);
      });
  }, [selected]);

  return (
    <SearchableDropdown
      label='Operation types'
      noOpitionFoundText='No operation types found'
      onSelect={onSelect}
      options={operationTypeOptions}
      placeholder='Type an operation type...'
      selected={selected}
      required
    />
  );
};

const SelectOperation = ({ options, onSelect, selected }) => {
  const parsedOptions = options.map((item) => ({
    label: item.name || item.label,
    value: item.id || item.value,
  }));

  return (
    <SearchableDropdown
      label='Operations'
      noOpitionFoundText='No operation found'
      onSelect={onSelect}
      options={parsedOptions}
      placeholder='Type an operation...'
      selected={selected}
      isMulti
    />
  );
};

const DetailsTab = () => {
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const { customer, farm, contact, assignations, operations } = workOrderDetails.workOrder;
  const { commodity } = farm;

  const [loading, setLoading] = useState();
  const [selectedOperation, setSelectedOperation] = useState();
  const [selectedOperationType, setSelectedOperationType] = useState();
  const [operationList, setOperationsList] = useState([]);
  const [rowSpacing, setRowSpacing] = useState(workOrderDetails.workOrder?.row_spacing);
  const [estimate, setEstimate] = useState(workOrderDetails.workOrder?.estimate);
  const [usaTicket, setUsaTicket] = useState(workOrderDetails.workOrder?.usa_ticket);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [editable, setEditable] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: '',
    body: '',
  });

  useEffect(() => {
    if (workOrderDetails.workOrder) {
      setStartDate(workOrderDetails.workOrder.start_date || null);
      setEndDate(workOrderDetails.workOrder.end_date || null);
    };
  }, [workOrderDetails.workOrder]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (moment(startDate).isAfter(endDate, 'day')) {
      return setInformationModal({
        isOpen: true,
        title: 'Update Work Order',
        body: 'Start date can`t be before end date.',
      });
    };
    setLoading(true);
    workOrdersApi
      .updateWorkOrder({
        ...workOrderDetails.workOrder,
        row_spacing: rowSpacing,
        usa_ticket: usaTicket,
        estimate,
        operations: selectedOperation.map(({ value: id }) => ({ id })),
        start_date: startDate,
        end_date: endDate
      })
      .then(() => {
        setInformationModal({
          isOpen: true,
          title: 'Update Work Order',
          body: 'Work Order Updated Successfully.',
          onClose: () => {
            setWorkOrderDetails({
              action: ACTIONS.REFRESH,
            })
          }
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (workOrderDetails.workOrder) {
      const operationResult = operations.find(
        (operation) => operation.operation_type_id === workOrderDetails.workOrder.operation_type_id
      );
      if (operationResult) {
        const { name, id } = operationResult.operationType;
        setSelectedOperationType({ value: id, label: name });
      };
      const formatedOperations = operations.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setSelectedOperation(formatedOperations);
    };
  }, [operations, workOrderDetails.workOrder]);

  useEffect(() => {
    if (workOrderDetails.workOrder) {
      setEditable(workOrderDetails.workOrder.status !== 0);
      operationsApi
        .getOperations({ pageSize: 500 })
        .then((result) => {
          setOperationsList(result.operations);
        });
    };
  }, [workOrderDetails.workOrder]);

  return (
    <Row className='mt-3'>
      <Col>
        <h4 className='border-bottom pb-2'>Work Order Details</h4>
        {loading ? (
          <Loader size='sm' />
        ) : (
          <Form onSubmit={onSubmit} className='mt-3'>
            <FormField label='Customer' value={customer?.name} />
            <FormField label='Farm' value={farm?.name} />
            <FormField label='Field #' value={farm?.field_number} />
            <FormField label='Commodity' value={commodity?.name} />
            <FormField label='Acres' value={farm?.acres} />
            <FormField
              label='Row Spacing'
              value={rowSpacing}
              onChange={(e) => setRowSpacing(e.target.value)}
              editable={editable}
            />
            <FormField
              label='Contact'
              value={contact ? contact.name + ' / ' + contact.phone : ''}
            />
            <FormField
              label='Customer PO#'
              value={workOrderDetails.workOrder?.customer_PO_number}
            />
            {editable ? (
              <SelectOperationType
                onSelect={setSelectedOperationType}
                selected={selectedOperationType}
              />
            ) : (
              <FormField
                label='Operation Type'
                value={selectedOperationType?.label}
              />
            )}
            {editable ? (
              <SelectOperation
                options={operationList.filter(
                  (o) => o.operationType.id === selectedOperationType?.value
                )}
                onSelect={setSelectedOperation}
                selected={selectedOperation}
              />
            ) : (
              <FormField
                label='Operations'
                value={operations
                  ?.map((operation) => operation.name)
                  ?.join(',')}
              />
            )}
            <FormField
              label='Estimate'
              value={estimate}
              onChange={(e) => setEstimate(e.target.value)}
              editable={editable}
            />
            <FormField
              label='USA Ticket'
              value={usaTicket}
              onChange={(e) => setUsaTicket(e.target.value)}
              editable={editable}
            />
            {editable ? (
              <FormGroup row>
                <Label sm={3}>Start Date</Label>
                <Datetime
                  renderInput={(props) => (
                    <input
                      {...props}
                      required={true}
                      placeholder='Start Date'
                    />
                  )}
                  closeOnSelect={true}
                  locale='us'
                  utc={true}
                  dateFormat={utils.DATE_FORMAT}
                  timeFormat={false}
                  className='col-sm-9'
                  value={moment(startDate).format("YYYY-MM-DD")}
                  onChange={value => {
                    const date = moment(value).format("YYYY-MM-DD");
                    setStartDate(date);
                  }}
                />
              </FormGroup>
            ) : (
              <FormField label='Start Date' value={workOrderDetails.workOrder?.start_date} />
            )}
            {editable ? (
              <FormGroup row>
                <Label sm={3}>End Date</Label>
                <Datetime
                  renderInput={(props) => (
                    <input {...props} required={true} placeholder='End Date' />
                  )}
                  closeOnSelect={true}
                  locale='us'
                  dateFormat={utils.DATE_FORMAT}
                  timeFormat={false}
                  className='col-sm-9'
                  value={moment(endDate).format("YYYY-MM-DD")}
                  onChange={value => {
                    const date = moment(value).format("YYYY-MM-DD");
                    setEndDate(date);
                  }}
                />
              </FormGroup>
            ) : (
              <FormField label='End Date' value={endDate} />
            )}
            <FormField
              label='Operators'
              value={assignations
                ?.map((assignation) => assignation.user.name)
                ?.join(',')}
            />
            <FormField label='Created By' value={workOrderDetails.workOrder?.createdBy.name} />
            {editable && (
              <>
                <hr />
                <div className='d-flex flex-row justify-content-end'>
                  <Button color='warning' className='rounded'>Save</Button>
                </div>
              </>
            )}
          </Form>
        )}
      </Col>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() => informationModal.onClose ? informationModal.onClose() :
            setInformationModal({ isOpen: false, title: '', body: '' })
          }
        />
      ) : null}
    </Row>
  );
};

export default DetailsTab;


import React from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";

import { Badge } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SidebarItem = (
  ({ name, badgeColor, badgeText, icon, to, action }) => {
    const history = useHistory();
    const location = useLocation();

    return (
      <li
        id={name}
        className={`sidebar-item ${location.pathname === to ? "active" : ""}`}
        onClick={() => action ? action() : history.push(to)}
      >
        <NavLink
          to="#"
          className="sidebar-link text-truncate"
          activeClassName="active">
          {icon ? (
            <React.Fragment>
              <FontAwesomeIcon
                icon={icon}
                fixedWidth
                className="align-middle mx-2 sidebar-icon"
              />
              <span className="align-middle">{name}</span>
            </React.Fragment>
          ) : (
            name
          )}
          {badgeColor && badgeText !== undefined ? (
            <Badge style={{ paddingTop: "4px" }} color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

export default SidebarItem;
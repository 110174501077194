import moment from 'moment';

const ACRE = 'Acre';
const HOUR = 'Hour';
const DATE_FORMAT = "MM/DD/YYYY";

export const utils = {
  DATE_FORMAT,
  formatDateLocal: (date) => moment(date).local().format(DATE_FORMAT),
  roundUp: (value) => Number(Math.ceil(value)),
  getTimeDiffRounded: (time) => {
    if (isNaN(time) || time < 0) {
      return 0;
    }
    return Math.round(time * 4) / 4;
  },
  getAnotherBillingTypeLabel: (type) => type === HOUR ? ACRE : HOUR,
  formatCurrency: (number, maximumFractionDigits = 2) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits,
    }).format(number),
  formatDecimal: (number, maximumFractionDigits = 2) =>
    new Intl.NumberFormat('en-US', { maximumFractionDigits }).format(number),
  formatPercent: (progress, total) =>
    `${total ? ((progress / total) * 100).toFixed(2) : 0}%`,
  formatDate: (date, format) => (date ? moment(date) : moment()).format(format || DATE_FORMAT),
  formatDateTime: (date, format = `${DATE_FORMAT}, h:mm a`) =>
    (date ? moment(date) : moment()).format(format),
  formatTime: (date) => (date ? moment(date) : moment()).format('h:mm a'),
  capitalize: (text) =>
    text.charAt(0).toUpperCase() + text.toLowerCase().slice(1),
  getDateDiff: (dateOne, dateTwo) =>
    moment.duration(moment(dateTwo).diff(dateOne)).asHours().toFixed(2),
  compareOnlyTime: (dateOne, dateTwo) => {
    if (
      dateOne.getHours() * 60 * 60 +
      dateOne.getMinutes() * 60 +
      dateOne.getSeconds() >
      dateTwo.getHours() * 60 * 60 +
      dateTwo.getMinutes() * 60 +
      dateTwo.getSeconds()
    ) {
      return 1;
    }
    return -1;
  },
  formatTractorHours: (value) =>
    Number((Math.round(value * 4) / 4).toFixed(2)),
  updateWorkOrderValues: (workOrder, tractorHours, newBillingTotal) => {
    const updatedWorkOrder = { ...workOrder };
    if (updatedWorkOrder.billing_type === HOUR) {
      updatedWorkOrder.billing_total = newBillingTotal ? newBillingTotal : (
        updatedWorkOrder.hr_billing_rate * tractorHours
      ).toFixed(2);
      updatedWorkOrder.rate_per_type_actual = ( //opposite
        updatedWorkOrder.acres ? (updatedWorkOrder.billing_total / updatedWorkOrder.acres) : 0
      ).toFixed(0);
      updatedWorkOrder.if_filled_by_type = ( //opposite
        updatedWorkOrder.acre_billing_rate * updatedWorkOrder.acres
      ).toFixed(2);
      updatedWorkOrder.cost_by_type = tractorHours ? (updatedWorkOrder.if_filled_by_type / tractorHours) : 0;
    } else {
      updatedWorkOrder.billing_total = newBillingTotal ? newBillingTotal : (
        updatedWorkOrder.acre_billing_rate * updatedWorkOrder.acres
      ).toFixed(2);
      updatedWorkOrder.rate_per_type_actual = (
        tractorHours ? (updatedWorkOrder.billing_total / utils.formatTractorHours(tractorHours)) : tractorHours
      ).toFixed(0);
      updatedWorkOrder.if_filled_by_type = (
        updatedWorkOrder.hr_billing_rate * tractorHours
      ).toFixed(2);
      updatedWorkOrder.cost_by_type = updatedWorkOrder.acres ? (updatedWorkOrder.if_filled_by_type / updatedWorkOrder.acres) : 0;
      updatedWorkOrder.worked_acres = updatedWorkOrder.acre_billing_rate ? (updatedWorkOrder.billing_total / updatedWorkOrder.acre_billing_rate) : 0;
      updatedWorkOrder.acres = updatedWorkOrder.worked_acres;
    };
    return updatedWorkOrder;
  },
  calculateCloseOutWorkOrder: (workOrder, newBillingTotal) => {
    const totalHours = {
      tractorHours: 0,
      tractorTime: 0,
      operatorTime: 0,
    };

    workOrder.workDays.forEach((workDay) => {
      totalHours.tractorHours += utils.getTimeDiffRounded(
        workDay.tractor_end_hours - workDay.tractor_start_hours
      );
      totalHours.tractorTime += utils.getTimeDiffRounded(
        utils.getDateDiff(workDay.tractor_start_time, workDay.tractor_end_time)
      );
      totalHours.operatorTime += utils.getTimeDiffRounded(
        utils.getDateDiff(workDay.operator_start_time, workDay.operator_end_time)
      );
    });

    if (workOrder.billing_type === HOUR) {
      if (newBillingTotal) {
        totalHours.tractorHours = workOrder.hr_billing_rate ? workOrder.billing_total / workOrder.hr_billing_rate : 0;
      } else if (workOrder.total_hours) {
        totalHours.tractorHours = workOrder.total_hours;
      };
    };

    const updatedWorkOrder = utils.updateWorkOrderValues(workOrder, totalHours.tractorHours, newBillingTotal);

    const closeOutWorkOrder = {
      hourBillingRate: updatedWorkOrder.hr_billing_rate,
      acreBillingRate: updatedWorkOrder.acre_billing_rate,
      billingType: updatedWorkOrder.billing_type ? updatedWorkOrder.billing_type : HOUR,
      workedAcres: Number(updatedWorkOrder.acres),
      billingTotal: updatedWorkOrder.billing_total,
      ratePer: updatedWorkOrder.rate_per_type_actual,
      ifBilledBy: updatedWorkOrder.if_filled_by_type,
      costPer: updatedWorkOrder.cost_by_type,
      error: '',
    };

    return { closeOutWorkOrder, totalHours, updatedWorkOrder };
  },
  isMobile: () => (/Android|webOS|iPhone|iPod/i.test(navigator.userAgent) || (/Android|webOS|iPhone|iPod/i.test(navigator.platform)))
};

import {
  faChevronLeft,
  faEraser,
  faSave,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import counties from '../../../assets/counties';
import ConfirmationModal from '../../../components/ConfirmationModal';
import Header from '../../../components/Header';
import HeaderTitle from '../../../components/HeaderTitle';
import InformationModal from '../../../components/InformationModal';
import Loader from '../../../components/Loader';
import { commoditiesApi } from '../../../services/commoditiesServices';
import { customersApi } from '../../../services/customersServices';
import { farmsApi } from '../../../services/farmsServices';
import { mapFarms } from '../../../utils/map';
import FarmMap from './FarmMap';

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
  }),
};

const animatedComponents = makeAnimated();

const SelectCustomer = ({ required = false, onSelect, selected }) => {
  const [match, setMatch] = useState();
  const [loading, setLoading] = useState();
  const [options, setOptions] = useState([]);

  const getOptions = () =>
    options
      .map((item) => ({
        label: item.name || item.label,
        value: item.id || item.value,
      }))
      .filter((item) => item.value !== selected?.value);

  // Load customers from service
  useEffect(() => {
    setLoading(true);
    customersApi
      .getCustomers({
        match,
        page: 1,
        pageSize: 100,
      })
      .then((result) => {
        const parsed = result.customers.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setOptions(parsed);
      })
      .finally(() => setLoading(false));
  }, [match, selected]);

  return (
    <Select
      required={required}
      placeholder={<span className='text-muted'>Type a customer name...</span>}
      noOptionsMessage={() => 'No farm found'}
      styles={style}
      className='border rounded'
      options={getOptions()}
      closeMenuOnSelect={true}
      components={animatedComponents}
      value={selected}
      isSearchable
      isClearable
      inputValue={match}
      onInputChange={(value) => setMatch(value)}
      onChange={onSelect}
      isLoading={loading}
    />
  );
};

const SelectCounties = ({ required = false, onSelect, selected }) => {
  const [match, setMatch] = useState();
  const [loading, setLoading] = useState();
  const [options, setOptions] = useState([]);

  const getOptions = () =>
    options
      .map((item) => ({
        label: item.name || item.label,
        value: item.id || item.value,
      }))
      .filter((item) => item.value !== selected?.value);

  // Load commodity from service
  useEffect(() => {
    setLoading(true);
    commoditiesApi
      .getCommodities({
        match,
        page: 1,
        pageSize: 100,
      })
      .then((result) => {
        const parsed = counties.map((item) => ({
          label: item,
          value: item,
        }));
        setOptions(parsed);
      })
      .finally(() => setLoading(false));
  }, [match, selected]);

  return (
    <Select
      required={required}
      placeholder={<span className='text-muted'>Type a county name</span>}
      noOptionsMessage={() => 'No county found'}
      styles={style}
      className='border rounded'
      options={getOptions()}
      closeMenuOnSelect={true}
      components={animatedComponents}
      value={selected}
      isSearchable
      isClearable
      inputValue={match}
      onInputChange={(value) => setMatch(value)}
      onChange={onSelect}
      isLoading={loading}
    />
  );
};

const SelectCommodities = ({ required = false, onSelect, selected }) => {
  const [match, setMatch] = useState();
  const [loading, setLoading] = useState();
  const [options, setOptions] = useState([]);

  const getOptions = () =>
    options
      .map((item) => ({
        label: item.name || item.label,
        value: item.id || item.value,
      }))
      .filter((item) => item.value !== selected?.value);

  // Load commodity from service
  useEffect(() => {
    setLoading(true);
    commoditiesApi
      .getCommodities({
        match,
        page: 1,
        pageSize: 100,
      })
      .then((result) => {
        const parsed = result.commodities.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setLoading(false);
        setOptions(parsed);
      })
  }, [match, selected]);

  return (
    <Select
      required={required}
      placeholder={<span className='text-muted'>Type a commodity name</span>}
      noOptionsMessage={() => 'No farm found'}
      styles={style}
      className='border rounded'
      options={getOptions()}
      closeMenuOnSelect={true}
      components={animatedComponents}
      value={selected}
      isSearchable
      isClearable
      inputValue={match}
      onInputChange={(value) => setMatch(value)}
      onChange={onSelect}
      isLoading={loading}
    />
  );
};

const FarmForm = () => {
  const history = useHistory();
  const { customerId, farmId } = useParams();

  const [refresh, setRefresh] = useState();

  const [loading, setLoading] = useState(true);
  const [farm, setFarm] = useState();
  const [farmName, setFarmName] = useState();
  const [farmAcres, setFarmAcres] = useState();
  const [farmGeoJson, setFarmGeoJson] = useState();
  const [farmAddress, setFarmAddress] = useState();
  const [farmFieldNumber, setFarmFieldNumber] = useState();
  const [county, setCounty] = useState();
  const [commodity, setCommodity] = useState();
  const [customer, setCustomer] = useState();

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: '',
    body: '',
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: '',
    body: '',
  });

  // OnInit
  useEffect(() => {
    const OnInit = async () => {
      if (farmId) {
        farmsApi.getFarm(farmId).then((_farm) => {
          setFarm(_farm);
          setFarmName(_farm.name);
          setFarmAcres(_farm.acres);
          setFarmFieldNumber(_farm.field_number);
          setFarmAddress(_farm.address);
          setFarmGeoJson(_farm.geojson);
          setCustomer({
            label: _farm.customer.name,
            value: _farm.customer.id,
          });
          customersApi.updateCustomer({
            ..._farm.customer,
            notified: true,
          });
          if (_farm.county) {
            setCounty({
              label: _farm.county,
              value: _farm.county,
            });
          }
          setCommodity({
            label: _farm.commodity?.name,
            value: _farm.commodity?.id,
          });
          if (_farm.geojson) {
            mapFarms.drawFarm(_farm.geojson);
          } else {
            setInformationModal({
              isOpen: true,
              title: 'Edit Farm',
              body: 'This farm has no geographic data associated.'
            })
          }
          farmsApi.notifyFarm(_farm);
        });
      }
    };
    setLoading(true);
    OnInit().then(() => {
      setLoading(false);
    }).catch(err => {
      console.log("error getFarm", err)
      setLoading(false);
    });
  }, [customerId, farmId, refresh]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (farmName && farmAcres && farmAddress && commodity && customer) {
      const farm = {
        name: farmName,
        acres: farmAcres,
        address: farmAddress,
        commodity_id: commodity.value,
        customer_id: customer.value,
        field_number: farmFieldNumber || "N/A",
        county: county?.value,
        geojson: farmGeoJson,
      };
      if (farmId) {
        await farmsApi.updateFarm({ ...farm, id: farmId });
        setRefresh(!refresh);
        setInformationModal({
          isOpen: true,
          title: 'Update Farm',
          body: 'Farm updated successfully'
        });
      } else {
        const newFarm = await farmsApi.createFarm(farm);
        setInformationModal({
          isOpen: true,
          title: 'Create Farm',
          body: 'Farm created successfully',
          onClose: () => history.push(`/customers/${newFarm.customer_id}/farm/${newFarm.id}`)
        });
      }
    }
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    setConfirmationModal({
      isOpen: true,
      confirmColor: 'danger',
      onSubmit: () => {
        setLoading(true);
        farmsApi.deleteFarm(farmId).then(() => history.push(`/customers/${customerId}`));
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: 'Delete Farm',
      body: `<span class="text-center">Do you confirm you want to delete Farm ${farmName}?</span>`,
    });
  };

  return (
    <Container className='d-flex flex-column flex-grow-1' fluid>
      <Header className='mb-3'>
        <HeaderTitle className='d-flex flex-wrap justify-content-between'>
          <div className='d-flex flex-column align-items-start col-6 px-0'>
            <span className='col-12 px-0 col-lg-6 text-dark'>
              {farmId ? 'Edit' : 'Create'} Farm
            </span>

            <div className='d-flex flex-row'>
              <Button
                size='sm'
                className='rounded mt-1 d-flex align-items-center'
                onClick={() => history.goBack()}
              >
                <FontAwesomeIcon icon={faChevronLeft} className='mr-1' />
                <span>Back</span>
              </Button>
            </div>
          </div>
          <div className='d-flex flex-column align-items-end'>
            <Button
              color='warning'
              onClick={mapFarms.clearDraw}
              className='rounded mt-auto'
            >
              <FontAwesomeIcon icon={faEraser} className='mr-1' />
              <span>Clear Farm</span>
            </Button>
          </div>
        </HeaderTitle>
      </Header>
      {loading ? (
        <Loader />
      ) : (
        <Row id='farmFormContainer' className='flex-grow-1'>
          <Col>
            <Card className='box-shadow-none border'>
              <CardBody>
                <Row className='mb-3'>
                  <Col>
                    <strong>Farm Details</strong>
                  </Col>
                </Row>
                <Form onSubmit={handleSubmit}>
                  <FormGroup row>
                    <Label sm={3}>
                      Name <span className='red-asterisk'>*</span>
                    </Label>
                    <Col sm={9}>
                      <Input
                        type='text'
                        name='name'
                        required
                        value={farmName || ""}
                        onChange={(e) => setFarmName(e.target.value)}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={3}>
                      Acres <span className='red-asterisk'>*</span>
                    </Label>
                    <Col sm={9}>
                      <Input
                        type='text'
                        name='acres'
                        id='farmFormAcres'
                        value={farmAcres || ""}
                        onChange={(e) => setFarmAcres(e.target.value)}
                        required
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={3}>Field Number</Label>
                    <Col sm={9}>
                      <Input
                        type='text'
                        name='field_number'
                        value={farmFieldNumber || ""}
                        onChange={(e) => setFarmFieldNumber(e.target.value)}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={3}>
                      Address <span className='red-asterisk'>*</span>
                    </Label>
                    <Col sm={9}>
                      <Input
                        type='text'
                        name='address'
                        required
                        value={farmAddress || ""}
                        onChange={(e) => setFarmAddress(e.target.value)}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={3}>County</Label>
                    <Col sm={9}>
                      <SelectCounties onSelect={setCounty} selected={county} />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={3}>
                      Commodity <span className='red-asterisk'>*</span>
                    </Label>
                    <Col sm={9}>
                      <SelectCommodities
                        required
                        onSelect={setCommodity}
                        selected={commodity}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={3}>
                      Customer <span className='red-asterisk'>*</span>
                    </Label>
                    <Col sm={9}>
                      <SelectCustomer
                        required
                        onSelect={setCustomer}
                        selected={customer}
                      />
                    </Col>
                  </FormGroup>
                  <hr />
                  <div className='d-flex flex-row justify-content-end align-items-center'>
                    {farmId && (
                      <Button
                        color='danger'
                        onClick={handleDelete}
                        className='rounded mr-3'
                      >
                        <FontAwesomeIcon icon={faTrash} className='mr-2' />
                        <span>Delete</span>
                      </Button>
                    )}
                    <Button
                      color='primary'
                      className='rounded'
                    >
                      <FontAwesomeIcon icon={faSave} className='mr-2' />
                      <span>Save</span>
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <FarmMap
              farm={farm}
              onCalculate={(acres, geojson) => {
                setFarmAcres(acres);
                setFarmGeoJson(geojson);
              }}
            />
          </Col>
        </Row>
      )}
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() => informationModal.onClose ? informationModal.onClose() :
            setInformationModal({ isOpen: false, title: '', body: '' })
          }
        />
      ) : null}
    </Container>
  );
};

export default FarmForm;
